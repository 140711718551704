import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { MdAdd, MdClose } from "react-icons/md";
import { FaRegCircle } from "react-icons/fa";
import { messages } from "consts";
import { api } from "lib";
import * as yup from "yup";
import _ from "lodash";
import { PrivateContext } from "pages/Private/Private";
import { ClusterContext } from "..";
import { ProductContext } from ".";
import { Card } from "components";

export const ProductAttributes = () => {
  const { currentMerchant } = useContext(PrivateContext);
  const { data, errors, handleDataChange } = useContext(ProductContext);
  const { loadingProductAttributes } = useContext(ClusterContext);
  const [showAttributesModal, setShowAttributesModal] = useState(false);
  const attributeBackgroundColor = useColorModeValue("gray.200", "gray.700");
  const attributeCloseButtonColorScheme = useColorModeValue("blackAlpha", "whiteAlpha");

  const handleAddAttribute = (attribute) => {
    const attrs = [...(data.attrs ?? [])];
    attrs.push(attribute);
    handleDataChange({ attrs });
  };

  const handleDeleteAttribute = (index) => {
    const attrs = [...(data.attrs ?? [])];
    attrs.splice(index, 1);
    handleDataChange({ attrs });
  };

  return (
    <>
      <Card borderColor={errors.attrs && "red.500"}>
        <HStack mb={4} justify="space-between">
          <Box>
            <Text>Atributos</Text>
            <Text fontSize="xs">Selecione os atributos que caracterizam este produto.</Text>
            {errors.attrs && (
              <Text color="red.500" fontSize="xs">
                {errors.attrs}
              </Text>
            )}
          </Box>
          <Tooltip label="Ação bloqueada para subsidiárias" isDisabled={!currentMerchant.parent} shouldWrapChildren>
            <IconButton
              icon={<Icon as={MdAdd} />}
              colorScheme="main"
              onClick={() => setShowAttributesModal(true)}
              isLoading={loadingProductAttributes}
              size="sm"
              isDisabled={!!currentMerchant.parent}
            />
          </Tooltip>
        </HStack>
        <VStack spacing={2} align="stretch">
          {(data.attrs ?? []).map((item, index) => (
            <HStack key={item._id} justify="space-between" bg={attributeBackgroundColor} borderRadius="lg" p={2}>
              <VStack spacing={-1} align="stretch">
                <Text fontSize="xs" noOfLines={1}>
                  {item.parent.name}
                </Text>
                <Text fontSize="xs" fontWeight="bold" noOfLines={1}>
                  {item.name}
                </Text>
              </VStack>
              <IconButton
                size="sm"
                icon={<Icon as={MdClose} />}
                colorScheme={attributeCloseButtonColorScheme}
                onClick={() => handleDeleteAttribute(index)}
              />
            </HStack>
          ))}
        </VStack>
        {!data?.attrs?.length && (
          <VStack spacing={0} py={4} textAlign="center">
            <Icon as={FaRegCircle} boxSize={10} marginBottom="10px" />
            <Text fontSize="sm" fontWeight="bold">
              Nenhum atributo adicionado
            </Text>
            <Text fontSize="xs">Você ainda não adicionou atributos a este produto.</Text>
          </VStack>
        )}
      </Card>
      <ProductAttributesModal isOpen={showAttributesModal} onClose={() => setShowAttributesModal(false)} onFinish={handleAddAttribute} />
    </>
  );
};

const ProductAttributesModal = ({ isOpen, onClose, onFinish }) => {
  const { data } = useContext(ProductContext);
  const { productAttributes } = useContext(ClusterContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [productAttributesChildren, setProductAttributesChildren] = useState({});
  const [loadingProductAttributesChildren, setLoadingProductAttributesChildren] = useState(false);
  const [parents, setParents] = useState([]);

  useEffect(() => {
    const parents = _.map(data.attrs, (o) => o.parent._id);
    const allowed = _.filter(productAttributes?.data, (o) => parents.indexOf(o._id) === -1);
    setParents(allowed);
  }, [data.attrs, productAttributes?.data]);

  useEffect(() => {
    (async () => {
      setProductAttributesChildren([]);
      if (!formData.parent) return;
      try {
        setLoadingProductAttributesChildren(true);
        const response = await api.get("/product-attributes", {
          params: { query: { parent: formData.parent } },
        });
        setProductAttributesChildren(response);
      } finally {
        setLoadingProductAttributesChildren(false);
      }
    })();
  }, [formData.parent]);

  const handleSubmit = async () => {
    try {
      const schema = yup.object().shape({
        parent: yup.string().required(messages.error.required),
        child: yup.string().required(messages.error.required),
      });
      await schema.validate(formData);
      const child = productAttributesChildren?.data?.find(({ _id }) => _id === formData.child);
      onFinish?.(child);
      setFormData({});
      setFormErrors({});
      onClose?.(false);
    } catch (error) {
      error.errors && setFormErrors({ [error.path]: error.message });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Novo atributo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <FormControl isRequired={true} isInvalid={formErrors.parent}>
              <FormLabel>Tipo</FormLabel>
              <Select
                value={formData?.parent ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, parent: target.value }))}
              >
                <option value="">--Selecione</option>
                {parents.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formErrors.parent}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.child}>
              <FormLabel>Atributo</FormLabel>
              <InputGroup>
                <Select
                  value={formData?.child ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, child: target.value }))}
                  isDisabled={loadingProductAttributesChildren}
                >
                  <option value="">--Selecione</option>
                  {productAttributesChildren?.data?.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                {loadingProductAttributesChildren && (
                  <InputRightElement>
                    <Spinner />
                  </InputRightElement>
                )}
              </InputGroup>
              <FormErrorMessage>{formErrors.child}</FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button colorScheme="main" onClick={handleSubmit}>
            Adicionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
