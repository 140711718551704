import React, { useRef, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  SimpleGrid,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { MerchantsDetailsContext } from ".";

const variables = [
  {
    title: "Expressões",
    items: ["<MOEDA>\n...\n</MOEDA>", "<CALCULO>\n...\n</CALCULO>", "<LISTA_MERCADORIAS>\n...\n</LISTA_MERCADORIAS>"],
  },
  {
    title: "Genéricos",
    items: ["{DATA_HORA}"],
  },
  {
    title: "Organização",
    items: [
      "{ORGANIZACAO_NOME}",
      "{ORGANIZACAO_ENDERECO}",
      "{ORGANIZACAO_CPF_CNPJ}",
      "{ORGANIZACAO_IE}",
      "{ORGANIZACAO_IM}",
      "{ORGANIZACAO_EMAIL}",
      "{ORGANIZACAO_TELEFONES}",
    ],
  },
  {
    title: "Pacotes",
    items: [
      "{PACOTE_NID}",
      "{PACOTE_REPRESENTANTE}",
      "{PACOTE_REVENDEDOR}",
      "{PACOTE_VALOR_CARREGADO}",
      "{PACOTE_VALOR_ATUAL}",
      "{PACOTE_VALOR_MODIFICADO}",
      "{PACOTE_QUANTIDADE_CARREGADA}",
      "{PACOTE_QUANTIDADE_ATUAL}",
      "{PACOTE_QUANTIDADE_MODIFICADO}",
    ],
  },
  {
    title: "Mercadorias",
    items: [
      "{MERCADORIA_PRODUTO_NID}",
      "{MERCADORIA_PRODUTO_REF}",
      "{MERCADORIA_PRODUTO_NOME}",
      "{MERCADORIA_QUANTIDADE}",
      "{MERCADORIA_VALOR}",
    ],
  },
];

const Templates = (props) => {
  const { formData, setFormData, formErrors } = useContext(MerchantsDetailsContext);
  const textareaRef = useRef();

  const insertVariableText = (text) => {
    const tmp = [...(formData.mobilePack ?? "")];
    const { selectionStart, selectionEnd } = textareaRef.current;
    tmp.splice(selectionStart, selectionEnd - selectionStart, text);
    const message = tmp.join("");
    textareaRef.current?.focus();
    setFormData((state) => ({ ...state, mobilePack: message }));
  };

  return (
    <>
      <Box>
        <Box mb={6}>
          <Text fontSize="lg">Template de pacotes da impressora Bluetooth</Text>
          <Text fontSize="xs">
            Informe o template que deseja utilizar na impressão de pacotes na impressora bluetooth do representante. Para valores dinâmicos,
            utilize as variáveis abaixo.
          </Text>
          <SimpleGrid mt={4} columns={[1, 5]} spacing={2}>
            {variables.map(({ title, items }) => (
              <Menu key={title}>
                <MenuButton as={Button} size="sm" colorScheme="main">
                  {title}
                </MenuButton>
                <MenuList>
                  {items.map((o) => (
                    <MenuItemOption key={o} fontSize="xs" fontWeight="bold" onClick={() => insertVariableText(o)}>
                      {o}
                    </MenuItemOption>
                  ))}
                </MenuList>
              </Menu>
            ))}
          </SimpleGrid>
        </Box>
        <SimpleGrid>
          <FormControl isInvalid={formErrors["templates.mobilePack"]}>
            <Textarea
              ref={textareaRef}
              value={formData.templates?.mobilePack ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, templates: { ...state.templates, mobilePack: target.value } }))}
              height="500px"
              fontSize="12px"
              fontWeight="500"
            />
            <FormErrorMessage>{formErrors["templates.mobilePack"]}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
      </Box>
    </>
  );
};

export default Templates;
