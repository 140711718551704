import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { InputCurrency } from "components";
import { IoCopyOutline } from "react-icons/io5";
import * as yup from "yup";
import { messages } from "consts";
import { api } from "lib";

const Duplicate = ({ _id, onClose, onFinish }) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [response, setResponse] = useState([]);
  const toast = useToast();

  useEffect(() => {
    setFormData({ qtt: 1 });
    setFormErrors({});
    setResponse([]);
  }, [_id]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        qtt: yup.number().min(1, `${messages.error.greaterOrEqual} 1.`).required(messages.error.required),
      });
      await schema.validate(formData);
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async ({ qtt }) => {
    try {
      setLoadingSaveData(true);
      const response = await api.put(`/tags/${_id}/duplicate/${qtt}`);
      setResponse(response);
      setFormData({});
      onFinish?.(response);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <Modal isOpen={_id} onClose={onClose} scrollBehavior="inside" isCentered>
      <ModalOverlay />
      <ModalContent>
        {response.length === 0 ? (
          <>
            <ModalHeader>
              <Text fontSize="md" fontWeight="bold">
                Duplicar etiqueta
              </Text>
              <Text fontSize="xs" fontWeight="normal">
                Informe a quantidade de cópias da etiqueta selecionada.
              </Text>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody pb="20px">
              <form onSubmit={handleSubmit}>
                <VStack>
                  <FormControl isRequired={true}>
                    <Input
                      as={InputCurrency}
                      precision="0"
                      value={formData.qtt ?? ""}
                      onChange={(qtt) => setFormData((state) => ({ ...state, qtt }))}
                    />
                    <FormErrorMessage>{formErrors.qtt}</FormErrorMessage>
                  </FormControl>
                  <Button
                    type="submit"
                    colorScheme="main"
                    leftIcon={<Icon as={IoCopyOutline} />}
                    isLoading={loadingSaveData}
                    isFullWidth={true}
                  >
                    Duplicar
                  </Button>
                </VStack>
              </form>
            </ModalBody>
          </>
        ) : (
          <>
            <ModalHeader>
              <Text fontWeight="bold">Pacote duplicado</Text>
              <Text fontSize="xs" fontWeight="normal">
                Os pacotes criados a partir da duplicação foram os listados abaixo.
              </Text>
              <ModalCloseButton />
            </ModalHeader>
            <Divider />
            <ModalBody textAlign="center">
              {response.map((title) => (
                <Button m="5px" colorScheme="main" key={title} size="sm">
                  {title}
                </Button>
              ))}
            </ModalBody>
            <Divider />
            <ModalFooter>
              <Button variant="ghost" onClick={onClose}>
                Fechar
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Duplicate;
