import { useContext } from "react";
import { Text, Stack, Icon, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { MdBusiness } from "react-icons/md";
import { PrivateContext } from "pages/Private/Private";

export const MerchantError = () => {
  const { merchantError, setMerchantError } = useContext(PrivateContext);

  return (
    <Modal isOpen={merchantError} onClose={() => setMerchantError(false)} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding="30px">
          <Stack align="center">
            <Icon as={MdBusiness} boxSize={40} />
            <Text fontSize="lg" fontWeight="bold">
              Problemas na Organização
            </Text>
            <Text fontSize="xs" textAlign="center" marginBottom="20px">
              Existem problemas com a organização atual. Por favor, peça o administrador da conta para validar as informações.
            </Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
