import React, { useRef, useMemo, createContext } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdDelete, MdAdd } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { Clipboard, DataTable, Dialog, StatusBadge, OwnershipButton, Card } from "components";
import { messages } from "consts";
import { api, translator } from "lib";
import Filter from "./filter";

export const ShipmentsListContext = createContext();

export const ShipmentsList = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useCacheState(20, "ShipmentsList:perPage");
  const [sort, setSort] = useStickyState({ nid: 1 }, "ShipmentsList:sort");
  const [page, setPage] = useCacheState(0, "ShipmentsList:page");
  const [query, setQuery] = useCacheState({}, "ShipmentsList:query");
  const [response, loading, refresh, error] = useApiGet(
    useMemo(() => ({ path: "/shipments", params: { query, perPage, page, sort } }), [query, perPage, page, sort])
  );
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/shipments/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <ShipmentsListContext.Provider value={{ query, setQuery, setPage, loading }}>
      <HStack p={2} mb={2} justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/shipments">
              Remessas
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/shipments/details" icon={<Icon as={MdAdd} boxSize={4} />} />
        </Tooltip>
      </HStack>

      <Card>
        <Filter />
      </Card>

      <Card>
        <DataTable
          tableId="ShipmentsList:table"
          title="Remessas"
          data={response?.data ?? []}
          size={response?.size ?? 0}
          loading={loading}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          onRefresh={refresh}
          error={error}
          renderItems={[
            {
              key: "_id",
              label: "ID",
              visible: true,
              render: (value, item) => (
                <HStack>
                  <Clipboard value={value} icon={<Icon as={CgHashtag} />} />
                  <OwnershipButton value={item.ownership} />
                </HStack>
              ),
            },
            { key: "nid", label: "NID", visible: true },
            { key: "status", label: "Status", visible: true, exporter: translator, render: (value) => <StatusBadge label={value} /> },
            { key: "agent.name", label: "Representante", visible: true, sortable: false },
            { key: "zone.name", label: "Praça", visible: true, sortable: false },
            {
              key: "distribution",
              label: "Distribuição",
              visible: true,
              sortable: false,
              formatter: ({ week, year }) => `${week}/${year}`,
            },
            { key: "charge", label: "Cobrança", visible: true, sortable: false, formatter: ({ week, year }) => `${week}/${year}` },
            {
              key: "predictedAgentPaymentDate",
              label: "Previsão de acerto",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY"),
            },
            {
              key: "closedAt",
              label: "Fechamento",
              visible: true,
              formatter: (value) => (value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-"),
            },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          ActionsComponent={({ item }) => (
            <Tooltip label="Excluir">
              <IconButton
                icon={<Icon as={MdDelete} />}
                size="sm"
                variant="ghost"
                colorScheme="red"
                onClick={() => deleteRef.current.confirm(item, handleDelete)}
              />
            </Tooltip>
          )}
          onRowDoubleClick={({ _id }) => navigate(`/shipments/details/${_id}`)}
        />
      </Card>

      <Dialog.Delete ref={deleteRef} />
    </ShipmentsListContext.Provider>
  );
};
