import React, { useState, forwardRef, useCallback, useImperativeHandle, useEffect } from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { messages } from "consts";
import * as yup from "yup";
import api from "lib/api";

const validationSchema = {
  zipCode: yup.string().required(messages.error.required),
  street: yup.string().required(messages.error.required),
  number: yup.string().required(messages.error.required),
  neighborhood: yup.string().required(messages.error.required),
  city: yup.object().shape({
    name: yup.string().required(messages.error.required),
  }),
};

export const Address = forwardRef(({ data = {}, errors = {}, onChange, onError, isDisabled = false }, ref) => {
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    Object.entries(errors).forEach(([key, value]) => {
      if (Object.keys(validationSchema).indexOf(key) === -1) return;
      setFormErrors((state) => ({ ...state, [key]: value }));
      onError(value);
    });
  }, [errors, onError]);

  const handleZipCodeChange = async ({ target }) => {
    const zipCode = target.value?.replace(/\D/g, "") || "";
    onChange({ ...data, zipCode });
    if (zipCode.length !== 8) return;
    setLoadingZipCode(true);
    const address = await api.get(`locales/${zipCode}`);
    onChange(address);
    setLoadingZipCode(false);
  };

  const validate = useCallback(async () => {
    try {
      const schema = yup.object().shape(validationSchema);
      await schema.validate(data);
      setFormErrors({});
      if (onError instanceof Function) onError(null);
      return { ...data, city: data.city._id };
    } catch (error) {
      if (onError instanceof Function) onError(error);
      error.errors && setFormErrors({ [error.path]: error.message });
      throw error;
    }
  }, [data, onError]);

  useImperativeHandle(ref, () => ({ validate }), [validate]);

  return (
    <Box>
      <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors.zipCode}>
          <FormLabel>CEP</FormLabel>
          <InputGroup>
            <Input as={InputMask} mask="99999-999" value={data.zipCode ?? ""} onChange={handleZipCodeChange} isDisabled={isDisabled} />
            {loadingZipCode && (
              <InputRightElement>
                <IconButton type="button" variant="ghost" isLoading={true} />
              </InputRightElement>
            )}
          </InputGroup>
          <FormErrorMessage>{formErrors.name}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors.street}>
          <FormLabel>Rua</FormLabel>
          <Input value={data.street ?? ""} onChange={({ target }) => onChange({ ...data, street: target.value })} isDisabled={isDisabled} />
          <FormErrorMessage>{formErrors.street}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors.number}>
          <FormLabel>Número</FormLabel>
          <Input value={data.number ?? ""} onChange={({ target }) => onChange({ ...data, number: target.value })} isDisabled={isDisabled} />
          <FormErrorMessage>{formErrors.number}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors.neighborhood}>
          <FormLabel>Bairro</FormLabel>
          <Input
            value={data.neighborhood ?? ""}
            onChange={({ target }) => onChange({ ...data, neighborhood: target.value })}
            isDisabled={isDisabled}
          />
          <FormErrorMessage>{formErrors.neighborhood}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors.city}>
          <FormLabel>Cidade</FormLabel>
          <Input value={data.city?.name ?? ""} isDisabled={true} />
          <FormErrorMessage>{formErrors.city}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors.state}>
          <FormLabel>Estado</FormLabel>
          <Input value={data.city?.state?.name ?? ""} isDisabled={true} />
          <FormErrorMessage>{formErrors.state}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid spacing={4} mb={4}>
        <FormControl>
          <FormLabel>Complemento</FormLabel>
          <Input value={data.complement ?? ""} onChange={({ target }) => onChange({ ...data, complement: target.value })} />
        </FormControl>
      </SimpleGrid>
    </Box>
  );
});
