import { useContext } from "react";
import { Text, Stack, Icon, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Button } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { RiUserStarLine } from "react-icons/ri";
import { PrivateContext } from "pages/Private/Private";

export const UnquilifiedUser = () => {
  const { unquilifiedUser, setUnquilifiedUser } = useContext(PrivateContext);

  const handleClose = () => setUnquilifiedUser(false);

  return (
    <Modal isOpen={unquilifiedUser.message} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding="30px">
          <Stack align="center">
            <Icon as={RiUserStarLine} boxSize={40} />
            <Text fontSize="lg" fontWeight="bold">
              Usuário não qualificado
            </Text>
            <Text fontSize="xs" textAlign="center" marginBottom="20px">
              {unquilifiedUser.message}
            </Text>
            <Button onClick={handleClose} leftIcon={<Icon as={MdClose} />}>
              Fechar
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
