import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { messages, roles } from "consts";
import { Card, InputChips, SyncSelect } from "components";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { ImageLibrary } from "containers";
import { api, translator } from "lib";
import { MdSave, MdChevronLeft, MdAttachFile, MdArrowBack } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";

const formatFilters = (filters = {}) => ({
  phones: _.map(filters.phones, (phone) => phone.replace(/\D/g, "")),
  roles: filters.roles,
});

export const NotificationsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [recipients, setRecipients] = useState([]);
  const [loadingRecipients, setLoadingRecipients] = useState(false);
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/notifications/${_id}` }), [_id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchRecipients = async () => {
      try {
        setLoadingRecipients(true);
        const recipients = await api.get("/notifications/recipients", { params: { query: formatFilters(formData.filters) } });
        setRecipients(recipients);
      } finally {
        setLoadingRecipients(false);
      }
    };
    !_id && fetchRecipients();
  }, [_id, formData.filters]);

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        body: yup.string().required(messages.error.required),
      });
      const data = { ...formData, filters: formatFilters(formData.filters) };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      if (_id) {
        await api.put(`/notifications/${_id}`, data);
        setFormData((state) => {
          state.__v++;
          return state;
        });
      } else {
        const saved = await api.post("/notifications", data);
        navigate(`/notifications/details/${saved._id}`, { replace: true });
        refreshData();
      }
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <>
      <HStack p={2} mb={2}>
        <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/notifications">
              Notificações
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.title ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      <form onSubmit={handleSubmit}>
        {_id && (
          <Card>
            <Box>
              <Box marginBottom="20px">
                <Text fontWeight="bold">Entrega</Text>
                <Text fontSize="sm">Informações referentes à entrega da notificação</Text>
              </Box>
              <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Recipientes
                  </Text>
                  <Text>{formData.delivery?.recipientsCount ?? 0}</Text>
                </Box>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Sucessos
                  </Text>
                  <Text>{formData.delivery?.successCount ?? 0}</Text>
                </Box>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Falhas
                  </Text>
                  <Text>{formData.delivery?.failureCount ?? 0}</Text>
                </Box>
              </SimpleGrid>
              <SimpleGrid columns={[1, 2]} spacing={4}>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Números de telefone
                  </Text>
                  <Text fontSize="xs">{_.join(formData.filters?.phones, ", ")}</Text>
                </Box>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Papéis
                  </Text>
                  <Text fontSize="xs">{_.join(formData.filters?.roles, ", ")}</Text>
                </Box>
              </SimpleGrid>
            </Box>
          </Card>
        )}

        {!_id && (
          <Card>
            <Box mb={4}>
              <HStack>
                {loadingRecipients && <Spinner size="xs" />}
                <Text fontWeight="bold">Filtros ({recipients ?? 0})</Text>
              </HStack>
              <Text fontSize="sm">Informe os filtros que deverão ser utilizados para a seleção dos usuários.</Text>
            </Box>
            <SimpleGrid columns={[1, 2]} spacing={4}>
              <FormControl isInvalid={formErrors["filters.phones"]}>
                <FormLabel>Números de telefone</FormLabel>
                <InputChips
                  as={InputMask}
                  mask="(99) 9 9999-9999"
                  data={formData.filters?.phones}
                  onChange={(phones) => setFormData((state) => ({ ...state, filters: { ...state.filters, phones } }))}
                />
                <FormErrorMessage>{formErrors["filters.phones"]}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired={false} isInvalid={formErrors["filters.roles"]}>
                <FormLabel>Papéis</FormLabel>
                <SyncSelect
                  isMulti
                  placeholder="Selecione os papéis"
                  value={_.map(formData.filters?.roles, (value) => ({ value, label: translator(value) }))}
                  options={_.map(roles, (value) => ({ value, label: translator(value) }))}
                  onChange={(roles) => setFormData((state) => ({ ...state, filters: { ...state.filters, roles: _.map(roles, "value") } }))}
                />
                <FormErrorMessage>{formErrors["filters.roles"]}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </Card>
        )}

        <Card>
          <Box mb={4}>
            <Text fontWeight="bold">Notificação</Text>
            <Text fontSize="sm">Informe os dados referentes à notificação que deverá ser enviada.</Text>
          </Box>
          <VStack mb="20px">
            {formData.img && (
              <Box width="300px">
                <Image src={formData.img?.transforms?.original?.location} alt={formData.img?.name} borderRadius="lg" />
              </Box>
            )}
            <HStack>
              <Button colorScheme="main" onClick={() => setShowImageLibrary(true)} leftIcon={<Icon as={MdAttachFile} />}>
                Selecionar imagem
              </Button>
              {formData.img && (
                <Button onClick={() => setFormData((state) => ({ ...state, img: null }))} leftIcon={<Icon as={IoMdTrash} />}>
                  Remover imagem
                </Button>
              )}
            </HStack>
          </VStack>
          <SimpleGrid spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel>Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.body}>
              <FormLabel>Mensagem</FormLabel>
              <Textarea
                value={formData.body ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, body: target.value }))}
                rows={10}
              />
              <FormErrorMessage>{formErrors.body}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Card>

        <HStack justify="flex-end" spacing={4} mb={2}>
          <Button as={RouterLink} to="/notifications" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button
            type="submit"
            leftIcon={<Icon as={MdSave} />}
            colorScheme="main"
            isLoading={loadingSaveData || loadingData}
            isDisabled={_id}
          >
            Salvar
          </Button>
        </HStack>
      </form>

      <ImageLibrary
        isOpen={showImageLibrary}
        onClose={() => setShowImageLibrary(false)}
        onFinish={([img]) => setFormData((state) => ({ ...state, img }))}
      />
    </>
  );
};
