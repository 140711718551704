import React, { useEffect, useRef, useContext, useState, useCallback } from "react";
import {
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { messages } from "consts";
import { MdOutlinePlaylistAdd } from "react-icons/md";
import { BsViewList, BsViewStacked } from "react-icons/bs";
import ObjectId from "bson-objectid";
import _ from "lodash";
import * as database from "database";
import { usePrevious } from "hooks";
import { PrivateContext } from "pages/Private/Private";
import Item from "./item";

const barcodeReaderSound = new Audio("/assets/sounds/scanner.mp3");
const errorSound = new Audio("/assets/sounds/error.mp3");

const Packs = ({ defaultValue, isLoading, isClosed, onChange }) => {
  const { currentMerchant } = useContext(PrivateContext);
  const [packs, setPacks] = useState();
  const prevPacks = usePrevious(packs);
  const inputRef = useRef();
  const formRef = useRef();
  const toast = useToast();

  useEffect(() => {
    if (_.isArray(defaultValue)) setPacks(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (prevPacks) onChange?.(packs);
  }, [packs, onChange]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const value = inputRef.current.value;
    formRef.current.reset();
    handleUpdateEntry(value);
    inputRef.current.focus();
  };

  const handleUpdateEntry = async (id) => {
    try {
      if (!id) return;
      await updateOrCreateEntry(id);
      barcodeReaderSound.play();
    } catch (error) {
      toast({ description: messages.error.orderNotFound, status: "error", isClosable: true });
      errorSound.play();
    }
  };

  const updateOrCreateEntry = async (id) => {
    const pack = await database.synced.packs
      .where(["_id", "merchant"])
      .equals([id, currentMerchant._id])
      .or(["nid", "merchant"])
      .equals([parseInt(id), currentMerchant._id])
      .first();
    if (!pack) throw new Error();
    setPacks((state) => {
      const tmp = [...state];
      const entry = _.find(tmp, (o) => o._id === id || o.nid === parseInt(id));
      if (entry) throw new Error();
      tmp.unshift({
        _id: ObjectId().toString(),
        pack,
        quantity: pack.load.current.quantity,
        amount: pack.load.current.amount,
      });
      return tmp;
    });
  };

  const handleDeleteEntry = useCallback((_id) => {
    setPacks((state) => {
      const tmp = [...state];
      _.remove(tmp, (o) => o._id === _id);
      return tmp;
    });
  }, []);

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit}>
        <HStack align="flex-end" mb={4}>
          <FormControl>
            <FormLabel>ID/NID</FormLabel>
            <Input ref={inputRef} isDisabled={isClosed || isLoading} autoFocus={true} />
          </FormControl>
          <IconButton type="submit" colorScheme="main" variant="ghost" icon={<Icon as={MdOutlinePlaylistAdd} />} />
        </HStack>
      </form>

      <Tabs isFitted variant="solid-rounded" colorScheme="main" mt={4} isLazy={true}>
        <TabList mb={6}>
          <Tab>
            <HStack>
              <Icon as={BsViewList} />
              <Text>Última entrada</Text>
            </HStack>
          </Tab>
          <Tab>
            <HStack>
              <Icon as={BsViewStacked} />
              <Text>Todas as entradas</Text>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            {_.size(packs) > 0 && (
              <Item
                key={packs?.[0]?._id}
                _id={packs?.[0]?._id}
                pack={packs?.[0]?.pack}
                quantity={packs?.[0]?.quantity}
                amount={packs?.[0]?.amount}
                isClosed={isClosed}
                handleDeleteEntry={handleDeleteEntry}
              />
            )}
          </TabPanel>
          <TabPanel p={0}>
            <VStack spacing={4} align="stretch" divider={<StackDivider />}>
              {_.map(packs, (item) => (
                <Item
                  key={item._id}
                  _id={item._id}
                  pack={item.pack}
                  quantity={item.quantity}
                  amount={item.amount}
                  isClosed={isClosed}
                  handleDeleteEntry={handleDeleteEntry}
                />
              ))}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default Packs;
