import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { MdSave, MdChevronLeft, MdArrowBack } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet } from "hooks";
import { Card, InputCurrency } from "components";

export const ChartOfAccountLevelsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/chart-of-account-levels/${_id}` }), [_id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        level: yup.number().min(1, `${messages.error.greaterOrEqual} 1.`).required(messages.error.required),
      });
      await schema.validate(formData);
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/chart-of-account-levels/${_id}`, data) : await api.post("/chart-of-account-levels", data);
      navigate(`/chart-of-account-levels/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <>
      <HStack p={2} mb={2}>
        <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/chart-of-account-levels">
              Níveis de Plano de Contas
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      <form onSubmit={handleSubmit}>
        <Card>
          <SimpleGrid spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel>Nome</FormLabel>
              <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.level}>
              <FormLabel>Nível</FormLabel>
              <Input
                as={InputCurrency}
                precision="0"
                value={formData.level ?? ""}
                onChange={(floatValue) => setFormData((state) => ({ ...state, level: floatValue }))}
              />
              <FormErrorMessage>{formErrors.level}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Índice</FormLabel>
              <Input value={formData.index ?? ""} isDisabled={true} />
            </FormControl>
          </SimpleGrid>
        </Card>

        <HStack justify="flex-end" spacing={4}>
          <Button as={RouterLink} to="/chart-of-account-levels" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </>
  );
};
