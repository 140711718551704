import { useState } from "react";
import { useLocation } from "react-router-dom";
import { getAuth, signInWithCredential, PhoneAuthProvider } from "@firebase/auth";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { BiCheck } from "react-icons/bi";
import { MdOutlineLock } from "react-icons/md";
import { CgLastpass } from "react-icons/cg";
import { messages } from "consts";
import * as yup from "yup";

export const SignInPhoneNumberValidate = () => {
  const location = useLocation();
  const { phoneNumber, verificationId } = location.state;
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        verificationCode: yup.string().required(messages.error.required),
      });
      await schema.validate(formData);
      handleSignIn(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSignIn = async ({ verificationCode }) => {
    try {
      setLoadingSignIn(true);
      const auth = getAuth();
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      await signInWithCredential(auth, credential);
    } catch (error) {
      toast({ description: messages.error.signInVerificationCode, status: "error", duration: 5000, isClosable: true });
    } finally {
      setLoadingSignIn(false);
    }
  };

  return (
    <>
      <VStack spacing={0} mb={4}>
        <Icon as={CgLastpass} boxSize={16} mb={2} />
        <Heading size="lg">Código de verificação</Heading>
        <Text fontSize="sm" textAlign="center">
          Por favor, informe o código de verificação enviado para o número <strong>{phoneNumber}</strong>.
        </Text>
      </VStack>
      <form onSubmit={handleSubmit}>
        <VStack align="stretch">
          <FormControl isRequired={true} isInvalid={formErrors.verificationCode}>
            <InputGroup>
              <InputLeftElement>
                <Icon as={MdOutlineLock} />
              </InputLeftElement>
              <Input
                type="password"
                placeholder="Informe o código de verificação"
                value={formData.verificationCode ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, verificationCode: target.value }))}
                autoFocus={true}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.verificationCode}</FormErrorMessage>
          </FormControl>
          <Button type="submit" rightIcon={<Icon as={BiCheck} />} isLoading={loadingSignIn} colorScheme="main">
            Confirmar
          </Button>
        </VStack>
      </form>
    </>
  );
};
