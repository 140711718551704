import { useEffect } from "react";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { Preloader } from "components";

export const SignInEmailLinkValidate = () => {
  useEffect(() => {
    const auth = getAuth();
    const handleSignIn = async () => {
      let email = window.localStorage.getItem("@sign-in-email-link");
      if (!email) email = window.prompt("Por favor informe seu e-mail para confirmação.");
      await signInWithEmailLink(auth, email, window.location.href);
      window.localStorage.removeItem("@sign-in-email-link");
    };
    isSignInWithEmailLink(auth, window.location.href) && handleSignIn();
  }, []);

  return <Preloader />;
};
