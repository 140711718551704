import React, { useRef, useMemo, useContext } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdDelete, MdAdd } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { Card, Clipboard, DataTable, Dialog } from "components";
import { messages } from "consts";
import { api } from "lib";
import { PrivateContext } from "pages/Private/Private";

const query = { parent: { $exists: false } };

export const ProductGroupsList = () => {
  const navigate = useNavigate();
  const { currentMerchant } = useContext(PrivateContext);
  const [perPage, setPerPage] = useCacheState(20, "ProductGroupsList:perPage");
  const [sort, setSort] = useStickyState({ name: 1 }, "ProductGroupsList:sort");
  const [page, setPage] = useCacheState(0, "ProductGroupsList:page");
  const [search, setSearch] = useCacheState("", "ProductGroupsList:search");
  const [response, loading, refresh, error] = useApiGet(
    useMemo(() => ({ path: "/product-groups", params: { query, search, perPage, page, sort } }), [search, perPage, page, sort])
  );
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/product-groups/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/product-groups">
              Grupos de Produto
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <HStack>
          <Tooltip label={currentMerchant.parent ? "Ação bloqueada para subsidiárias" : "Novo"} shouldWrapChildren>
            <IconButton
              size="sm"
              colorScheme="main"
              onClick={() => navigate("/product-groups/details")}
              icon={<Icon as={MdAdd} />}
              isDisabled={!!currentMerchant.parent}
            />
          </Tooltip>
        </HStack>
      </HStack>
      <Card>
        <DataTable
          tableId="ProductGroupsList:table"
          title="Grupos de Produto"
          data={response?.data ?? []}
          size={response?.size ?? 0}
          loading={loading}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          search={search}
          onSearch={setSearch}
          onRefresh={refresh}
          error={error}
          renderItems={[
            { key: "_id", label: "ID", visible: true, render: (value) => <Clipboard value={value} icon={<Icon as={CgHashtag} />} /> },
            { key: "name", label: "Nome", visible: true },
            {
              key: "childrenCount",
              label: "Subgrupos",
              visible: true,
              sortable: false,
              formatter: (value) => value?.toLocaleString?.(),
            },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          ActionsComponent={({ item }) => (
            <Tooltip label={currentMerchant.parent ? "Ação bloqueada para subsidiárias" : "Excluir"} shouldWrapChildren>
              <IconButton
                icon={<Icon as={MdDelete} />}
                size="sm"
                variant="ghost"
                colorScheme="red"
                onClick={() => deleteRef.current.confirm(item, handleDelete)}
                isDisabled={!!currentMerchant.parent}
              />
            </Tooltip>
          )}
          onRowDoubleClick={({ _id }) => navigate(`/product-groups/details/${_id}`)}
        />
      </Card>
      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
