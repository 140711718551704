import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { messages, permissions } from "consts";
import { api } from "lib";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { MdSave, MdArrowBack, MdChevronLeft } from "react-icons/md";
import { Card } from "components";

export const UserGroupsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/user-groups/${_id}` }), [_id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? { active: true });
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        permissions: yup.array().min(1, messages.error.required),
      });
      const data = { ...formData, bank: formData?.bank?._id };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/user-groups/${_id}`, data) : await api.post("/user-groups", data);
      navigate(`/user-groups/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handlePermissionsChange = (value) => {
    const permissions = [...(formData.permissions ?? [])];
    const index = permissions.indexOf(value);
    if (index !== -1) permissions.splice(index, 1);
    else permissions.push(value);
    setFormData((state) => ({ ...state, permissions }));
  };

  return (
    <>
      <HStack p={2} mb={2}>
        <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/user-groups">
              Grupos de Usuários
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      <form onSubmit={handleSubmit}>
        <Card>
          <SimpleGrid spacing={4}>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel>Nome</FormLabel>
              <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Card>

        <Card>
          <Box mb={6}>
            <Text fontSize="lg">Permissões</Text>
            <Text fontSize="xs">
              As permissões definem o que os usuários deste grupo poderão fazer em todas as plataformas do ConsigSys relacionadas a esta
              organização.
            </Text>
          </Box>
          <SimpleGrid columns={[1, 2, 4]} spacing={4}>
            {permissions.map((item) => (
              <VStack key={item.title} align="stretch" mb={3}>
                <Text fontSize="sm" fontWeight="bold">
                  {item.title}
                </Text>
                {item.options.map((option) => (
                  <Checkbox
                    key={option.value}
                    isChecked={(formData.permissions ?? []).indexOf(option.value) !== -1}
                    onChange={() => handlePermissionsChange(option.value)}
                  >
                    <Text fontSize="sm">{option.label}</Text>
                  </Checkbox>
                ))}
              </VStack>
            ))}
          </SimpleGrid>
        </Card>

        <HStack justify="flex-end" mb={2}>
          <Button leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </>
  );
};
