import axios from "axios";
import { getAuth, getIdToken } from "@firebase/auth";
import * as eventEmitter from "./eventEmitter";

const errorHandler = {
  INVALID_AUTHORIZATION: (error) => eventEmitter.dispatch("onAuthorizationError", error),
  INVALID_MERCHANT: (error) => eventEmitter.dispatch("onMerchantError", error),
  PERMISSION_DENIED: (error) => eventEmitter.dispatch("onPermissionDenied", error),
  UNQUILIFIED_USER: (error) => eventEmitter.dispatch("onUnquilifiedUser", error),
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 180000,
});

api.interceptors.request.use(
  async (config) => {
    try {
      const auth = getAuth();
      const token = await getIdToken(auth.currentUser);
      config.headers.Authorization = token;
      config.headers.Merchant = localStorage.getItem("@current-merchant-id");
      return config;
    } catch (error) {
      return config;
    }
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    if (!error?.response && error?.message.search("Network") !== -1) eventEmitter.dispatch("onNetworkError", error);
    else errorHandler[error?.response?.data?.type]?.(error?.response?.data);
    return Promise.reject(error?.response?.data ?? error.message);
  }
);

api.upload = (path, file) => {
  const data = new FormData();
  data.append("file", file, file.name);
  return api.post(path, data, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    },
  });
};

export default api;
