import React, { useContext, useRef } from "react";
import { Button, Divider, HStack, Icon, Switch, Text, VStack, Tooltip } from "@chakra-ui/react";
import { Card, Dialog } from "components";
import { MdDelete } from "react-icons/md";
import { PrivateContext } from "pages/Private/Private";
import { ProductContext } from ".";

export const ProductInfo = () => {
  const { currentMerchant } = useContext(PrivateContext);
  const { data, handleDataChange, handleDataDelete } = useContext(ProductContext);
  const deleteRef = useRef();

  return (
    <>
      <Card>
        <VStack align="stretch">
          <HStack justify="space-between">
            <Text fontSize="xs">Ativo?</Text>
            <Divider />
            <Switch isChecked={data.active ?? false} onChange={() => handleDataChange({ active: !data.active })} />
          </HStack>
          <HStack justify="space-between">
            <Text fontSize="xs">Publico?</Text>
            <Divider />
            <Switch isChecked={data.public ?? false} onChange={() => handleDataChange({ public: !data.public })} />
          </HStack>
          <HStack justify="space-between">
            <Text fontSize="xs" whiteSpace="nowrap">
              Estoque
            </Text>
            <Divider />
            <Text fontSize="xs">{data.inventory?.toLocaleString() ?? 0}</Text>
          </HStack>
          <Tooltip label="Ação bloqueada para subsidiárias" isDisabled={!currentMerchant.parent} shouldWrapChildren>
            <Button
              colorScheme="red"
              size="sm"
              variant="ghost"
              mt={2}
              isFullWidth={true}
              leftIcon={<Icon as={MdDelete} />}
              onClick={() => deleteRef.current.confirm(data, handleDataDelete)}
              isDisabled={!!currentMerchant.parent}
            >
              Excluir produto
            </Button>
          </Tooltip>
        </VStack>
      </Card>
      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
