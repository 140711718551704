import {
  MdAccountBalanceWallet,
  MdAddShoppingCart,
  MdAttachMoney,
  MdCreditCard,
  MdDashboard,
  MdMap,
  MdPerson,
  MdPlace,
} from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import { FaHandHoldingUsd, FaTags, FaBox, FaBoxes, FaBell } from "react-icons/fa";
import { HiClipboardList } from "react-icons/hi";
import { FiBox } from "react-icons/fi";
import { CgTimer } from "react-icons/cg";
import { AiFillGift } from "react-icons/ai";
import { BiTransferAlt } from "react-icons/bi";

export const sidebarItems = [
  {
    id: Math.random(),
    items: [
      {
        id: Math.random(),
        icon: MdDashboard,
        text: "Home",
        to: "/home",
      },
    ],
  },
  {
    id: Math.random(),
    items: [
      {
        id: Math.random(),
        icon: MdPerson,
        text: "Usuários",
        to: "#",
        items: [
          { id: Math.random(), text: "Todos", to: "/users" },
          { id: Math.random(), text: "Grupos", to: "/user-groups" },
        ],
      },
      {
        id: Math.random(),
        icon: FiBox,
        text: "Produtos",
        to: "#",
        items: [
          { id: Math.random(), text: "Todos", to: "/products" },
          { id: Math.random(), text: "Atributos", to: "/product-attributes" },
          { id: Math.random(), text: "Grupos", to: "/product-groups" },
        ],
      },
      {
        id: Math.random(),
        icon: FaTags,
        text: "Etiquetas",
        to: "/tags",
      },
      {
        id: Math.random(),
        icon: MdAttachMoney,
        text: "Comissões",
        to: "/commissions",
      },
      {
        id: Math.random(),
        icon: MdMap,
        text: "Praças",
        to: "/zones",
      },
      {
        id: Math.random(),
        icon: MdPlace,
        text: "Cidades",
        to: "/cities",
      },
      {
        id: Math.random(),
        icon: AiFillGift,
        text: "P. de Vantagens",
        to: "/benefits",
      },
      {
        id: Math.random(),
        icon: FaBell,
        text: "Notificações",
        to: "/notifications",
      },
    ],
  },
  {
    id: Math.random(),
    items: [
      {
        id: Math.random(),
        icon: HiClipboardList,
        text: "Mov. de Inventário",
        to: "/inventory-movements",
      },
    ],
  },
  {
    id: Math.random(),
    items: [
      {
        id: Math.random(),
        icon: MdAddShoppingCart,
        text: "Encomendas",
        to: "/orders",
      },
      {
        id: Math.random(),
        icon: FaBox,
        text: "Pacotes",
        to: "/packs",
      },
      {
        id: Math.random(),
        icon: FaBoxes,
        text: "Remessas",
        to: "/shipments",
      },
      {
        id: Math.random(),
        icon: BiTransferAlt,
        text: "Movimentações",
        to: "/movements",
      },
    ],
  },
  {
    id: Math.random(),
    items: [
      {
        id: Math.random(),
        icon: MdAccountBalanceWallet,
        text: "Carteiras",
        to: "/wallets",
      },
      {
        id: Math.random(),
        icon: MdCreditCard,
        text: "Formas de Pagamento",
        to: "/payment-forms",
      },
      {
        id: Math.random(),
        icon: IoDocumentText,
        text: "Plano de Contas",
        to: "#",
        items: [
          { id: Math.random(), text: "Todos", to: "/chart-of-accounts" },
          { id: Math.random(), text: "Níveis", to: "/chart-of-account-levels" },
        ],
      },
      {
        id: Math.random(),
        icon: FaHandHoldingUsd,
        text: "Contas",
        to: "/accounts",
      },
      {
        id: Math.random(),
        icon: CgTimer,
        text: "Recorrências",
        to: "/recurrences",
      },
    ],
  },
];
