import { useState } from "react";
import { IconButton, Icon, Tooltip } from "@chakra-ui/react";
import { MdCheck } from "react-icons/md";
import { FiClipboard } from "react-icons/fi";

export const Clipboard = ({ value, icon = <Icon as={FiClipboard} />, size = "sm", colorScheme = "gray", variant, color }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), [2000]);
  };

  return (
    <Tooltip label={copied ? "Copiado!" : value} closeOnClick={false}>
      <IconButton
        icon={copied ? <Icon as={MdCheck} /> : icon}
        size={size}
        colorScheme={copied ? "green" : colorScheme}
        variant={variant}
        color={color}
        onClick={copyToClipboard}
      />
    </Tooltip>
  );
};
