import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { MdSearch, MdChevronRight, MdLocationCity } from "react-icons/md";
import { api } from "lib";

const CitiesNew = ({ isOpen, onClose }) => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { data } = await api.get("/cities", { params: { perPage: 20, page: 0, search, all: true } });
    setCities(data ?? []);
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <form onSubmit={handleSubmit}>
            <InputGroup>
              <Input variant="filled" placeholder="Buscar cidade..." value={search} onChange={({ target }) => setSearch(target.value)} />
              <InputRightElement>
                <IconButton type="submit" icon={<Icon as={MdSearch} />} isLoading={loading} variant="ghost" colorScheme="none" />
              </InputRightElement>
            </InputGroup>
          </form>
        </ModalHeader>
        <ModalBody>
          {!loading && !cities.length && (
            <Box textAlign="center" p="30px">
              <Icon as={MdLocationCity} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma cidade encontrada
              </Text>
              <Text fontSize="sm">Não encontramos cidades correspondentes à sua busca.</Text>
            </Box>
          )}

          {cities?.map(({ _id, name, state }) => (
            <Button
              isFullWidth
              key={_id}
              as={RouterLink}
              to={`/cities/details/${_id}`}
              variant="outline"
              mb="10px"
              rightIcon={<Icon as={MdChevronRight} />}
            >
              <Text flex="1" fontSize="sm">
                {name} / {state.initials}
              </Text>
            </Button>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CitiesNew;
