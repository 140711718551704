import moment from "moment";

export const formDataToQuery = (formData) => {
  const response = {};

  if (formData.user) response.user = formData.user;

  if (formData.statuses?.length) response.status = { $in: formData.statuses };
  if (formData.types?.length) response.type = { $in: formData.types };
  if (formData.wallets?.length) response.wallet = { $in: formData.wallets };
  if (formData.paymentForms?.length) response.paymentForm = { $in: formData.paymentForms };
  if (formData.chartOfAccounts?.length) response.chartOfAccount = { $in: formData.chartOfAccounts };

  if (formData["amount.partial"]?.start)
    response["amount.partial"] = { ...response["amount.partial"], $gte: formData["amount.partial"].start };
  if (formData["amount.partial"]?.end) response["amount.partial"] = { ...response["amount.partial"], $lte: formData["amount.partial"].end };

  if (formData["amount.remaining"]?.start)
    response["amount.remaining"] = { ...response["amount.remaining"], $gte: formData["amount.remaining"].start };
  if (formData["amount.remaining"]?.end)
    response["amount.remaining"] = { ...response["amount.remaining"], $lte: formData["amount.remaining"].end };

  if (formData["amount.receivable"]?.start)
    response["amount.receivable"] = { ...response["amount.receivable"], $gte: formData["amount.receivable"].start };
  if (formData["amount.receivable"]?.end)
    response["amount.receivable"] = { ...response["amount.receivable"], $lte: formData["amount.receivable"].end };

  if (formData["amount.received"]?.start)
    response["amount.received"] = { ...response["amount.received"], $gte: formData["amount.received"].start };
  if (formData["amount.received"]?.end)
    response["amount.received"] = { ...response["amount.received"], $lte: formData["amount.received"].end };

  if (formData.paymentDate?.start && moment(formData.paymentDate.start, "DD/MM/YYYY").isValid())
    response.paymentDate = { ...response.paymentDate, $gte: moment(formData.paymentDate.start, "DD/MM/YYYY").toDate() };
  if (formData.paymentDate?.end && moment(formData.paymentDate.end, "DD/MM/YYYY").isValid())
    response.paymentDate = { ...response.paymentDate, $lte: moment(formData.paymentDate.end, "DD/MM/YYYY").toDate() };

  if (formData.issueDate?.start && moment(formData.issueDate.start, "DD/MM/YYYY").isValid())
    response.issueDate = { ...response.issueDate, $gte: moment(formData.issueDate.start, "DD/MM/YYYY").toDate() };
  if (formData.issueDate?.end && moment(formData.issueDate.end, "DD/MM/YYYY").isValid())
    response.issueDate = { ...response.issueDate, $lte: moment(formData.issueDate.end, "DD/MM/YYYY").toDate() };

  if (formData.dueDate?.start && moment(formData.dueDate.start, "DD/MM/YYYY").isValid())
    response.dueDate = { ...response.dueDate, $gte: moment(formData.dueDate.start, "DD/MM/YYYY").toDate() };
  if (formData.dueDate?.end && moment(formData.dueDate.end, "DD/MM/YYYY").isValid())
    response.dueDate = { ...response.dueDate, $lte: moment(formData.dueDate.end, "DD/MM/YYYY").toDate() };

  if (formData.createdAt?.start && moment(formData.createdAt.start, "DD/MM/YYYY").isValid())
    response.createdAt = { ...response.createdAt, $gte: moment(formData.createdAt.start, "DD/MM/YYYY").toDate() };
  if (formData.createdAt?.end && moment(formData.createdAt.end, "DD/MM/YYYY").isValid())
    response.createdAt = { ...response.createdAt, $lte: moment(formData.createdAt.end, "DD/MM/YYYY").toDate() };

  return response;
};

export const queryToFormData = (query) => {
  const response = {};

  if (query.user) response.user = query.user;

  if (query.status?.$in) response.statuses = query.status.$in;
  if (query.type?.$in) response.types = query.type.$in;
  if (query.wallet?.$in) response.wallets = query.wallet.$in;
  if (query.paymentForm?.$in) response.paymentForms = query.paymentForm.$in;
  if (query.chartOfAccount?.$in) response.chartOfAccounts = query.chartOfAccount.$in;

  if (query["amount.partial"]?.$gte) response["amount.partial"] = { ...response["amount.partial"], start: query["amount.partial"].$gte };
  if (query["amount.partial"]?.$lte) response["amount.partial"] = { ...response["amount.partial"], end: query["amount.partial"].$lte };

  if (query["amount.remaining"]?.$gte)
    response["amount.remaining"] = { ...response["amount.remaining"], start: query["amount.remaining"].$gte };
  if (query["amount.remaining"]?.$lte)
    response["amount.remaining"] = { ...response["amount.remaining"], end: query["amount.remaining"].$lte };

  if (query["amount.receivable"]?.$gte)
    response["amount.receivable"] = { ...response["amount.receivable"], start: query["amount.receivable"].$gte };
  if (query["amount.receivable"]?.$lte)
    response["amount.receivable"] = { ...response["amount.receivable"], end: query["amount.receivable"].$lte };

  if (query["amount.received"]?.$gte)
    response["amount.received"] = { ...response["amount.received"], start: query["amount.received"].$gte };
  if (query["amount.received"]?.$lte) response["amount.received"] = { ...response["amount.received"], end: query["amount.received"].$lte };

  if (query.paymentDate?.$gte)
    response.paymentDate = { ...response.paymentDate, start: moment(query.paymentDate.$gte).format("DD/MM/YYYY") };
  if (query.paymentDate?.$lte) response.paymentDate = { ...response.paymentDate, end: moment(query.paymentDate.$lte).format("DD/MM/YYYY") };

  if (query.issueDate?.$gte) response.issueDate = { ...response.issueDate, start: moment(query.issueDate.$gte).format("DD/MM/YYYY") };
  if (query.issueDate?.$lte) response.issueDate = { ...response.issueDate, end: moment(query.issueDate.$lte).format("DD/MM/YYYY") };

  if (query.dueDate?.$gte) response.dueDate = { ...response.dueDate, start: moment(query.dueDate.$gte).format("DD/MM/YYYY") };
  if (query.dueDate?.$lte) response.dueDate = { ...response.dueDate, end: moment(query.dueDate.$lte).format("DD/MM/YYYY") };

  if (query.createdAt?.$gte) response.createdAt = { ...response.createdAt, start: moment(query.createdAt.$gte).format("DD/MM/YYYY") };
  if (query.createdAt?.$lte) response.createdAt = { ...response.createdAt, end: moment(query.createdAt.$lte).format("DD/MM/YYYY") };

  return response;
};
