const download = async (data, filename) => {
  const file = new Blob([data], { type: "csv" });
  if (window.navigator.msSaveOrOpenBlob) return window.navigator.msSaveOrOpenBlob(file, filename);
  const a = document.createElement("a");
  const url = URL.createObjectURL(file);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const csvDownload = async (columns, rows, filename) => {
  const separator = ";";
  const data = rows.map((row) => row.join(separator));
  data.unshift(columns.map(({ label }) => label).join(separator));
  const csv = `\uFEFF${data.join("\n")}`;
  await download(csv, `${filename}.csv`);
};
