import React, { useRef, useState, useEffect, useContext } from "react";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Icon, IconButton, Text, Tooltip, useToast } from "@chakra-ui/react";
import { MdDelete, MdAdd, MdArrowBack, MdEdit, MdCloud, MdVisibility } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { useCacheState, useStickyState } from "hooks";
import * as database from "database";
import { Card, Clipboard, DataTable, Dialog, StatusBadge } from "components";
import { NewMovement } from "containers";
import { messages } from "consts";
import { api, translator, currency } from "lib";
import { PrivateContext } from "pages/Private/Private";
import { FaBox, FaBoxes } from "react-icons/fa";

const refPathIcons = { Shipment: FaBoxes, Pack: FaBox };

export const MovementsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentMerchant } = useContext(PrivateContext);
  const [perPage, setPerPage] = useCacheState(20, "MovementsList:perPage");
  const [sort, setSort] = useStickyState({ createdAt: -1 }, "MovementsList:sort");
  const [page, setPage] = useCacheState(0, "MovementsList:page");
  const [movements, setMovements] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [timestampData, setTimestampData] = useState(Date.now());
  const [errorData, setErrorData] = useState();
  const [isOpenNewMovement, setIsOpenNewMovement] = useState(false);
  const toast = useToast();
  const deleteRef = useRef();

  useEffect(() => {
    (async () => {
      try {
        setLoadingData(true);
        let query = {};
        if (location.state) query = { $or: [{ source: location.state._id }, { destination: location.state._id }] };
        const response = await api.get("/movements", { params: { query, perPage, page, sort } });
        const entries = await database.main.entries.where({ merchant: currentMerchant._id }).toArray();
        const mapped = entries.map(({ _id }) => _id);
        const removes = [];
        for (const item of response.data) {
          const index = mapped.indexOf(item._id);
          if (index === -1 || item.closed) removes.push(item._id);
          else item.mustUpdate = true;
        }
        await database.main.entries.bulkDelete(removes);
        setMovements(response);
      } catch (error) {
        setErrorData(error);
      } finally {
        setLoadingData(false);
      }
    })();
  }, [currentMerchant._id, location.state, perPage, page, sort, timestampData]);

  const handleDeleteData = async (item) => {
    try {
      await api.delete(`/movements/${item._id}`);
      await database.main.entries.where({ _id: item._id, merchant: currentMerchant._id }).delete();
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      setTimestampData(Date.now());
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <HStack>
          {location.state && <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />}
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>Movimentações</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        {location.state && (
          <IconButton size="sm" colorScheme="main" icon={<Icon as={MdAdd} />} onClick={() => setIsOpenNewMovement(true)} />
        )}
      </HStack>

      {location.state && (
        <Card>
          <HStack spacing={4}>
            <Icon as={refPathIcons[location.state.refPath]} boxSize={4} />
            <Box flex={1}>
              <Text fontWeight="bold" lineHeight={1}>
                {translator(location.state.refPath)}
              </Text>
              <Text fontSize="sm">NID {location.state.nid}</Text>
            </Box>
            <IconButton icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          </HStack>
        </Card>
      )}

      <Card>
        <DataTable
          tableId="MovementsList:table"
          title="Movimentações"
          data={movements?.data ?? []}
          size={movements?.size ?? 0}
          loading={loadingData}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          onRefresh={() => setTimestampData(Date.now())}
          error={errorData}
          renderItems={[
            {
              key: "_id",
              label: "ID",
              visible: true,
              render: (value) => <Clipboard value={value} icon={<Icon as={CgHashtag} />} />,
            },
            { key: "event", label: "Evento", visible: true, formatter: translator },
            { key: "type", label: "Tipo", visible: true, formatter: translator },
            { key: "status", label: "Status", visible: true, exporter: translator, render: (value) => <StatusBadge label={value} /> },
            { key: "author.name", label: "Autor", visible: true, sortable: false },
            {
              key: "source.nid",
              label: "Origem",
              visible: true,
              sortable: false,
              formatter: (value, item) => (value ? `${translator(item.sourceRef)} ${value}` : "-"),
            },
            {
              key: "destination.nid",
              label: "Destino",
              visible: true,
              sortable: false,
              formatter: (value, item) => (value ? `${translator(item.destinationRef)} ${value}` : "-"),
            },
            { key: "quantity", label: "Quantidade", visible: true, formatter: (value) => value?.toLocaleString?.() ?? 0 },
            { key: "amount", label: "Valor", visible: true, formatter: currency.format },
            {
              key: "closedAt",
              label: "Fechamento",
              visible: true,
              formatter: (value) => (value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-"),
            },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          ActionsComponent={({ item }) =>
            item?.closed ? (
              <Tooltip label="Visualizar">
                <IconButton
                  icon={<Icon as={MdVisibility} />}
                  size="sm"
                  variant="ghost"
                  onClick={() => navigate(`/movements/details/${item?._id}`)}
                />
              </Tooltip>
            ) : (
              <HStack>
                <Tooltip label="Excluir">
                  <IconButton
                    icon={<Icon as={MdDelete} />}
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => deleteRef.current.confirm(item, handleDeleteData)}
                    isDisabled={item?.closed}
                  />
                </Tooltip>
                <Tooltip label="Editar">
                  <IconButton
                    icon={<Icon as={MdEdit} />}
                    size="sm"
                    variant="ghost"
                    onClick={() => navigate(`/movements/details/${item?._id}`)}
                    isDisabled={item?.closed}
                  />
                </Tooltip>
                {item?.mustUpdate && (
                  <Tooltip label="Sincronização necessária">
                    <IconButton size="sm" colorScheme="orange" variant="ghost" icon={<Icon as={MdCloud} />} />
                  </Tooltip>
                )}
              </HStack>
            )
          }
          onRowDoubleClick={({ _id }) => navigate(`/movements/details/${_id}`)}
        />
      </Card>

      {location.state && (
        <NewMovement
          refPath={location.state?.refPath}
          _id={location.state?._id}
          isOpen={isOpenNewMovement}
          onClose={() => setIsOpenNewMovement(false)}
        />
      )}

      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
