import React, { useState, useRef, useEffect } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  Input,
  Text,
} from "@chakra-ui/react";
import { IoWarningOutline } from "react-icons/io5";

export const Confirm = ({ textConfirmation = true, isOpen, onClose, onConfirm }) => {
  const keyword = "finalizar";
  const [text, setText] = useState("");
  const cancelRef = useRef();

  useEffect(() => {
    setText("");
  }, [isOpen]);

  const handleConfirm = () => {
    onClose();
    onConfirm();
  };

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Atenção
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text fontSize="sm">Deseja realmente finalizar esta movimentação?</Text>
            <Text fontSize="sm" fontWeight="bold" mb={6}>
              Esta é uma ação irreversível.
            </Text>
            {textConfirmation && (
              <Input placeholder={`Digite "${keyword}" para confirmar`} onChange={({ target }) => setText(target.value)} />
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="ghost" ref={cancelRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              ml={3}
              colorScheme="red"
              leftIcon={<Icon as={IoWarningOutline} />}
              isDisabled={textConfirmation ? text.toLowerCase() !== keyword : false}
              onClick={handleConfirm}
            >
              Confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
