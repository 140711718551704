import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Preloader } from "components";
import { Private, Public } from "pages";

const App = () => {
  const [currentAuthenticatedUser, setCurrentAuthenticatedUser] = useState();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      try {
        if (!user) localStorage.removeItem("@current-merchant-id");
      } finally {
        setCurrentAuthenticatedUser(user);
      }
    });
  }, []);

  return currentAuthenticatedUser === null ? <Public /> : currentAuthenticatedUser?.uid ? <Private /> : <Preloader />;
};

export default App;
