export const permissions = [
  {
    title: "Usuários",
    options: [
      { value: "read:users", label: "Ler" },
      { value: "save:users", label: "Salvar" },
      { value: "delete:users", label: "Excluir" },
    ],
  },

  {
    title: "Grupos de usuário",
    options: [
      { value: "read:user-groups", label: "Ler" },
      { value: "save:user-groups", label: "Salvar" },
      { value: "delete:user-groups", label: "Excluir" },
    ],
  },

  {
    title: "Produtos",
    options: [
      { value: "read:products", label: "Ler" },
      { value: "save:products", label: "Salvar" },
      { value: "delete:products", label: "Excluir" },
    ],
  },

  {
    title: "Atributos de produto",
    options: [
      { value: "read:product-attributes", label: "Ler" },
      { value: "save:product-attributes", label: "Salvar" },
      { value: "delete:product-attributes", label: "Excluir" },
    ],
  },

  {
    title: "Grupos de produto",
    options: [
      { value: "read:product-groups", label: "Ler" },
      { value: "save:product-groups", label: "Salvar" },
      { value: "delete:product-groups", label: "Excluir" },
    ],
  },

  {
    title: "Movimentações de Inventário",
    options: [
      { value: "read:inventory-movements", label: "Ler" },
      { value: "save:inventory-movements", label: "Salvar" },
      { value: "delete:inventory-movements", label: "Excluir" },
    ],
  },

  {
    title: "Cidades",
    options: [
      { value: "read:cities", label: "Ler" },
      { value: "save:cities", label: "Salvar" },
      { value: "delete:cities", label: "Excluir" },
    ],
  },

  {
    title: "Comissões",
    options: [
      { value: "read:commissions", label: "Ler" },
      { value: "save:commissions", label: "Salvar" },
      { value: "delete:commissions", label: "Excluir" },
    ],
  },

  {
    title: "Praças",
    options: [
      { value: "read:zones", label: "Ler" },
      { value: "save:zones", label: "Salvar" },
      { value: "delete:zones", label: "Excluir" },
    ],
  },

  {
    title: "Remessas",
    options: [
      { value: "read:shipments", label: "Ler" },
      { value: "save:shipments", label: "Salvar" },
      { value: "delete:shipments", label: "Excluir" },
    ],
  },

  {
    title: "Pacotes",
    options: [
      { value: "read:packs", label: "Ler" },
      { value: "save:packs", label: "Salvar" },
      { value: "delete:packs", label: "Excluir" },
    ],
  },

  {
    title: "Movimentações",
    options: [
      { value: "read:movements", label: "Ler" },
      { value: "save:movements", label: "Salvar" },
      { value: "delete:movements", label: "Excluir" },
    ],
  },

  {
    title: "Encomendas",
    options: [
      { value: "read:orders", label: "Ler" },
      { value: "save:orders", label: "Salvar" },
      { value: "delete:orders", label: "Excluir" },
    ],
  },

  {
    title: "Carteiras",
    options: [
      { value: "read:wallets", label: "Ler" },
      { value: "save:wallets", label: "Salvar" },
      { value: "delete:wallets", label: "Excluir" },
    ],
  },

  {
    title: "Formas de Pagamentos",
    options: [
      { value: "read:payment-forms", label: "Ler" },
      { value: "save:payment-forms", label: "Salvar" },
      { value: "delete:payment-forms", label: "Excluir" },
    ],
  },

  {
    title: "Contas",
    options: [
      { value: "read:accounts", label: "Ler" },
      { value: "save:accounts", label: "Salvar" },
      { value: "delete:accounts", label: "Excluir" },
    ],
  },

  {
    title: "Plano de Contas",
    options: [
      { value: "read:chart-of-accounts", label: "Ler" },
      { value: "save:chart-of-accounts", label: "Salvar" },
      { value: "delete:chart-of-accounts", label: "Excluir" },
    ],
  },

  {
    title: "Níveis de Plano de Contas",
    options: [
      { value: "read:chart-of-account-levels", label: "Ler" },
      { value: "save:chart-of-account-levels", label: "Salvar" },
      { value: "delete:chart-of-account-levels", label: "Excluir" },
    ],
  },

  {
    title: "Contas de Organização",
    options: [
      { value: "read:merchant-accounts", label: "Ler" },
      { value: "save:merchant-accounts", label: "Salvar" },
      { value: "delete:merchant-accounts", label: "Excluir" },
    ],
  },

  {
    title: "Imagens",
    options: [
      { value: "read:images", label: "Ler" },
      { value: "save:images", label: "Salvar" },
      { value: "delete:images", label: "Excluir" },
    ],
  },

  {
    title: "Etiquetas",
    options: [
      { value: "read:tags", label: "Ler" },
      { value: "save:tags", label: "Salvar" },
      { value: "delete:tags", label: "Excluir" },
    ],
  },

  {
    title: "Recorrências",
    options: [
      { value: "read:recurrences", label: "Ler" },
      { value: "save:recurrences", label: "Salvar" },
      { value: "delete:recurrences", label: "Excluir" },
    ],
  },

  {
    title: "Programa de Vantagens",
    options: [
      { value: "read:benefits", label: "Ler" },
      { value: "save:benefits", label: "Salvar" },
      { value: "delete:benefits", label: "Excluir" },
    ],
  },

  {
    title: "Notificações",
    options: [
      { value: "read:notifications", label: "Ler" },
      { value: "save:notifications", label: "Salvar" },
      { value: "delete:notifications", label: "Excluir" },
    ],
  },
];
