import React, { useRef, useMemo, createContext, useContext } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Box, Image } from "@chakra-ui/react";
import { MdDelete, MdAdd } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { Card, Clipboard, DataTable, Dialog } from "components";
import { messages } from "consts";
import { api, currency } from "lib";
import { PrivateContext } from "pages/Private/Private";
import Filter from "./filter";

export const ProductsListContext = createContext();

export const ProductsList = () => {
  const navigate = useNavigate();
  const { currentMerchant } = useContext(PrivateContext);
  const [perPage, setPerPage] = useCacheState(20, "ProductsList:perPage");
  const [sort, setSort] = useStickyState({ name: 1 }, "ProductsList:sort");
  const [page, setPage] = useCacheState(0, "ProductsList:page");
  const [query, setQuery] = useCacheState({}, "AccountsList:query");
  const [search, setSearch] = useCacheState("", "ProductsList:search");
  const [response, loading, refresh, error] = useApiGet(
    useMemo(() => ({ path: "/products", params: { search, query, perPage, page, sort } }), [search, query, perPage, page, sort])
  );
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/products/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <ProductsListContext.Provider value={{ query, setQuery, setPage, loading }}>
      <HStack p={2} mb={2} justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/products">
              Produtos
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label={currentMerchant.parent ? "Ação bloqueada para subsidiárias" : "Novo"} shouldWrapChildren>
          <IconButton
            size="sm"
            colorScheme="main"
            onClick={() => navigate("/products/details")}
            icon={<Icon as={MdAdd} boxSize={4} />}
            isDisabled={!!currentMerchant.parent}
          />
        </Tooltip>
      </HStack>

      <Filter />

      <Card>
        <DataTable
          tableId="ProductsList:table"
          title="Produtos"
          data={response?.data ?? []}
          size={response?.size ?? 0}
          loading={loading}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          search={search}
          onSearch={setSearch}
          onRefresh={refresh}
          error={error}
          renderItems={[
            { key: "_id", label: "ID", visible: true, render: (value) => <Clipboard value={value} icon={<Icon as={CgHashtag} />} /> },
            { key: "nid", label: "NID", visible: true },
            {
              key: "mainImg",
              label: "Imagem",
              visible: true,
              exportable: false,
              render: (value) => (
                <Box boxSize="50px">
                  <Image
                    borderRadius="md"
                    objectFit="cover"
                    src={value?.transforms?.thumbnail?.location}
                    fallbackSrc="/assets/imgs/empty.jpg"
                  />
                </Box>
              ),
            },
            { key: "name", label: "Nome", visible: true },
            { key: "sku", label: "REF", visible: true },
            {
              key: "costPrice",
              label: "Preço de custo",
              visible: true,
              formatter: (value) => currency.format(value),
            },
            {
              key: "wholesalePrice",
              label: "Preço de atacado",
              visible: true,
              formatter: (value) => currency.format(value),
            },
            {
              key: "retailPrice",
              label: "Preço de varejo",
              visible: true,
              formatter: (value) => currency.format(value),
            },
            { key: "inventory", label: "Estoque", visible: true, formatter: (value) => value?.toLocaleString?.() || 0 },
            {
              key: "productGroups",
              label: "Grupos",
              sortable: false,
              visible: true,
              formatter: (value) => value?.map(({ name }) => name).join(", ") || "-",
            },
            { key: "supplier.name", label: "Fornecedor", visible: true, formatter: (value) => value ?? "-" },
            { key: "fiscal.ncm.code", label: "NCM", visible: true, formatter: (value) => value ?? "-" },
            {
              key: "active",
              label: "Ativo?",
              visible: true,
              formatter: (value) => (value ? "Sim" : "Não"),
            },
            {
              key: "public",
              label: "Público?",
              visible: true,
              formatter: (value) => (value ? "Sim" : "Não"),
            },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              visible: false,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          ActionsComponent={({ item }) => (
            <Tooltip label={currentMerchant.parent ? "Ação bloqueada para subsidiárias" : "Excluir"} shouldWrapChildren>
              <IconButton
                icon={<Icon as={MdDelete} />}
                size="sm"
                variant="ghost"
                colorScheme="red"
                onClick={() => deleteRef.current.confirm(item, handleDelete)}
                isDisabled={!!currentMerchant.parent}
              />
            </Tooltip>
          )}
          onRowDoubleClick={({ _id, cluster }) => navigate(`/products/details/${cluster}?p=${_id}`)}
        />
      </Card>
      <Dialog.Delete ref={deleteRef} />
    </ProductsListContext.Provider>
  );
};
