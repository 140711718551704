import React, { useState, useContext, useEffect } from "react";
import { Button, HStack, Icon, IconButton, Input, SimpleGrid, Text, Tooltip, VStack } from "@chakra-ui/react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdSearch } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import { Card, InputCurrency } from "components";
import { usePrevious, useCacheState } from "hooks";
import { ProductsListContext } from "./index";
import * as queryFormatter from "./queryFormatter";

const LabelButton = ({ children, active, onClick }) => (
  <Button size="xs" colorScheme={active ? "main" : "gray"} onClick={onClick}>
    {children}
  </Button>
);

const Filter = () => {
  const { query, setQuery, setPage, loading } = useContext(ProductsListContext);
  const [formData, setFormData] = useState({});
  const [visible, setVisible] = useCacheState(false, "ProductsListFilter:visible");
  const prevFormData = usePrevious(formData);

  useEffect(() => {
    if (!prevFormData) setFormData(queryFormatter.queryToFormData(query));
  }, [prevFormData, query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const query = queryFormatter.formDataToQuery(formData);
    setQuery(query);
    setPage(0);
  };

  const handleCleanQuery = () => {
    setFormData({});
    setQuery({});
    setPage(0);
  };

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <HStack justify="space-between">
          <HStack>
            {Object.keys(query).length > 0 && (
              <Tooltip label="Limpar filtros">
                <IconButton colorScheme="main" variant="ghost" icon={<Icon as={FaCircle} />} onClick={handleCleanQuery} />
              </Tooltip>
            )}
            <Text fontWeight="bold">Filtros</Text>
          </HStack>
          <HStack>
            <Tooltip label="Filtrar">
              <IconButton type="submit" icon={<Icon as={MdSearch} />} isLoading={loading} />
            </Tooltip>
            <Tooltip label="Exibir/Ocultar filtros">
              <IconButton icon={<Icon as={visible ? MdKeyboardArrowUp : MdKeyboardArrowDown} />} onClick={() => setVisible(!visible)} />
            </Tooltip>
          </HStack>
        </HStack>

        {visible && (
          <>
            <SimpleGrid columns={[1, 3]} spacing={4} mt={2} mb={4}>
              <VStack align="stretch">
                <LabelButton active={formData.nid} onClick={() => setFormData((state) => ({ ...state, nid: undefined }))}>
                  NID
                </LabelButton>
                <Input value={formData.nid ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))} />
              </VStack>

              <VStack align="stretch">
                <LabelButton
                  active={formData.inventory?.start}
                  onClick={() => setFormData((state) => ({ ...state, inventory: { ...state.inventory, start: undefined } }))}
                >
                  Estoque (início)
                </LabelButton>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.inventory?.start ?? ""}
                  onChange={(value) => setFormData((state) => ({ ...state, inventory: { ...state.inventory, start: value } }))}
                />
              </VStack>

              <VStack align="stretch">
                <LabelButton
                  active={formData.inventory?.end}
                  onClick={() => setFormData((state) => ({ ...state, inventory: { ...state.inventory, end: undefined } }))}
                >
                  Estoque (fim)
                </LabelButton>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.inventory?.end ?? ""}
                  onChange={(value) => setFormData((state) => ({ ...state, inventory: { ...state.inventory, end: value } }))}
                />
              </VStack>
            </SimpleGrid>
          </>
        )}
      </form>
    </Card>
  );
};

export default Filter;
