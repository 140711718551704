export const formDataToQuery = (formData) => {
  const response = {};

  if (formData.nid) response.nid = parseInt(formData.nid);
  if (formData.sku) response.sku = formData.sku;

  if (formData.inventory?.start) response.inventory = { ...response.inventory, $gte: formData.inventory.start };
  if (formData.inventory?.end) response.inventory = { ...response.inventory, $lte: formData.inventory.end };

  return response;
};

export const queryToFormData = (query) => {
  const response = {};

  if (query.nid) response.nid = parseInt(query.nid);
  if (query.sku) response.sku = query.sku;

  if (query.inventory?.$gte) response.inventory = { ...response.inventory, start: query.inventory.$gte };
  if (query.inventory?.$lte) response.inventory = { ...response.inventory, end: query.inventory.$lte };

  return response;
};
