import { Badge } from "@chakra-ui/react";
import { translator } from "lib";

const colorSchemes = {
  PAID: "green",
  PENDING: "yellow",
  CANCELED: "red",
  FAILED: "red",
  DISSOLVED: "gray",
  PREPARING: "orange",
  DISPATCHED: "main",
  DELIVERED: "green",
  LOST: "red",
  DEFAULT: "main",
  ORDER: "teal",
  SPECIAL: "purple",
  SYNCED: "green",
  FINISHED: "main",
  SUCCESS: "green",
};

export const StatusBadge = ({ label }) => <Badge colorScheme={colorSchemes[label]}>{translator(label)}</Badge>;
