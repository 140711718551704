import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Container from "./Container";
import * as Pages from "./";

const PublicContainer = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="sign-in" element={<Container />}>
          <Route index={true} element={<Pages.SignIn />} />
          <Route path="phone-number">
            <Route index={true} element={<Pages.SignInPhoneNumberSend />} />
            <Route path="validate" element={<Pages.SignInPhoneNumberValidate />} />
          </Route>
          <Route path="email-link">
            <Route index={true} element={<Pages.SignInEmailLinkSend />} />
            <Route path="sended" element={<Pages.SignInEmailLinkSended />} />
            <Route path="validate" element={<Pages.SignInEmailLinkValidate />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/sign-in" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default PublicContainer;
