import React, { useState, useContext, useEffect, memo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Button, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Icon, List, ListItem, Text } from "@chakra-ui/react";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { BiRadioCircle } from "react-icons/bi";
import { sidebarItems } from "consts";
import { ContainerContext } from "./index";

const MenuItem = ({ item, onClick }) => {
  return (
    <Button
      as={Link}
      to={item.to}
      variant={item.active ? "solid" : "ghost"}
      colorScheme={item.active ? "main" : "gray"}
      borderLeftRadius="none"
      borderRightRadius="xl"
      isFullWidth={true}
      leftIcon={<Icon as={item.icon ?? BiRadioCircle} marginRight="10px" boxSize={4} />}
      rightIcon={item.items && <Icon as={item.expanded ? MdExpandLess : MdExpandMore} marginLeft="10px" />}
      onClick={onClick}
      fontSize="xs"
    >
      <Text flex={1}>{item.text}</Text>
    </Button>
  );
};

const Nav = memo(() => {
  const location = useLocation();
  const [expanded, setExpanded] = useState({});
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = [...sidebarItems];
    const processor = (items = []) => {
      for (const item of items) {
        item.expanded = expanded[item.id];
        item.active = item.to && location.pathname.search(item.to) !== -1;
        if (item.items) item.items = processor(item.items);
      }
      return items;
    };
    setItems(processor(items));
  }, [expanded, location.pathname]);

  const MenuItems = ({ data }) => {
    return (
      <List>
        {data.map((item) => (
          <ListItem key={item.id}>
            <MenuItem item={item} onClick={() => setExpanded((state) => ({ ...state, [item.id]: !state[item.id] }))} />
            {item.items && item.expanded && <MenuItems data={item.items} />}
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Box>
      {items.map((group) => (
        <Box key={group.id}>
          {group.title && (
            <Text fontSize="xs" fontWeight="bold" p={3} mt={3}>
              {group.title}
            </Text>
          )}
          <MenuItems data={group.items} />
        </Box>
      ))}
    </Box>
  );
});

const Sidebar = () => {
  const { isMobileView, opened, handleToggle } = useContext(ContainerContext);

  return isMobileView ? (
    <Drawer placement="left" onClose={handleToggle} isOpen={opened}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerBody padding="20px 0">
            <Nav />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  ) : (
    <>
      {opened && (
        <Box overflowY="auto" py="10px" pr="10px" boxShadow="0 0 5px rgba(0,0,0,0.2)" zIndex={1}>
          <Nav />
        </Box>
      )}
    </>
  );
};

export default Sidebar;
