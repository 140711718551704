export const PRODUCT = {
  _id: "61c9ed751b79e8634dcfdd17",
  nid: 1234,
  sku: "BKR1234",
  name: "Biker Leather Jacket",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  costPrice: 80,
  wholesalePrice: 140,
  retailPrice: 160,
  attrs: [{ name: "M" }, { name: "Preta" }],
};

export const PACK = {
  _id: "61c9ed751b79e8634dcfdd17",
  nid: 1234,
  agent: { name: "João Silveira" },
  dealer: { name: "Maria Clara Santos" },
  load: {
    original: { amount: 7894 },
  },
  shipment: {
    zone: {
      name: "Alto floresta",
    },
  },
  distribution: {
    week: 2,
    year: 2022,
  },
  charge: {
    week: 6,
    year: 2022,
  },
};
