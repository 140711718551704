import React, { useRef, useState, useMemo, createContext } from "react";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Box, Text } from "@chakra-ui/react";
import { MdDelete, MdAdd, MdArrowBack } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { IoCopyOutline } from "react-icons/io5";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { Clipboard, DataTable, Dialog, StatusBadge, OwnershipButton, Card } from "components";
import { messages } from "consts";
import { api, translator, currency } from "lib";
import Filter from "./filter";
import Duplicate from "./duplicate";
import { FaBoxes } from "react-icons/fa";

export const PacksListContext = createContext();

export const PacksList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [perPage, setPerPage] = useCacheState(20, "PacksList:perPage");
  const [sort, setSort] = useStickyState({ nid: -1 }, "PacksList:sort");
  const [page, setPage] = useCacheState(0, "PacksList:page");
  const [query, setQuery] = useCacheState({}, "PacksList:query");
  const [response, loading, refresh, error] = useApiGet(
    useMemo(
      () => ({ path: "/packs", params: { query: location.state ? { shipment: location.state._id } : query, perPage, page, sort } }),
      [location.state, query, perPage, page, sort]
    )
  );
  const toast = useToast();
  const deleteRef = useRef();
  const [selected, setSelected] = useState(null);

  const handleDelete = async (item) => {
    try {
      await api.delete(`/packs/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <PacksListContext.Provider value={{ query, setQuery, setPage, loading }}>
      <HStack p={2} mb={2} justify="space-between">
        <HStack>
          {location.state && <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />}
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink as={Link} to="/packs">
                Pacotes
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/packs/details" icon={<Icon as={MdAdd} boxSize={4} />} />
        </Tooltip>
      </HStack>

      <Card>
        {location.state ? (
          <HStack spacing={4}>
            <Icon as={FaBoxes} boxSize={4} />
            <Box flex={1}>
              <Text fontWeight="bold" lineHeight={1}>
                REMESSA
              </Text>
              <Text fontSize="sm">NID {location.state.nid}</Text>
            </Box>
            <IconButton icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          </HStack>
        ) : (
          <Filter />
        )}
      </Card>

      <Card>
        <DataTable
          tableId="PacksList:table"
          title="Pacotes"
          data={response?.data ?? []}
          size={response?.size ?? 0}
          loading={loading}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          onRefresh={refresh}
          error={error}
          renderItems={[
            {
              key: "_id",
              label: "ID",
              visible: true,
              render: (value, item) => (
                <HStack>
                  <Clipboard value={value} icon={<Icon as={CgHashtag} />} />
                  <OwnershipButton value={item.shipment?.ownership} />
                </HStack>
              ),
            },
            { key: "nid", label: "NID", visible: true },
            { key: "status", label: "Status", visible: true, exporter: translator, render: (value) => <StatusBadge label={value} /> },
            { key: "dealer.name", label: "Revendedor", visible: true, sortable: false },
            { key: "agent.name", label: "Representante", visible: true, sortable: false },
            { key: "shipment.nid", label: "Remessa", visible: true, sortable: false },
            { key: "load.original.quantity", label: "Qtd carregada", visible: true },
            { key: "load.current.quantity", label: "Qtd atual", visible: true },
            { key: "load.modified.quantity", label: "Qtd modificada", visible: true },
            { key: "load.original.amount", label: "Valor carregado", visible: true, formatter: currency.format },
            { key: "load.current.amount", label: "Valor atual", visible: true, formatter: currency.format },
            { key: "load.modified.amount", label: "Valor modificado", visible: true, formatter: currency.format },
            { key: "amount.received", label: "Valor recebido", visible: true, formatter: currency.format },
            { key: "amount.remaining", label: "Valor pendente", visible: true, formatter: currency.format },
            {
              key: "closedAt",
              label: "Fechamento",
              visible: true,
              formatter: (value) => (value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-"),
            },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          ActionsComponent={({ item }) => (
            <HStack>
              <Tooltip label="Duplicar">
                <IconButton size="sm" variant="ghost" icon={<Icon as={IoCopyOutline} />} onClick={() => setSelected(item._id)} />
              </Tooltip>
              <Tooltip label="Excluir">
                <IconButton
                  icon={<Icon as={MdDelete} />}
                  size="sm"
                  variant="ghost"
                  colorScheme="red"
                  onClick={() => deleteRef.current.confirm(item, handleDelete)}
                />
              </Tooltip>
            </HStack>
          )}
          onRowDoubleClick={({ _id }) => navigate(`/packs/details/${_id}`)}
        />
      </Card>
      <Dialog.Delete ref={deleteRef} />
      <Duplicate _id={selected} onClose={() => setSelected(null)} onFinish={refresh} />
    </PacksListContext.Provider>
  );
};
