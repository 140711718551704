import React, { useContext } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from "@chakra-ui/react";
import { InputCurrency } from "components";
import { BuilderContext } from "..";

const PageSettings = ({ isOpen, onClose }) => {
  const { formData, setFormData } = useContext(BuilderContext);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="2xl" isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configurações de página</ModalHeader>
          <Divider />
          <ModalBody>
            <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
              <FormControl isRequired={true}>
                <FormLabel>Colunas</FormLabel>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.columnsQuantity ?? ""}
                  onChange={(columnsQuantity) => setFormData((state) => ({ ...state, columnsQuantity }))}
                />
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Linhas</FormLabel>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.rowsQuantity ?? ""}
                  onChange={(rowsQuantity) => setFormData((state) => ({ ...state, rowsQuantity }))}
                />
              </FormControl>
            </SimpleGrid>

            <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
              <FormControl isRequired={true}>
                <FormLabel>Margem esquerda da página</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={formData.pageStyle?.paddingLeft ?? ""}
                    onChange={(paddingLeft) => setFormData((state) => ({ ...state, pageStyle: { ...state.pageStyle, paddingLeft } }))}
                  />
                  <InputRightElement>cm</InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Margem direita da página</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={formData.pageStyle?.paddingRight ?? ""}
                    onChange={(paddingRight) => setFormData((state) => ({ ...state, pageStyle: { ...state.pageStyle, paddingRight } }))}
                  />
                  <InputRightElement>cm</InputRightElement>
                </InputGroup>
              </FormControl>
            </SimpleGrid>

            <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
              <FormControl isRequired={true}>
                <FormLabel>Margem superior da página</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={formData.pageStyle?.paddingTop ?? ""}
                    onChange={(paddingTop) => setFormData((state) => ({ ...state, pageStyle: { ...state.pageStyle, paddingTop } }))}
                  />
                  <InputRightElement>cm</InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Margem inferior da página</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={formData.pageStyle?.paddingBottom ?? ""}
                    onChange={(paddingBottom) => setFormData((state) => ({ ...state, pageStyle: { ...state.pageStyle, paddingBottom } }))}
                  />
                  <InputRightElement>cm</InputRightElement>
                </InputGroup>
              </FormControl>
            </SimpleGrid>

            <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
              <FormControl isRequired={true}>
                <FormLabel>Espaço entre colunas</FormLabel>
                <Image src="/assets/imgs/tag-grid-columns-gap.svg" mb="10px" />
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={formData.pageStyle?.columnsGap ?? ""}
                    onChange={(columnsGap) => setFormData((state) => ({ ...state, pageStyle: { ...state.pageStyle, columnsGap } }))}
                  />
                  <InputRightElement>cm</InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Espaço entre linhas</FormLabel>
                <Image src="/assets/imgs/tag-grid-rows-gap.svg" mb="10px" />
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={formData.pageStyle?.rowsGap ?? ""}
                    onChange={(rowsGap) => setFormData((state) => ({ ...state, pageStyle: { ...state.pageStyle, rowsGap } }))}
                  />
                  <InputRightElement>cm</InputRightElement>
                </InputGroup>
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button onClick={onClose}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PageSettings;
