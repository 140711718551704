import React from "react";
import { VStack, FormControl, FormLabel, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import { InputCurrency } from "components";

export const QRCode = ({ data, onChange }) => {
  return (
    <VStack>
      <FormControl>
        <FormLabel fontSize="xs" mb="2px">
          Tamanho
        </FormLabel>
        <InputGroup>
          <Input as={InputCurrency}
            precision="0"
            value={data.props.size ?? ""}
            onChange={(floatValue) => onChange({ ...data, props: { ...data.props, size: floatValue } })}
          />
          <InputRightElement>
            <Text>px</Text>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </VStack>
  );
};
