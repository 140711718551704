import React from "react";
import { Center, useColorModeValue } from "@chakra-ui/react";
import { Card } from "components";
import { Outlet } from "react-router-dom";

const Container = ({ children }) => {
  const backgroundColor = useColorModeValue("gray.100", "gray.900");

  return (
    <Center width="100vw" height="100vh" bg={backgroundColor}>
      <Card width={{ base: "90%", md: "400px" }} p={8}>
        <Outlet />
      </Card>
    </Center>
  );
};

export default Container;
