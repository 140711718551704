import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR";
import moment from "moment";
import "moment/locale/pt-br";
import { theme, firebaseConfig } from "consts";
import App from "./App";
import { initializeApp } from "firebase/app";

registerLocale("pt-br", ptBr);
setDefaultLocale("pt-br");
moment.locale("pt-br");

initializeApp(firebaseConfig);

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>,
  document.getElementById("root")
);
