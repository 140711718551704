import React, { useRef, useEffect, useState, useContext } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdDelete, MdAdd, MdCloud } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { useCacheState, useStickyState } from "hooks";
import { Card, Clipboard, DataTable, Dialog } from "components";
import { messages } from "consts";
import { api, translator } from "lib";
import * as database from "database";
import { PrivateContext } from "pages/Private/Private";

export const InventoryMovementsList = () => {
  const navigate = useNavigate();
  const { currentMerchant } = useContext(PrivateContext);
  const [perPage, setPerPage] = useCacheState(20, "InventoryMovementsList:perPage");
  const [sort, setSort] = useStickyState({ title: 1 }, "InventoryMovementsList:sort");
  const [page, setPage] = useCacheState(0, "InventoryMovementsList:page");
  const [search, setSearch] = useCacheState("", "InventoryMovementsList:search");
  const [response, setResponse] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [timestampData, setTimestampData] = useState(Date.now());
  const [errorData, setErrorData] = useState();
  const toast = useToast();
  const deleteRef = useRef();

  useEffect(() => {
    (async () => {
      try {
        setLoadingData(true);
        const response = await api.get("/inventory-movements", { params: { search, perPage, page, sort } });
        const entries = await database.main.entries.where({ merchant: currentMerchant._id }).toArray();
        const mapped = entries.map(({ _id }) => _id);
        const removes = [];
        for (const item of response.data) {
          const index = mapped.indexOf(item._id);
          if (index === -1 || item.closed) removes.push(item._id);
          else item.mustUpdate = true;
        }
        await database.main.entries.bulkDelete(removes);
        setResponse(response);
      } catch (error) {
        setErrorData(error);
      } finally {
        setLoadingData(false);
      }
    })();
  }, [currentMerchant._id, search, perPage, page, sort, timestampData]);

  const handleDelete = async (item) => {
    try {
      await api.delete(`/inventory-movements/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      setTimestampData(Date.now());
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/inventory-movements">
              Movimentações de Inventário
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/inventory-movements/details" icon={<Icon as={MdAdd} boxSize={4} />} />
        </Tooltip>
      </HStack>
      <Card>
        <DataTable
          tableId="InventoryMovementsList:table"
          title="Movimentações de inventário"
          data={response?.data ?? []}
          size={response?.size ?? 0}
          loading={loadingData}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          search={search}
          onSearch={setSearch}
          onRefresh={() => setTimestampData(Date.now())}
          error={errorData}
          renderItems={[
            { key: "_id", label: "ID", visible: true, render: (value) => <Clipboard value={value} icon={<Icon as={CgHashtag} />} /> },
            { key: "title", label: "Título", visible: true },
            { key: "type", label: "Tipo", visible: true, formatter: translator },
            { key: "notListedProducts", label: "Não listados", visible: true, formatter: translator },
            { key: "author.name", label: "Autor", visible: true, sortable: false },
            {
              key: "closedAt",
              label: "Fechamento",
              visible: true,
              formatter: (value) => (value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-"),
            },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          ActionsComponent={({ item }) => (
            <HStack>
              <Tooltip label="Excluir">
                <IconButton
                  icon={<Icon as={MdDelete} />}
                  size="sm"
                  variant="ghost"
                  colorScheme="red"
                  onClick={() => deleteRef.current.confirm(item, handleDelete)}
                />
              </Tooltip>
              {item?.mustUpdate && (
                <Tooltip label="Sincronização necessária">
                  <IconButton size="sm" colorScheme="orange" variant="ghost" icon={<Icon as={MdCloud} />} />
                </Tooltip>
              )}
            </HStack>
          )}
          onRowDoubleClick={({ _id }) => navigate(`/inventory-movements/details/${_id}`)}
        />
      </Card>
      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
