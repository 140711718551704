import React, { useContext, useEffect, useMemo } from "react";
import { FormControl, FormErrorMessage, FormLabel, HStack, Select, SimpleGrid, Spinner } from "@chakra-ui/react";
import moment from "moment";
import _ from "lodash";
import { api } from "lib";
import { useApiGet } from "hooks";
import { AsyncSelect, DatePicker } from "components";
import { AccountsDetailsContext } from "./index";

let loadUsersTimeout = null;

export const General = () => {
  const { formData, setFormData, formErrors, isPaid } = useContext(AccountsDetailsContext);
  const [paymentForms, loadingPaymentForms] = useApiGet(useMemo(() => ({ path: "/payment-forms", params: { sort: { name: 1 } } }), []));
  const [wallets, loadingWallets] = useApiGet(useMemo(() => ({ path: "/wallets", params: { sort: { name: 1 } } }), []));
  const [chartOfAccounts, loadingChartOfAccounts] = useApiGet(
    useMemo(() => ({ path: "/chart-of-accounts", params: { query: { type: formData.type }, sort: { name: 1 } } }), [formData.type])
  );

  useEffect(() => {
    setFormData((state) => ({ ...state, paymentDate: isPaid ? new Date() : undefined }));
  }, [isPaid, setFormData]);

  const handlePaymentFormChange = ({ target }) => {
    const paymentForm = _.find(paymentForms?.data, (o) => o._id === target.value);
    setFormData((state) => ({
      ...state,
      paymentForm,
      wallet: paymentForm?.wallet,
      chartOfAccount: paymentForm?.chartOfAccount,
    }));
  };

  const handleLoadUsers = (search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const data = await api.get("/users", { params: { search } });
      const response = _.map(data?.data, (item) => ({ ...item, isDisabled: !item.qualified }));
      cb(response);
    }, 1000);
  };

  return (
    <>
      <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors.type}>
          <FormLabel>Tipo</FormLabel>
          <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
            <option value="REVENUE">Receita</option>
            <option value="EXPENSE">Despesa</option>
          </Select>
          <FormErrorMessage>{formErrors.type}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors.user}>
          <FormLabel>Usuário</FormLabel>
          <AsyncSelect
            value={formData.user ?? {}}
            loadOptions={handleLoadUsers}
            placeholder="Selecione o usuário"
            onChange={(user) => setFormData((state) => ({ ...state, user }))}
            getOptionValue={({ _id }) => _id}
            formatOptionLabel={({ name }) => name}
          />
          <FormErrorMessage>{formErrors.user}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors.status}>
          <FormLabel>Status</FormLabel>
          <Select value={formData.status ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
            <option value="PAID">Pago</option>
            <option value="PENDING">Pendente</option>
          </Select>
          <FormErrorMessage>{formErrors.status}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors.wallet}>
          <FormLabel>Forma de Pagamento</FormLabel>
          <HStack>
            <Select value={formData.paymentForm?._id ?? ""} onChange={handlePaymentFormChange}>
              <option value="">--Selecione</option>
              {(paymentForms?.data ?? []).map((o) => (
                <option key={o._id} value={o._id}>
                  {o.name}
                </option>
              ))}
            </Select>
            {loadingPaymentForms && <Spinner size="sm" />}
          </HStack>
          <FormErrorMessage>{formErrors.paymentForm}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors.wallet}>
          <FormLabel>Carteira</FormLabel>
          <HStack>
            <Select
              value={formData.wallet?._id ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, wallet: { _id: target.value } }))}
            >
              <option value="">--Selecione</option>
              {(wallets?.data ?? []).map((o) => (
                <option key={o._id} value={o._id}>
                  {o.name}
                </option>
              ))}
            </Select>
            {loadingWallets && <Spinner size="sm" />}
          </HStack>
          <FormErrorMessage>{formErrors.wallet}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors.chartOfAccount}>
          <FormLabel>Plano de Contas</FormLabel>
          <HStack>
            <Select
              value={formData.chartOfAccount?._id ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, chartOfAccount: { _id: target.value } }))}
              isDisabled={loadingChartOfAccounts}
            >
              <option value="">--Selecione</option>
              {(chartOfAccounts?.data ?? []).map((o) => (
                <option key={o._id} value={o._id}>
                  {o.name}
                </option>
              ))}
            </Select>
            {loadingChartOfAccounts && <Spinner size="sm" />}
          </HStack>
          <FormErrorMessage>{formErrors.chartOfAccount}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={[1, 3]} spacing={4}>
        <FormControl isRequired={true} isInvalid={formErrors.issueDate}>
          <FormLabel>Data de emissão</FormLabel>
          <DatePicker
            selected={formData.issueDate}
            onChange={(issueDate) => setFormData((state) => ({ ...state, issueDate: moment(issueDate).startOf("day").toDate() }))}
            dateFormat="d, MMMM yyyy"
          />
          <FormErrorMessage>{formErrors.issueDate}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors.dueDate}>
          <FormLabel>Data de vencimento</FormLabel>
          <DatePicker
            selected={formData.dueDate}
            onChange={(dueDate) => setFormData((state) => ({ ...state, dueDate: moment(dueDate).startOf("day").toDate() }))}
            dateFormat="d, MMMM yyyy"
          />
          <FormErrorMessage>{formErrors.dueDate}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={isPaid} isInvalid={formErrors.paymentDate}>
          <FormLabel>Data de pagamento</FormLabel>
          <DatePicker
            selected={formData.paymentDate}
            onChange={(paymentDate) => setFormData((state) => ({ ...state, paymentDate: moment(paymentDate).startOf("day").toDate() }))}
            dateFormat="d, MMMM yyyy"
            disabled={!isPaid}
          />
          <FormErrorMessage>{formErrors.paymentDate}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </>
  );
};
