import React, { useState, useEffect, createContext, useMemo } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  Spinner,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { MdSave, MdArrowBack } from "react-icons/md";
import { Card, InputCurrency } from "components";
import Images from "./images";

export const BenefitsDetailsContext = createContext();

export const BenefitsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/benefits/${_id}` }), [_id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? { active: true });
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        description: yup.string().required(messages.error.required),
        requiredScore: yup.number().required(messages.error.required),
      });
      const data = { ...formData, bank: formData?.bank?._id };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/benefits/${_id}`, data) : await api.post("/benefits", data);
      navigate(`/benefits/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <BenefitsDetailsContext.Provider value={{ formData, setFormData }}>
      <HStack p={2} mb={2} justify="space-between">
        <HStack>
          <IconButton size="sm" variant="ghost" as={RouterLink} to="/benefits" icon={<Icon as={MdArrowBack} />} />
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/benefits">
                Programa de vantagens
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.title ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        <HStack>
          <Text fontSize="sm">Ativo?</Text>
          <Switch isChecked={formData.active} onChange={() => setFormData((state) => ({ ...state, active: !state.active }))} />
        </HStack>
      </HStack>

      <form onSubmit={handleSubmit}>
        <Card>
          <SimpleGrid spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel>Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.requiredScore}>
              <FormLabel>Pontuação requerida</FormLabel>
              <Input
                as={InputCurrency}
                precision="0"
                value={formData.requiredScore ?? 0}
                onChange={(floatValue) => setFormData((state) => ({ ...state, requiredScore: floatValue }))}
              />
              <FormErrorMessage>{formErrors.requiredScore}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid spacing={4}>
            <FormControl isRequired={true} isInvalid={formErrors.description}>
              <FormLabel>Descrição</FormLabel>
              <Textarea
                value={formData.description ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
                h="200px"
              />
              <FormErrorMessage>{formErrors.description}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Card>

        <Card>
          <Images />
        </Card>

        <HStack justify="flex-end" spacing={4} mt={6}>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </BenefitsDetailsContext.Provider>
  );
};
