import React, { useContext } from "react";
import { VStack, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { TextCore } from "./Text";
import { BuilderContext } from "..";

const variables = {
  PRODUCT: [
    { value: "ID", label: "ID" },
    { value: "NID", label: "NID" },
    { value: "REF", label: "Referência" },
    { value: "NOME", label: "Nome" },
    { value: "DESCRICAO", label: "Descrição" },
    { value: "ATRIBUTOS", label: "Atributos" },
    { value: "PRECO_CUSTO", label: "Preço de custo" },
    { value: "PRECO_ATACADO", label: "Preço de atacado" },
    { value: "PRECO_VAREJO", label: "Preço de varejo" },
    { value: "DATA", label: "Data de impressão" },
    { value: "DATA_HORA", label: "Data/hora de impressão" },
  ],
  PACK: [
    { value: "ID", label: "ID" },
    { value: "NID", label: "NID" },
    { value: "CARREGADO", label: "Peças carregadas" },
    { value: "REPRESENTANTE", label: "Representante" },
    { value: "REVENDEDOR", label: "Vendedor" },
    { value: "PRACA", label: "Praça da remessa" },
    { value: "DISTRIBUICAO", label: "Período de distribuição" },
    { value: "COBRANCA", label: "Período de cobrança" },
    { value: "DATA", label: "Data de impressão" },
    { value: "DATA_HORA", label: "Data/hora de impressão" },
  ],
};

export const Variable = ({ data, onChange }) => {
  const { formData } = useContext(BuilderContext);
  return (
    <VStack>
      <FormControl>
        <FormLabel fontSize="xs" mb="2px">
          Valor
        </FormLabel>
        <Select value={data.children} onChange={({ target }) => onChange({ ...data, children: target.value })}>
          {variables[formData.type].map((item) => (
            <option key={item.value} value={`[${item.value}]`}>
              {item.label}
            </option>
          ))}
        </Select>
      </FormControl>
      <TextCore data={data} onChange={onChange} />
    </VStack>
  );
};
