import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  StackDivider,
  Switch,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MdSave, MdChevronLeft, MdAdd, MdDelete, MdEdit, MdArrowBack } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { api, currency } from "lib";
import { useApiGet } from "hooks";
import { Card, InputCurrency } from "components";

export const CommissionsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/commissions/${_id}` }), [_id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
      });
      await schema.validate(formData);
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/commissions/${_id}`, data) : await api.post("/commissions", data);
      navigate(`/commissions/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <HStack>
          <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/commissions">
                Comissões
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        <HStack justify="space-between">
          <Text fontSize="xs">Publico?</Text>
          <Switch isChecked={formData.public ?? false} onChange={() => setFormData((state) => ({ ...state, public: !state.public }))} />
        </HStack>
      </HStack>

      <form onSubmit={handleSubmit}>
        <Card>
          <SimpleGrid spacing={4}>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel>Nome</FormLabel>
              <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Card>

        <Ranges data={formData ?? {}} onChange={(data) => setFormData(data)} />

        <HStack justify="flex-end" spacing={4}>
          <Button as={RouterLink} to="/commissions" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </>
  );
};

const Ranges = ({ data, onChange }) => {
  const [formData, setFormData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [loadingDeleteData] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const handleSubmit = async () => {
    try {
      const schema = yup.object().shape({
        amountStart: yup.number().required(messages.error.required),
        amountEnd: yup.number().required(messages.error.required),
        percentageStart: yup.number().required(messages.error.required),
        percentageEnd: yup.number().required(messages.error.required),
      });
      await schema.validate(formData);
      handleUpdateOrCreateRange();
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleUpdateOrCreateRange = () => {
    const ranges = data.ranges ?? [];
    if (formData.index === undefined) ranges.push({ ...formData, index: undefined });
    else ranges[formData.index] = { ...formData, index: undefined };
    onChange({ ...data, ranges });
    setFormData(null);
  };

  const handleDeleteRange = () => {
    const ranges = data.ranges ?? [];
    ranges.splice(deleteData.index, 1);
    onChange({ ...data, ranges });
    setDeleteData(null);
  };

  return (
    <>
      <Card>
        <HStack justify="space-between">
          <Box>
            <Text fontSize="lg">Intervalos</Text>
            <Text fontSize="xs">Informe os intervalos de valores e suas respectivas comissões.</Text>
          </Box>
          <IconButton icon={<Icon as={MdAdd} />} colorScheme="main" onClick={() => setFormData({})} />
        </HStack>

        <VStack align="stretch" spacing={4} divider={<StackDivider />}>
          {(data.ranges ?? []).map((item, index) => (
            <HStack key={index} justify="space-between">
              <Box>
                <Text>
                  {(item.percentageStart * 100)?.toLocaleString()}% a {(item.percentageEnd * 100)?.toLocaleString()}% de comissão
                </Text>
                <Text fontSize="xs">
                  Valor de {currency.format(item.amountStart)} até {currency.format(item.amountEnd)}
                </Text>
              </Box>
              <HStack spacing={4}>
                <IconButton
                  icon={<Icon as={MdDelete} />}
                  variant="ghost"
                  colorScheme="red"
                  onClick={() => setDeleteData({ ...item, index })}
                />
                <IconButton icon={<Icon as={MdEdit} />} variant="ghost" onClick={() => setFormData({ ...item, index })} />
              </HStack>
            </HStack>
          ))}
        </VStack>
      </Card>

      <Modal isOpen={formData} onClose={() => setFormData(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{formData?.index !== undefined ? "Editar intervalo" : "Novo intervalo"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
              <FormControl isRequired={true} isInvalid={formErrors.amountStart}>
                <FormLabel>Valor de</FormLabel>
                <InputGroup>
                  <InputLeftAddon>
                    <Text fontSize="xs">R$</Text>
                  </InputLeftAddon>
                  <Input
                    as={InputCurrency}
                    precision={2}
                    value={formData?.amountStart ?? ""}
                    onChange={(floatValue) => setFormData((state) => ({ ...state, amountStart: floatValue }))}
                  />
                </InputGroup>
                <FormErrorMessage>{formErrors.amountStart}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired={true} isInvalid={formErrors.amountEnd}>
                <FormLabel>Valor até</FormLabel>
                <InputGroup>
                  <InputLeftAddon>
                    <Text fontSize="xs">R$</Text>
                  </InputLeftAddon>
                  <Input
                    as={InputCurrency}
                    precision={2}
                    value={formData?.amountEnd ?? ""}
                    onChange={(floatValue) => setFormData((state) => ({ ...state, amountEnd: floatValue }))}
                  />
                </InputGroup>
                <FormErrorMessage>{formErrors.amountEnd}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
            <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
              <FormControl isRequired={true} isInvalid={formErrors.percentageStart}>
                <FormLabel>Comissão de</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={formData?.percentageStart * 100 ?? 0}
                    onChange={(floatValue) => setFormData((state) => ({ ...state, percentageStart: floatValue / 100 }))}
                  />
                  <InputRightAddon>
                    <Text fontSize="xs">%</Text>
                  </InputRightAddon>
                </InputGroup>
                <FormErrorMessage>{formErrors.percentageStart}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired={true} isInvalid={formErrors.percentageEnd}>
                <FormLabel>Comissão até</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={formData?.percentageEnd * 100 ?? 0}
                    onChange={(floatValue) => setFormData((state) => ({ ...state, percentageEnd: floatValue / 100 }))}
                  />
                  <InputRightAddon>
                    <Text fontSize="xs">%</Text>
                  </InputRightAddon>
                </InputGroup>
                <FormErrorMessage>{formErrors.percentageEnd}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button type="button" colorScheme="blue" mr={3} onClick={() => setFormData(null)}>
              Cancelar
            </Button>
            <Button type="button" onClick={handleSubmit}>
              {formData?.index !== undefined ? "Salvar" : "Adicionar"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal closeOnOverlayClick={!loadingDeleteData} isOpen={deleteData} onClose={() => setDeleteData(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atenção</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{messages.warning.deleteData}</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} isDisabled={loadingDeleteData} onClick={() => setDeleteData(null)}>
              Cancelar
            </Button>
            <Button variant="ghost" onClick={handleDeleteRange} isLoading={loadingDeleteData}>
              Sim
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
