import React, { useContext, useEffect, useMemo, useState } from "react";
import { Badge, Box, Center, Flex, HStack, Icon, IconButton, Input, Spinner, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import _ from "lodash";
import Fuse from "fuse.js";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight, MdSearch, MdBusiness, MdClose, MdRefresh, MdArrowBack } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { translator } from "lib";
import { Clipboard, Card } from "components";
import { PrivateContext } from "pages/Private/Private";
import { useApiGet } from "hooks";

export const MembershipsList = () => {
  const navigate = useNavigate();
  const { currentMerchant, setCurrentMerchant } = useContext(PrivateContext);
  const [memberships, setMemberships] = useState([]);
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/current/memberships` }), []));
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const backgroundColor = useColorModeValue("gray.100", "gray.900");

  useEffect(() => {
    setSearchText("");
  }, [isOpen]);

  useEffect(() => {
    if (searchText) {
      const fuse = new Fuse(data ?? [], { includeScore: true, keys: ["merchant._id", "merchant.name"] });
      const results = fuse.search(searchText, { limit: 20 });
      setMemberships(results.map((o) => o.item));
    } else setMemberships(data ?? []);
  }, [data, searchText]);

  const handleSelect = async (item) => {
    localStorage.setItem("@current-merchant-id", item.merchant._id);
    setCurrentMerchant(item.merchant);
    navigate("/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const [{ value }] = e.target;
    setSearchText(value);
  };

  return (
    <Center as={Flex} flexDirection="column" width="100vw" height="100vh" p={6} bg={backgroundColor}>
      <Flex flexDirection="column" width={{ base: "90%", md: "500px" }} overflow="hidden">
        <form onSubmit={handleSubmit}>
          <Card as={HStack}>
            {isOpen ? (
              <>
                <Input variant="filled" placeholder="Pesquisar..." autoFocus={true} />
                <IconButton size="sm" icon={<Icon as={MdClose} />} onClick={() => setIsOpen(false)} />
              </>
            ) : (
              <>
                <IconButton size="sm" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
                <Box flex={1}>
                  <Text fontSize="sm" fontWeight="bold">
                    Minhas organizações
                  </Text>
                  <Text fontSize="xs">Selecione uma organização para continuar.</Text>
                </Box>
                <IconButton size="sm" icon={<Icon as={MdRefresh} />} isLoading={loadingData} onClick={refreshData} />
                <IconButton size="sm" icon={<Icon as={MdSearch} />} onClick={() => setIsOpen(true)} />
              </>
            )}
          </Card>
        </form>

        {loadingData ? (
          <Card as={Center}>
            <Spinner />
          </Card>
        ) : (
          _.size(memberships) === 0 && (
            <Card as={VStack} flex={1} align="center" spacing={0} mb={0}>
              <Icon as={MdBusiness} boxSize={20} />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma organização
              </Text>
              <Text fontSize="xs" textAlign="center">
                Não fram encontradas organizações correspondentes à sua busca.
              </Text>
            </Card>
          )
        )}

        <VStack p={0} mb={0} flex={1} overflowY="auto" align="stretch">
          {_.map(memberships, (membership) => {
            const isActive = currentMerchant?._id === membership.merchant._id;
            return (
              <Card as={HStack} key={membership._id} mb={0} p={6} spacing={4} backgroundColor={isActive && "main.500"}>
                <Clipboard colorScheme={isActive ? "main" : "gray"} value={membership.merchant._id} icon={<Icon as={CgHashtag} />} />
                <Box flex={1}>
                  <Text fontWeight="bold" fontSize="sm" mb="2px" color={isActive && "white"}>
                    {membership.merchant.name}
                  </Text>
                  <Text fontSize="xs" color={isActive && "white"}>
                    {_.map(membership.roles, translator).join(", ")}
                  </Text>
                </Box>
                {membership.merchant.subsidiaries?.length > 0 && <Badge colorScheme="main">Matriz</Badge>}
                {!!membership.merchant.parent && <Badge colorScheme="green">Subsidiária</Badge>}
                <IconButton
                  size="sm"
                  colorScheme={isActive ? "main" : "gray"}
                  icon={<Icon as={MdKeyboardArrowRight} />}
                  onClick={() => handleSelect(membership)}
                  isDisabled={!membership.active}
                />
              </Card>
            );
          })}
        </VStack>
      </Flex>
    </Center>
  );
};
