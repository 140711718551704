import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  Spinner,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { MdSave, MdChevronLeft, MdArrowBack } from "react-icons/md";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { PrivateContext } from "pages/Private/Private";
import { Children } from "./children";
import { Card } from "components";

export const ParentChildrenDetails = ({ title, path }) => {
  const { _id } = useParams();
  const { currentMerchant } = useContext(PrivateContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/${path}/${_id}` }), [path, _id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
      });
      await schema.validate(formData);
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/${path}/${_id}`, data) : await api.post(`/${path}`, data);
      navigate(`/${path}/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <>
      <HStack p={2} mb={2}>
        <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={`/${path}`}>
              {title}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={RouterLink} to={`/${path}/details`}>
              {loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      <form onSubmit={handleSubmit}>
        <Card>
          <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel>Nome</FormLabel>
              <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Slug</FormLabel>
              <Input value={formData.slug ?? ""} isDisabled={true} />
            </FormControl>
          </SimpleGrid>
        </Card>

        <HStack justify="flex-end" spacing={4} mb={6}>
          <Button as={RouterLink} to={`/${path}`} leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Tooltip label="Ação bloqueada para subsidiárias" isDisabled={!currentMerchant.parent} shouldWrapChildren>
            <Button
              type="submit"
              leftIcon={<Icon as={MdSave} />}
              colorScheme="main"
              isLoading={loadingSaveData || loadingData}
              isDisabled={!!currentMerchant.parent}
            >
              Salvar
            </Button>
          </Tooltip>
        </HStack>
      </form>

      {_id && <Children parentData={formData} {...{ path }} />}
    </>
  );
};
