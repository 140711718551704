import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  SimpleGrid,
  Spinner,
  Switch,
  Text,
  Textarea,
  useToast,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Flex,
} from "@chakra-ui/react";
import { MdSave, MdArrowBack, MdClose } from "react-icons/md";
import { BsQuestion } from "react-icons/bs";
import * as yup from "yup";
import moment from "moment";
import _ from "lodash";
import { messages, roles } from "consts";
import { api, translator } from "lib";
import { InputCurrency, AsyncSelect, Card } from "components";
import { useApiGet } from "hooks";

let loadUsersTimeout = null;

export const RecurrencesDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ type: "REVENUE" });
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/recurrences/${_id}` }), [_id]));
  const [paymentForms] = useApiGet(useMemo(() => ({ path: "/payment-forms", params: { sort: { name: 1 } } }), []));
  const [wallets] = useApiGet(useMemo(() => ({ path: "/wallets", params: { sort: { name: 1 } } }), []));
  const [chartOfAccounts, loadingChartOfAccounts] = useApiGet(
    useMemo(() => ({ path: "/chart-of-accounts", params: { query: { type: formData.type }, sort: { name: 1 } } }), [formData.type])
  );
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const formData = data ?? { type: "REVENUE", startDate: new Date(), monthDay: 31, dueDaysFromMonthDay: 10 };
    if (moment(formData.startDate).isValid()) formData.startDate = new Date(formData.startDate);
    setFormData(formData);
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        type: yup.string().required(messages.error.required),
        paymentForm: yup.string().required(messages.error.required),
        wallet: yup.string().required(messages.error.required),
        chartOfAccount: yup.string().required(messages.error.required),
        monthDay: yup
          .string()
          .min(1, `${messages.error.greaterOrEqual} 1.`)
          .max(31, `${messages.error.lessOrEqual} 31.`)
          .required(messages.error.required),
        amount: yup.number().required(messages.error.required),
      });
      const data = {
        ...formData,
        user: formData.user?._id,
        paymentForm: formData.paymentForm?._id,
        wallet: formData.wallet?._id,
        chartOfAccount: formData.chartOfAccount?._id,
      };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.message });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/recurrences/${_id}`, data) : await api.post("/recurrences", data);
      navigate(`/recurrences/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleLoadUsers = (search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const data = await api.get("/users", { params: { search } });
      const response = _.map(data?.data, (item) => ({ ...item, isDisabled: !item.qualified }));
      cb(response);
    }, 1000);
  };

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <HStack>
          <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/recurrences">
                Recorrências
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.title ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        <HStack>
          <Text fontSize="xs">Ativo?</Text>
          <Switch isChecked={formData.active} onChange={() => setFormData((state) => ({ ...state, active: !state.active }))} />
        </HStack>
      </HStack>

      <form onSubmit={handleSubmit}>
        <Card>
          <SimpleGrid spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel>Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
            <FormControl isInvalid={formErrors.user}>
              <FormLabel>Usuário</FormLabel>
              <HStack>
                <AsyncSelect
                  value={formData.user ?? {}}
                  loadOptions={handleLoadUsers}
                  placeholder="Selecione o usuário"
                  onChange={(user) => setFormData((state) => ({ ...state, user }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                />
                {formData.user && (
                  <IconButton
                    variant="ghost"
                    icon={<Icon as={MdClose} />}
                    onClick={() => setFormData((state) => ({ ...state, user: undefined }))}
                  />
                )}
              </HStack>
              <FormErrorMessage>{formErrors.user}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={false} isInvalid={formErrors.role}>
              <FormLabel>Nível de usuário</FormLabel>
              <Select value={formData.role ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, role: target.value }))}>
                <option value="">--Selecione</option>
                {roles.map((role) => (
                  <option key={role} value={role}>
                    {translator(role)}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formErrors.role}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.type}>
              <FormLabel>Tipo</FormLabel>
              <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
                <option value="REVENUE">Receita</option>
                <option value="EXPENSE">Despesa</option>
              </Select>
              <FormErrorMessage>{formErrors.type}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.paymentForm}>
              <FormLabel>Forma de Pagamento</FormLabel>
              <Select
                value={formData.paymentForm?._id ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, paymentForm: { _id: target.value } }))}
              >
                <option value="">--Selecione</option>
                {(paymentForms?.data ?? []).map((o) => (
                  <option key={o._id} value={o._id}>
                    {o.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formErrors.paymentForm}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.wallet}>
              <FormLabel>Carteira</FormLabel>
              <Select
                value={formData.wallet?._id ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, wallet: { _id: target.value } }))}
              >
                <option value="">--Selecione</option>
                {(wallets?.data ?? []).map((o) => (
                  <option key={o._id} value={o._id}>
                    {o.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formErrors.wallet}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.chartOfAccount}>
              <FormLabel>Plano de Contas</FormLabel>
              <HStack>
                <Select
                  value={formData.chartOfAccount?._id ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, chartOfAccount: { _id: target.value } }))}
                  isDisabled={loadingChartOfAccounts}
                >
                  <option value="">--Selecione</option>
                  {(chartOfAccounts?.data ?? []).map((o) => (
                    <option key={o._id} value={o._id}>
                      {o.name}
                    </option>
                  ))}
                </Select>
                {loadingChartOfAccounts && <Spinner size="sm" />}
              </HStack>
              <FormErrorMessage>{formErrors.chartOfAccount}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.monthDay}>
              <Flex>
                <FormLabel flex={1}>Dia do mês</FormLabel>
                <Popover placement="bottom-end">
                  <PopoverTrigger>
                    <IconButton size="xs" icon={<Icon as={BsQuestion} />} />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Repetição</PopoverHeader>
                    <PopoverBody fontSize="xs">
                      Escolha o dia do mês em que esta recorrência deverá ser executada. Os dias 29, 30 e 31 serão considerados como último
                      dia do mês nos meses em que estes dias não existirem.
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>
              <Input
                as={InputCurrency}
                precision="0"
                value={formData.monthDay ?? ""}
                onChange={(floatValue) => setFormData((state) => ({ ...state, monthDay: floatValue }))}
              />
              <FormErrorMessage>{formErrors.monthDay}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.dueDaysFromMonthDay}>
              <FormLabel>Vencimento a partir da emissão</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.dueDaysFromMonthDay ?? ""}
                  onChange={(floatValue) => setFormData((state) => ({ ...state, dueDaysFromMonthDay: floatValue }))}
                />
                <InputRightElement fontSize="xs">dias</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.dueDaysFromMonthDay}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.amount}>
              <FormLabel>Valor</FormLabel>
              <InputGroup>
                <InputLeftElement fontSize="xs">R$</InputLeftElement>
                <Input
                  as={InputCurrency}
                  value={formData.amount ?? ""}
                  onChange={(floatValue) => setFormData((state) => ({ ...state, amount: floatValue }))}
                />
              </InputGroup>
              <FormErrorMessage>{formErrors.amount}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid spacing={4}>
            <FormControl isInvalid={formErrors.comments}>
              <FormLabel>Observações</FormLabel>
              <Textarea
                value={formData.comments ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
              />
              <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Card>

        <HStack justify="flex-end" spacing={4} mt={6}>
          <Button
            type="submit"
            leftIcon={<Icon as={MdSave} />}
            colorScheme="main"
            isLoading={loadingSaveData || loadingData || loadingChartOfAccounts}
          >
            Salvar
          </Button>
        </HStack>
      </form>
    </>
  );
};
