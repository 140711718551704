import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  Spinner,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { messages } from "consts";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { MdSave, MdChevronLeft, MdArrowBack } from "react-icons/md";
import { AsyncSelect, Card } from "components";
import api from "lib/api";

let loadOptionsTimeout = {};

export const PaymentFormsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/payment-forms/${_id}` }), [_id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
      });
      const data = { ...formData, bank: formData?.bank?._id };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/payment-forms/${_id}`, data) : await api.post("/payment-forms", data);
      navigate(`/payment-forms/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleLoadOptions = (path, search, cb) => {
    setTimeout(loadOptionsTimeout[path]);
    loadOptionsTimeout[path] = setTimeout(async () => {
      const response = await api.get(path, { params: { search } });
      cb(response?.data ?? []);
    }, 1000);
  };

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <HStack>
          <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/payment-forms">
                Formas de Pagamento
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        <HStack justify="space-between">
          <Text fontSize="xs">Publico?</Text>
          <Switch isChecked={formData.public ?? false} onChange={() => setFormData((state) => ({ ...state, public: !state.public }))} />
        </HStack>
      </HStack>

      <form onSubmit={handleSubmit}>
        <Card>
          <SimpleGrid spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel>Nome</FormLabel>
              <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={[1, 2]} spacing={4}>
            <FormControl isInvalid={formErrors.wallet}>
              <FormLabel>Carteira</FormLabel>
              <AsyncSelect
                value={formData.wallet ?? {}}
                loadOptions={(search, cb) => handleLoadOptions("/wallets", search, cb)}
                placeholder="Selecione a carteira"
                onChange={(wallet) => setFormData((state) => ({ ...state, wallet }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name }) => name}
              />
              <FormErrorMessage>{formErrors.wallet}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formErrors.chartOfAccount}>
              <FormLabel>Plano de Contas</FormLabel>
              <AsyncSelect
                value={formData.chartOfAccount ?? {}}
                loadOptions={(search, cb) => handleLoadOptions("/chart-of-accounts", search, cb)}
                placeholder="Selecione o plano de contas"
                onChange={(chartOfAccount) => setFormData((state) => ({ ...state, chartOfAccount }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name }) => name}
              />
              <FormErrorMessage>{formErrors.chartOfAccount}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Card>

        <HStack justify="flex-end" spacing={4} mb={2}>
          <Button as={RouterLink} to="/payment-forms" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </>
  );
};
