import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  SimpleGrid,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { MdClose, MdLink } from "react-icons/md";
import { IoCopyOutline } from "react-icons/io5";
import _ from "lodash";
import { ProductsCore } from "@consigsys/core";
import { InputCurrency, AsyncSelect, Card } from "components";
import { percent, api } from "lib";
import { Tags } from "containers";
import { PrivateContext } from "pages/Private/Private";
import { CopyProduct } from "../CopyProduct";
import { ProductContext } from ".";
import { FaTags } from "react-icons/fa";

let loadUsersTimeout = null;
let loadNcmsTimeout = null;

export const ProductGeneral = () => {
  const { currentMerchant } = useContext(PrivateContext);
  const { data, errors, handleDataChange } = useContext(ProductContext);
  const [showCopyProductModal, setShowCopyProductModal] = useState(false);
  const tagsSelectorRef = useRef();

  useEffect(() => {
    const wholesaleProfit = ProductsCore.getProfit({ costPrice: data.costPrice, price: data.wholesalePrice });
    const retailProfit = ProductsCore.getProfit({ costPrice: data.costPrice, price: data.retailPrice });
    handleDataChange({ wholesaleProfit, retailProfit });
  }, [data.wholesalePrice, data.retailPrice, data.costPrice, handleDataChange]);

  const handleLoadUsers = (search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const data = await api.get("/users", { params: { search, query: { role: "supplier" } } });
      const response = _.map(data?.data, (item) => ({ ...item, isDisabled: !item.qualified }));
      cb(response);
    }, 1000);
  };

  const handleLoadNcms = (search, cb) => {
    clearTimeout(loadNcmsTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const data = await api.get("/ncm", { params: { search } });
      const response = data?.data ?? [];
      cb(response);
    }, 1000);
  };

  const handleOpenTags = useCallback(() => {
    const documents = [{ document: data, quantity: 1 }];
    tagsSelectorRef.current.open("PRODUCT", documents, true);
  }, [data]);

  return (
    <>
      <Card>
        <SimpleGrid spacing={4} mb={4}>
          <FormControl isRequired={true} isInvalid={errors.name}>
            <FormLabel>Nome</FormLabel>
            <InputGroup>
              <Input value={data.name ?? ""} onChange={({ target }) => handleDataChange({ name: target.value })} />
              <InputRightAddon p={0}>
                <Tooltip label={currentMerchant.parent ? "Ação bloqueada para subsidiárias" : "Duplicar atributos"} shouldWrapChildren>
                  <IconButton
                    variant="ghost"
                    icon={<Icon as={IoCopyOutline} />}
                    onClick={() => setShowCopyProductModal(true)}
                    isDisabled={!!currentMerchant.parent}
                  />
                </Tooltip>
                {data._id && <IconButton variant="ghost" icon={<Icon as={FaTags} />} onClick={handleOpenTags} />}
              </InputRightAddon>
            </InputGroup>
            <FormErrorMessage>{errors.name}</FormErrorMessage>
            <HStack align="center" spacing={1} pt={1}>
              <Icon as={MdLink} />
              <Text fontSize="xs">{data.slug}</Text>
            </HStack>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 5]} spacing={4} mb={4}>
          <FormControl isInvalid={errors.sku}>
            <FormLabel>REF</FormLabel>
            <InputGroup>
              {data.nid && (
                <InputLeftAddon>
                  <Text fontSize="xs" fontWeight="bold">
                    NID {data.nid}
                  </Text>
                </InputLeftAddon>
              )}
              <Input value={data.sku ?? ""} onChange={({ target }) => handleDataChange({ sku: target.value })} />
            </InputGroup>
            <FormErrorMessage>{errors.sku}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.sku}>
            <FormLabel>REF Fabricante</FormLabel>
            <Input value={data.supplierSku ?? ""} onChange={({ target }) => handleDataChange({ supplierSku: target.value })} />
            <FormErrorMessage>{errors.supplierSku}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.sku}>
            <FormLabel>REF Interna</FormLabel>
            <Input value={data.internalSku ?? ""} onChange={({ target }) => handleDataChange({ internalSku: target.value })} />
            <FormErrorMessage>{errors.internalSku}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.basket}>
            <FormLabel>Cesta</FormLabel>
            <Input value={data.basket ?? ""} onChange={({ target }) => handleDataChange({ basket: target.value })} />
            <FormErrorMessage>{errors.basket}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.package}>
            <FormLabel>Pacote</FormLabel>
            <Input value={data.package ?? ""} onChange={({ target }) => handleDataChange({ package: target.value })} />
            <FormErrorMessage>{errors.package}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
          <FormControl isRequired={true} isInvalid={errors.costPrice}>
            <FormLabel>Preço de custo</FormLabel>
            <InputGroup>
              <InputLeftElement>
                <Text fontSize="xs" fontWeight="bold">
                  R$
                </Text>
              </InputLeftElement>
              <Input as={InputCurrency} value={data.costPrice ?? ""} onChange={(costPrice) => handleDataChange({ costPrice })} />
            </InputGroup>
            <FormErrorMessage>{errors.costPrice}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={errors.wholesalePrice}>
            <FormLabel>Preço de atacado</FormLabel>
            <InputGroup>
              <InputLeftElement>
                <Text fontSize="xs" fontWeight="bold">
                  R$
                </Text>
              </InputLeftElement>
              <Input
                as={InputCurrency}
                value={data.wholesalePrice ?? ""}
                onChange={(wholesalePrice) => handleDataChange({ wholesalePrice })}
              />
              <InputRightAddon fontSize="xs">{percent.format(data.wholesaleProfit ?? 0)}</InputRightAddon>
            </InputGroup>
            <FormErrorMessage>{errors.wholesalePrice}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={errors.retailPrice}>
            <FormLabel>Preço de varejo</FormLabel>
            <InputGroup>
              <InputLeftElement>
                <Text fontSize="xs" fontWeight="bold">
                  R$
                </Text>
              </InputLeftElement>
              <Input as={InputCurrency} value={data.retailPrice ?? ""} onChange={(retailPrice) => handleDataChange({ retailPrice })} />
              <InputRightAddon fontSize="xs">{percent.format(data.retailProfit ?? 0)}</InputRightAddon>
            </InputGroup>
            <FormErrorMessage>{errors.retailPrice}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
          <FormControl isInvalid={errors.supplier}>
            <FormLabel>Fornecedor</FormLabel>
            <HStack>
              <AsyncSelect
                value={data.supplier ?? {}}
                loadOptions={handleLoadUsers}
                placeholder="Selecione o fornecedor"
                onChange={(supplier) => handleDataChange({ supplier })}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name }) => name}
              />
              {data.supplier && <IconButton icon={<Icon as={MdClose} />} onClick={() => handleDataChange({ supplier: undefined })} />}
            </HStack>
            <FormErrorMessage>{errors.supplier}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={false} isInvalid={errors["fiscal.ncm"]}>
            <FormLabel>NCM</FormLabel>
            <HStack>
              <AsyncSelect
                value={data.fiscal?.ncm ?? {}}
                loadOptions={handleLoadNcms}
                placeholder="Selecione o NCM"
                onChange={(ncm) => handleDataChange({ fiscal: { ...data.fiscal, ncm } })}
                getOptionValue={({ code }) => code}
                formatOptionLabel={({ code, description }) => (code ? `${code} ${description}` : "")}
              />
              {data.fiscal?.ncm && (
                <IconButton icon={<Icon as={MdClose} />} onClick={() => handleDataChange({ fiscal: { ...data.fiscal, ncm: undefined } })} />
              )}
            </HStack>
            <FormErrorMessage>{errors["fiscal.ncm"]}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid spacing={4}>
          <FormControl isInvalid={errors.description}>
            <FormLabel>Descrição</FormLabel>
            <Textarea value={data.description ?? ""} onChange={({ target }) => handleDataChange({ description: target.value })} />
            <FormErrorMessage>{errors.description}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <CopyProduct isOpen={showCopyProductModal} onClose={() => setShowCopyProductModal(false)} />
      </Card>

      <Tags.Selector ref={tagsSelectorRef} />
    </>
  );
};
