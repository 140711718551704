import React, { forwardRef, useMemo } from "react";
import moment from "moment";
import { Alert, AlertDescription, Box, HStack, Icon, IconButton, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { Card, StatusBadge } from "components";
import { useApiGet } from "hooks";
import { useParams } from "react-router-dom";
import { MdOutlineCloudOff, MdRefresh } from "react-icons/md";

const Syncs = () => {
  const { _id } = useParams();
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/users/${_id}/syncs` }), [_id]));

  return (
    <>
      <Card>
        <HStack justify="space-between">
          <Box>
            <Text fontWeight="bold">Sincronizações</Text>
            <Text fontSize="sm">{_.size(data)} linhas</Text>
          </Box>
          <IconButton size="sm" icon={<Icon as={MdRefresh} />} onClick={refreshData} isLoading={loadingData} />
        </HStack>
      </Card>

      {loadingData === false && _.size(data) === 0 && (
        <VStack spacing={0} mt={4}>
          <Icon as={MdOutlineCloudOff} boxSize={20} />
          <Text fontSize="lg" fontWeight="bold">
            Nenhuma sincronização
          </Text>
          <Text fontSize="sm">Este usuário ainda não realizou uma sincronização.</Text>
        </VStack>
      )}

      {_.map(data, (item) => (
        <Card>
          <SimpleGrid columns={[1, 4]} spacing={4}>
            <Box>
              <Text fontSize="xs" fontWeight="bold">
                Status
              </Text>
              <StatusBadge label={item.status?.toUpperCase()} />
            </Box>
            <Box>
              <Text fontSize="xs" fontWeight="bold">
                Iniciado em
              </Text>
              <Text fontSize="sm">{moment(item.startedAt).format("DD/MM/YYYY [às] HH:mm:ss")}</Text>
            </Box>
            <Box>
              <Text fontSize="xs" fontWeight="bold">
                Finalizado em
              </Text>
              <Text fontSize="sm">{moment(item.finishedAt).format("DD/MM/YYYY [às] HH:mm:ss")}</Text>
            </Box>
            <Box>
              <Text fontSize="xs" fontWeight="bold">
                Tempo de execução
              </Text>
              <Text fontSize="sm">{moment.duration(moment(item.finishedAt).diff(moment(item.startedAt))).asSeconds()}s</Text>
            </Box>
          </SimpleGrid>
          {item.err && (
            <Alert status="error" borderRadius="lg" mt={4}>
              <AlertDescription fontSize="xs">{item.err}</AlertDescription>
            </Alert>
          )}
        </Card>
      ))}
    </>
  );
};

export default forwardRef(Syncs);
