import React, { useContext, useEffect } from "react";
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftAddon, SimpleGrid } from "@chakra-ui/react";
import { InputCurrency } from "components";
import { AccountsDetailsContext } from "./index";

export const Installments = () => {
  const { formData, formErrors, setFormData, isPaid } = useContext(AccountsDetailsContext);

  useEffect(() => {
    if (isPaid) setFormData((state) => ({ ...state, installments: { ...state.installments, quantity: 1 } }));
  }, [setFormData, isPaid]);

  return (
    <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
      <FormControl isRequired={true} isInvalid={formErrors["installments.quantity"]}>
        <FormLabel>Número de parcelas</FormLabel>
        <Input
          as={InputCurrency}
          precision="0"
          value={formData.installments?.quantity ?? 0}
          onChange={(quantity) => setFormData((state) => ({ ...state, installments: { ...state.installments, quantity } }))}
          disabled={isPaid}
        />
        <FormErrorMessage>{formErrors["installments.quantity"]}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formErrors["installments.amount"]}>
        <FormLabel>Valor da parcela</FormLabel>
        <InputGroup>
          <InputLeftAddon>R$</InputLeftAddon>
          <Input as={InputCurrency} value={formData.installments?.amount ?? 0} disabled={true} />
        </InputGroup>
        <FormErrorMessage>{formErrors["installments.amount"]}</FormErrorMessage>
      </FormControl>
    </SimpleGrid>
  );
};
