import React, { useContext } from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  VStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { AccountsCore } from "@consigsys/core";
import { InputCurrency } from "components";
import { BsQuestion } from "react-icons/bs";
import { PrivateContext } from "pages/Private/Private";
import { AccountsDetailsContext } from "./index";
import { percent } from "lib";

export const Additions = () => {
  const { currentMerchant } = useContext(PrivateContext);
  const { formData, setFormData, formErrors, cardBackgroundColor } = useContext(AccountsDetailsContext);

  const handleDiscountPercentageChange = (discount) => {
    const discountAmount = AccountsCore.getAmountValueWithPercentage(discount, formData.amount?.partial);
    setFormData((state) => ({ ...state, additions: { ...state.additions, discount, discountAmount } }));
  };

  const handleDiscountAmountChange = (discountAmount) => {
    const discount = AccountsCore.getAmountPercentageWithValue(discountAmount, formData.amount?.partial);
    setFormData((state) => ({ ...state, additions: { ...state.additions, discount, discountAmount } }));
  };

  return (
    <SimpleGrid columns={[1, 2]} spacing={4}>
      <SimpleGrid columns={3} spacing={4}>
        <HStack flex={1} align="center" justify="center" spacing={4} backgroundColor={cardBackgroundColor} borderRadius="lg">
          <Box>
            <Text fontSize="sm">Expirado</Text>
            <Text fontSize="lg" fontWeight="bold">
              {formData.expiredDays} dias
            </Text>
          </Box>
          <Box>
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <IconButton size="xs" icon={<Icon as={BsQuestion} />} />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Carência de {currentMerchant.params.gracePeriod} dias.</PopoverHeader>
                <PopoverBody fontSize="xs">
                  A carência é um parâmetro global e pode ser configurado através do cadastro da organização.
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </HStack>
        <VStack align="center" justify="center" spacing={0} backgroundColor={cardBackgroundColor} borderRadius="lg">
          <Text fontSize="sm">Juros</Text>
          <Text fontSize="lg" fontWeight="bold">
            {percent.format(formData.additions?.fees)}
          </Text>
        </VStack>
        <VStack align="center" justify="center" spacing={0} backgroundColor={cardBackgroundColor} borderRadius="lg">
          <Text fontSize="sm">Multa</Text>
          <Text fontSize="lg" fontWeight="bold">
            {percent.format(formData.additions?.fine)}
          </Text>
        </VStack>
      </SimpleGrid>
      <FormControl isInvalid={formErrors["additions.discount"]}>
        <FormLabel>Desconto</FormLabel>
        <HStack>
          <InputGroup>
            <Input
              as={InputCurrency}
              value={formData.additions?.discount * 100 ?? 0}
              onChange={(floatValue) => handleDiscountPercentageChange(floatValue / 100)}
            />
            <InputRightAddon>%</InputRightAddon>
          </InputGroup>
          <InputGroup>
            <Input
              as={InputCurrency}
              value={formData.additions?.discountAmount ?? 0}
              onChange={(floatValue) => handleDiscountAmountChange(floatValue)}
            />
            <InputRightAddon>$</InputRightAddon>
          </InputGroup>
        </HStack>
        <FormErrorMessage>{formErrors["additions.discount"]}</FormErrorMessage>
      </FormControl>
    </SimpleGrid>
  );
};
