import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";

const Comments = memo(({ value, isOpen, onClose, onChange }) => {
  const [comments, setComments] = useState("");

  useEffect(() => {
    if (isOpen) setComments(value);
  }, [isOpen, value]);

  const handleSubmit = () => {
    onClose?.();
    onChange?.({ comments });
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Observações</Text>
          <ModalCloseButton />
        </ModalHeader>
        <Divider />
        <ModalBody>
          <Textarea value={comments} onChange={({ target }) => setComments(target.value)} />
        </ModalBody>
        <ModalFooter as={HStack}>
          <Button>Cancelar</Button>
          <Button colorScheme="main" onClick={handleSubmit}>
            Adicionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default Comments;
