import React, { useState, useEffect, createContext, useMemo, useContext, useRef } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import moment from "moment";
import _ from "lodash";
import * as yup from "yup";
import InputMask from "react-input-mask";
import {
  MdClose,
  MdArrowBack,
  MdRefresh,
  MdChevronLeft,
  MdSave,
  MdOutlineLock,
  MdArrowForward,
  MdVisibility,
  MdPrint,
} from "react-icons/md";
import { ShipmentsCore, UtilsCore } from "@consigsys/core";
import { BiTransferAlt } from "react-icons/bi";
import { messages } from "consts";
import { api } from "lib";
import { MerchandisesList, NewMovement } from "containers";
import { AsyncSelect, DatePicker, InputCurrency, OwnershipMenu, Counters, Card } from "components";
import { useApiGet } from "hooks";
import { PrivateContext } from "pages/Private/Private";
import { Requirements, Derivative } from "./containers";
import { FiPackage } from "react-icons/fi";
import { CgTag } from "react-icons/cg";
import Printable from "./printable";

const timeout = { loadUsers: null };

export const ShipmentsDetailsContext = createContext();

export const ShipmentsDetails = () => {
  const { _id } = useParams();
  const { refreshSynced } = useContext(PrivateContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/shipments/${_id}` }), [_id]));
  const [zones, loadingZones] = useApiGet(useMemo(() => ({ path: `/users/${formData.agent?._id}/zones` }), [formData.agent?._id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [isClosable, setIsClosable] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [load, setLoad] = useState({ packs: {}, total: {} });
  const [merchandises, setMerchandises] = useState([]);
  const [isOpenDerivativePack, setIsOpenDerivativePack] = useState(false);
  const [isOpenNewMovement, setIsOpenNewMovement] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const bodyRef = useRef();
  const toast = useToast();

  useEffect(() => {
    refreshSynced();
  }, [refreshSynced]);

  useEffect(() => {
    const { __v, ...formData } = data ?? { status: "PREPARING", ownership: "SYSTEM" };
    formData.predictedAgentPaymentDate = formData.predictedAgentPaymentDate ? new Date(formData.predictedAgentPaymentDate) : null;
    setFormData(formData);
    setIsOwner(data?.ownership !== "APP");
  }, [data]);

  useEffect(() => {
    if (formData.status === "PREPARING") setFormData((state) => ({ ...state, ownership: "SYSTEM" }));
  }, [formData.status]);

  useEffect(() => {
    const { distribution, charge } = ShipmentsCore.getDistributionAndChargeWeeks(formData.createdAt, formData.weeksToCharge);
    setFormData((state) => ({ ...state, distribution, charge }));
  }, [formData.createdAt, formData.weeksToCharge]);

  useEffect(() => {
    const load = { packs: UtilsCore.getPacksLoad(formData.packs) };
    load.total = UtilsCore.getTotalLoad(formData.load, load.packs);
    setLoad(load);
  }, [formData.load, formData.packs]);

  useEffect(() => {
    const opens = _.filter(formData.packs, (o) => !o.closedAt && ["DISSOLVED", "LOST"].indexOf(o.status) === -1);
    const isClosable = _.size(formData.merchandises) === 0 && _.size(formData.cheking) === 0 && _.size(opens) === 0;
    setIsClosable(isClosable);
  }, [formData.packs, formData.merchandises, formData.cheking]);

  const handleSaveData = async ({ load, ...data }) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/shipments/${_id}`, data) : await api.post("/shipments", data);
      navigate(`/shipments/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const schema = yup.object().shape({
        agent: yup.string().required(messages.error.required),
        zone: yup.string().required(messages.error.required),
        status: yup.string().required(messages.error.required),
        weeksToCharge: yup.number().required(messages.error.required),
        predictedAgentPaymentDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
      });
      const data = { ...formData, agent: formData.agent?._id, zone: formData.zone?._id };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleLoadUsers = (search, cb) => {
    clearTimeout(timeout.loadUsers);
    timeout.loadUsers = setTimeout(async () => {
      const data = await api.get("/users", { params: { search, query: { roles: "agent" } } });
      const response = _.map(data?.data, (item) => ({ ...item, isDisabled: !item.qualified }));
      cb(response);
    }, 1000);
  };

  return (
    <ShipmentsDetailsContext.Provider value={{ formData, refreshData }}>
      <HStack p={2} mb={2} justify="space-between">
        <HStack>
          <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/shipments">
                Remessas
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : formData?.nid ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        <HStack>
          {_id && (
            <Tooltip label="Recarregar">
              <IconButton variant="ghost" icon={<Icon as={MdRefresh} />} onClick={refreshData} isLoading={loadingData} />
            </Tooltip>
          )}
          <ReactToPrint
            content={() => bodyRef.current}
            trigger={() => <IconButton variant="ghost" icon={<Icon as={MdPrint} />} isLoading={isPrinting} />}
            onBeforeGetContent={() =>
              new Promise((resolve) => {
                setIsPrinting(true);
                setTimeout(resolve, 1000);
              })
            }
            onAfterPrint={() => setIsPrinting(false)}
            onPrintError={() => setIsPrinting(false)}
          />
          <OwnershipMenu
            value={formData.ownership}
            isLoading={loadingData || loadingSaveData}
            isDisabled={data?.ownership === "APP" || formData.status === "PREPARING"}
            onChange={(ownership) => setFormData((state) => ({ ...state, ownership }))}
          />
        </HStack>
      </HStack>

      {!_id && (
        <Alert status="info" borderRadius="lg" mb={2}>
          <AlertIcon />
          <AlertDescription>Antes de movimentar este documento, você deve salvá-lo.</AlertDescription>
        </Alert>
      )}

      <Card>
        <SimpleGrid columns={[1, 2]} spacing={4} mb={2}>
          <FormControl isRequired={true} isInvalid={formErrors.agent}>
            <FormLabel>Representante</FormLabel>
            <HStack>
              <AsyncSelect
                value={formData.agent ?? {}}
                loadOptions={handleLoadUsers}
                placeholder="Selecione o representante"
                onChange={(agent) => setFormData((state) => ({ ...state, agent }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name }) => name}
                isDisabled={formData?.status !== "PREPARING"}
              />
              {formData.agent && formData?.status === "PREPARING" && (
                <IconButton
                  variant="ghost"
                  icon={<Icon as={MdClose} />}
                  onClick={() => setFormData((state) => ({ ...state, agent: undefined, zone: undefined }))}
                />
              )}
            </HStack>
            <FormErrorMessage>{formErrors.agent}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors.zone}>
            <FormLabel>Praça</FormLabel>
            <HStack spacing={4}>
              <Select
                value={formData.zone?._id ?? {}}
                onChange={({ target }) => setFormData((state) => ({ ...state, zone: { _id: target.value } }))}
              >
                <option>--Selecione</option>
                {zones?.map((zone) => (
                  <option key={zone._id} value={zone._id}>
                    {zone.name}
                  </option>
                ))}
              </Select>
              {loadingZones && <Spinner size="sm" />}
            </HStack>
            <FormErrorMessage>{formErrors.zone}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 3]} spacing={4}>
          <FormControl isRequired={true} isInvalid={formErrors.status}>
            <FormLabel>Status</FormLabel>
            <HStack>
              <Select
                value={formData.status}
                onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}
                isDisabled={data?.closed === true || !formData._id}
              >
                {(!data?.status || data?.status === "PREPARING") && <option value="PREPARING">Preparando</option>}
                <option value="DISPATCHED">Despachado</option>
                <option value="DELIVERED">Entregue</option>
                <option value="CHECKING">Conferindo</option>
              </Select>
            </HStack>
            <FormErrorMessage>{formErrors.status}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors.predictedAgentPaymentDate}>
            <FormLabel>Previsão de acerto</FormLabel>
            <DatePicker
              selected={formData.predictedAgentPaymentDate}
              onChange={(value) =>
                setFormData((state) => ({
                  ...state,
                  predictedAgentPaymentDate: value ? moment(value).startOf("day").toDate() : null,
                }))
              }
              dateFormat="dd/MM/yyyy"
              customInput={<InputMask mask="99/99/9999" />}
            />
            <FormErrorMessage>{formErrors.predictedAgentPaymentDate}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors.weeksToCharge}>
            <FormLabel>Semanas para cobrança</FormLabel>
            <Input
              as={InputCurrency}
              precision="0"
              value={formData.weeksToCharge ?? ""}
              onChange={(weeksToCharge) => setFormData((state) => ({ ...state, weeksToCharge }))}
            />
            <FormErrorMessage>{formErrors.weeksToCharge}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
      </Card>

      <SimpleGrid columns={[1, 2]} spacing={2}>
        <Card>
          <Text fontWeight="bold" mb={2}>
            Distribuição
          </Text>
          <SimpleGrid columns={2} spacing={4}>
            <Box>
              <Text fontSize="xs">Semana/Ano: </Text>
              <Text fontSize="sm" fontWeight="bold">
                {formData.distribution?.week ?? 0}/{formData.distribution?.year ?? 0}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs">Intervalo:</Text>
              <Text fontSize="sm" fontWeight="bold">
                {moment(formData.createdAt).startOf("week").weekday(1).format("DD/MM/YYYY")} -{" "}
                {moment(formData.createdAt).endOf("week").weekday(5).format("DD/MM/YYYY")}
              </Text>
            </Box>
          </SimpleGrid>
        </Card>
        <Card>
          <Text fontWeight="bold" mb={2}>
            Cobrança
          </Text>
          <SimpleGrid columns={2} spacing={4}>
            <Box>
              <Text fontSize="xs">Semana/Ano: </Text>
              <Text fontSize="sm" fontWeight="bold">
                {formData.charge?.week ?? 0}/{formData.charge?.year ?? 0}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs">Intervalo:</Text>
              <Text fontSize="sm" fontWeight="bold">
                {moment(formData.createdAt).add(formData.weeksToCharge, "weeks").startOf("week").weekday(1).format("DD/MM/YYYY")} -{" "}
                {moment(formData.createdAt).add(formData.weeksToCharge, "weeks").endOf("week").weekday(5).format("DD/MM/YYYY")}
              </Text>
            </Box>
          </SimpleGrid>
        </Card>
      </SimpleGrid>

      <Card>
        <Counters data={load.total} />
      </Card>

      <Card>
        <HStack mb={4}>
          <Icon mx={2} as={CgTag} />
          <Box flex={1}>
            <Text fontWeight="bold">Mercadorias</Text>
            <Text fontSize="xs">{formData.merchandises?.length ?? 0} linhas</Text>
          </Box>
          <Button
            size="sm"
            coloScheme="main"
            leftIcon={<Icon as={FiPackage} />}
            onClick={() => setIsOpenDerivativePack(true)}
            isDisabled={_.size(formData.merchandises) === 0 || !isOwner}
          >
            Novo pacote perdido
          </Button>
          <IconButton
            variant="ghost"
            icon={<Icon as={MdVisibility} />}
            onClick={() => setMerchandises(formData.merchandises ?? [])}
            isDisabled={_.size(formData.merchandises) === 0}
          />
        </HStack>
        <Counters data={formData.load} />
      </Card>

      <Card>
        <HStack mb={2}>
          <Icon mx={2} as={FiPackage} />
          <Box flex={1}>
            <Text fontWeight="bold">Pacotes</Text>
          </Box>
          <IconButton
            variant="ghost"
            icon={<Icon as={MdArrowForward} />}
            onClick={() => navigate("/packs", { state: { _id: formData._id, nid: formData.nid } })}
            isDisabled={!formData._id}
          />
        </HStack>
        <Counters data={load.packs} />
      </Card>

      <Card>
        <HStack>
          <Icon mx={2} as={BiTransferAlt} />
          <Box flex={1}>
            <Text fontWeight="bold">Movimentações</Text>
            <Text fontSize="xs">{formData.movementsCount ?? 0} movimentações</Text>
          </Box>
          <Button
            size="sm"
            leftIcon={<Icon as={BiTransferAlt} />}
            onClick={() => setIsOpenNewMovement(true)}
            isDisabled={!formData._id || !isOwner}
          >
            Nova movimentação
          </Button>
          <IconButton
            variant="ghost"
            icon={<Icon as={MdArrowForward} />}
            onClick={() => navigate("/movements", { state: { refPath: "Shipment", _id: formData._id, nid: formData.nid } })}
            isDisabled={!formData._id}
          />
        </HStack>
      </Card>

      <HStack spacing={4} mb={2}>
        {formData?._id && !formData.closed && (
          <HStack>
            <Button
              colorScheme="green"
              leftIcon={<Icon as={MdOutlineLock} />}
              onClick={() => navigate(`/shipments/details/${_id}/settlements`)}
              isLoading={loadingData || loadingSaveData}
              isDisabled={!isClosable || !isOwner}
            >
              Fechar remessa
            </Button>
            <Requirements />
          </HStack>
        )}
        <HStack justify="flex-end" flex={1}>
          <Button leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
            Voltar
          </Button>
          <Button
            leftIcon={<Icon as={MdSave} />}
            colorScheme="main"
            isLoading={loadingSaveData || loadingData}
            onClick={handleSubmit}
            isDisabled={data?.closed || !isOwner}
          >
            Salvar
          </Button>
        </HStack>
      </HStack>

      <MerchandisesList data={merchandises} isOpen={merchandises.length} onClose={() => setMerchandises([])} />
      <Derivative isOpen={isOpenDerivativePack} onClose={() => setIsOpenDerivativePack(false)} />

      <NewMovement refPath="Shipment" _id={_id} isOpen={isOpenNewMovement} onClose={() => setIsOpenNewMovement(false)} />
      {isPrinting && <Printable ref={bodyRef} data={formData} load={load} />}
    </ShipmentsDetailsContext.Provider>
  );
};
