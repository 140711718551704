import React, { memo } from "react";
import { Box, HStack, Icon, IconButton, Input, Text } from "@chakra-ui/react";
import _ from "lodash";
import { MdRemove, MdAdd, MdDelete } from "react-icons/md";
import { InputCurrency } from "components";
import { currency } from "lib";

const Item = memo(({ product, amount, quantity, searchKey, handleDeleteEntry, handleUpdateEntry, isClosed }) => {
  return (
    <HStack justify="space-between">
      <HStack>
        {!isClosed && (
          <IconButton icon={<Icon as={MdDelete} />} colorScheme="red" variant="ghost" onClick={() => handleDeleteEntry(product)} />
        )}
        <Box>
          <Text fontSize="xs">
            NID {product.nid} | REF {product.sku}
          </Text>
          <Text fontWeight="bold">{product.name}</Text>
          <Text fontSize="xs">{_.join(_.map(product.attrs, "name"), ",")}</Text>
          <Text fontSize="xs">
            {currency.format(amount)} x {quantity} ({currency.format(amount * quantity)})
          </Text>
        </Box>
      </HStack>
      {isClosed ? (
        <Text fontWeight="bold">{quantity?.toLocaleString?.()}</Text>
      ) : (
        <HStack>
          <IconButton
            icon={<Icon as={MdRemove} />}
            colorScheme="main"
            onClick={() => handleUpdateEntry(product[searchKey], -1)}
            isDisabled={quantity <= 1}
          />
          <Input
            as={InputCurrency}
            precision="0"
            textAlign="center"
            value={quantity}
            onChange={(value) => handleUpdateEntry(product[searchKey], 0, value)}
          />
          <IconButton icon={<Icon as={MdAdd} />} colorScheme="main" onClick={() => handleUpdateEntry(product[searchKey], 1)} />
        </HStack>
      )}
    </HStack>
  );
});

export default Item;
