import React, { useState, useEffect, createContext, useContext, useCallback, useMemo } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Icon,
  IconButton,
  Spinner,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Card } from "components";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet, useMiddlewareState } from "hooks";
import * as yup from "yup";
import { MdSave, MdChevronLeft, MdArrowBack } from "react-icons/md";
import moment from "moment";
import { PrivateContext } from "pages/Private/Private";
import { AccountsCore } from "@consigsys/core";
import { General } from "./general";
import { Installments } from "./installments";
import { PartialPayment } from "./partialPayment";
import { Additional } from "./additional";
import { Amount } from "./amount";
import { Additions } from "./additions";

export const AccountsDetailsContext = createContext();

export const AccountsDetails = () => {
  const { currentMerchant } = useContext(PrivateContext);
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useMiddlewareState(
    useCallback((state) => AccountsCore.process(state, currentMerchant.params), [currentMerchant.params]),
    AccountsCore.factory()
  );
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/accounts/${_id}` }), [_id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const toast = useToast();
  const cardBackgroundColor = useColorModeValue("gray.200", "gray.700");

  useEffect(() => {
    const formData = data ?? AccountsCore.factory();
    if (formData.issueDate && moment(formData.issueDate).isValid()) formData.issueDate = new Date(formData.issueDate);
    if (formData.dueDate && moment(formData.dueDate).isValid()) formData.dueDate = new Date(formData.dueDate);
    if (formData.paymentDate && moment(formData.paymentDate).isValid()) formData.paymentDate = new Date(formData.paymentDate);
    setFormData(formData);
  }, [data, setFormData]);

  useEffect(() => {
    setIsPaid(formData.status === "PAID");
  }, [formData.status]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        _id: yup.string(),
        type: yup.string().required(messages.error.required),
        user: yup.string().required(messages.error.required),
        status: yup.string().required(messages.error.required),
        paymentForm: yup.string().required(messages.error.required),
        wallet: yup.string().required(messages.error.required),
        chartOfAccount: yup.string().required(messages.error.required),
        issueDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        dueDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        paymentDate: yup.mixed().when("status", {
          is: "PAID",
          then: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        }),
        expiredDays: yup.number().required(messages.error.required),
        additions: yup.object().shape({
          fees: yup.number().required(messages.error.required),
          fine: yup.number().required(messages.error.required),
          discount: yup.number().required(messages.error.required),
        }),
        amount: yup.object().shape({
          partial: yup.number().min(0.01, `${messages.error.greaterOrEqual} 0,01.`).required(messages.error.required),
          receivable: yup.number().required(messages.error.required),
          received: yup.number().when("status", {
            is: "PAID",
            then: yup
              .number()
              .min(0.01, `${messages.error.greaterOrEqual} 0,01.`)
              .max(formData.receivable, `${messages.error.lessOrEqual} ${formData.receivable}.`)
              .required(messages.error.required),
          }),
          remaining: yup.number().required(messages.error.required),
        }),
        installments: yup.object().shape({
          quantity: yup.number().when("_id", {
            is: undefined,
            then: yup.number().min(1, `${messages.error.greaterOrEqual} 1.`).required(messages.error.required),
          }),
        }),
        nextDueDate: yup.date().when(["status", "amount"], (status, amount) => {
          if (status === "PAID" && amount.remaining > 0)
            return yup.date().typeError(messages.error.invalidDate).required(messages.error.required);
        }),
      });
      const data = {
        ...formData,
        user: formData.user?._id,
        paymentForm: formData.paymentForm?._id,
        wallet: formData.wallet?._id,
        chartOfAccount: formData.chartOfAccount?._id,
        closed: formData.status === "PAID",
      };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.message });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/accounts/${_id}`, data) : await api.post("/accounts", data);
      navigate(`/accounts/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <AccountsDetailsContext.Provider
      value={{ formData, formErrors, loadingSaveData, isPaid, setFormData, cardBackgroundColor, setFormErrors, handleSubmit }}
    >
      <HStack p={2} mb={2}>
        <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/accounts">
              Contas
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?._id ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      {data?.status === "PAID" && (
        <Alert status="info" mb={2} borderRadius="lg" fontSize="sm">
          <AlertIcon />
          Este documento já foi pago, portanto não pode ser editado. Qualquer ajuste deverá ser feito através do lançamento de um novo
          registro.
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Card>
          <General />
        </Card>

        <Card>
          <Additions />
        </Card>

        <Card>
          <Amount />
        </Card>

        {!_id && (
          <Card>
            <Installments />
          </Card>
        )}

        <Card>
          <Additional />
        </Card>

        <HStack justify="flex-end" spacing={4} mb={2}>
          <Button leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
            Voltar
          </Button>
          <Button
            type="submit"
            leftIcon={<Icon as={MdSave} />}
            colorScheme="main"
            isLoading={loadingSaveData || loadingData}
            isDisabled={data?.closed}
          >
            Salvar
          </Button>
        </HStack>
      </form>

      <PartialPayment />
    </AccountsDetailsContext.Provider>
  );
};
