import React from "react";
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { MdDesktopMac, MdPhoneIphone } from "react-icons/md";
import { translator } from "lib";

export const OwnershipButton = ({ value, isLoading, isDisabled }) => {
  return (
    <Tooltip label={`Propriedade do registro | ${translator(value ?? "SYSTEM")}`} shouldWrapChildren>
      <IconButton
        variant="ghost"
        icon={<Icon as={value !== "APP" ? MdDesktopMac : MdPhoneIphone} />}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </Tooltip>
  );
};

export const OwnershipMenu = ({ value, isLoading, isDisabled, onChange }) => {
  return (
    <Box>
      <Menu>
        <MenuButton
          as={IconButton}
          variant="ghost"
          icon={<Icon as={value !== "APP" ? MdDesktopMac : MdPhoneIphone} />}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
        <MenuList>
          <MenuOptionGroup value={value} type="radio" onChange={onChange}>
            <MenuItemOption value="SYSTEM">
              <HStack justify="space-between">
                <Text fontSize="xs" fontWeight="bold">
                  SISTEMA
                </Text>
                <Icon as={MdDesktopMac} />
              </HStack>
            </MenuItemOption>
            <MenuDivider />
            <MenuItemOption value="APP">
              <HStack justify="space-between">
                <Text fontSize="xs" fontWeight="bold">
                  APLICATIVO
                </Text>
                <Icon as={MdPhoneIphone} />
              </HStack>
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};
