import React, { useState } from "react";
import {
  Box,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MdArrowForward, MdClose } from "react-icons/md";
import { TbPackage, TbTag, TbTagOff } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { api } from "lib";
import { messages } from "consts";

const types = {
  LOAD: { path: "destination", refPath: "destinationRef" },
  UNLOAD: { path: "source", refPath: "sourceRef" },
};

const options = [
  {
    icon: TbTag,
    title: "Carga de mercadorias",
    description: "Adicionar novas mercadorias no documento.",
    data: { event: "MERCHANDISES", type: "LOAD" },
    refPaths: ["Pack", "Shipment"],
  },
  {
    icon: TbTagOff,
    title: "Descarga de mercadorias",
    description: "Remover mercadorias do documento.",
    data: { event: "MERCHANDISES", type: "UNLOAD" },
    refPaths: ["Pack", "Shipment"],
  },
  {
    icon: TbPackage,
    title: "Carga de pacotes",
    description: "Adicionar novos pacotes ao documento.",
    data: { event: "PACKS", type: "LOAD" },
    refPaths: ["Shipment"],
  },
];

export const NewMovement = ({ refPath, _id, isOpen, onClose }) => {
  const navigate = useNavigate();
  const [loadignSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  const handleSaveData = async ({ event, type }) => {
    try {
      setLoadingSaveData(true);
      const obj = { event, type, [types[type].path]: _id, [types[type].refPath]: refPath };
      const saved = await api.post(`/movements`, obj);
      navigate(`/movements/details/${saved._id}`);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack}>
          <Box flex={1}>
            <Text fontSize="lg" fontWeight="bold">
              Nova movimentação
            </Text>
            <Text fontSize="sm" fontWeight="normal">
              Escolha uma das opções de movimentação abaixo para continuar.
            </Text>
          </Box>
          <IconButton icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <Divider />
        <ModalBody p={4}>
          <VStack align="stretch">
            {options
              .filter((item) => item.refPaths.indexOf(refPath) !== -1)
              .map((item) => (
                <HStack key={item.title} p={6} borderWidth="1px" borderRadius="xl">
                  <Icon as={item.icon} boxSize={6} mr={2} />
                  <Box flex={1}>
                    <Text fontSize="lg" fontWeight="bold">
                      {item.title}
                    </Text>
                    <Text fontSize="xs">{item.description}</Text>
                  </Box>
                  <IconButton
                    colorScheme="main"
                    icon={<Icon as={MdArrowForward} />}
                    onClick={() => handleSaveData(item.data)}
                    isLoading={loadignSaveData}
                  />
                </HStack>
              ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
