import React, { forwardRef, useContext, useMemo } from "react";
import { FormControl, FormErrorMessage, FormLabel, Select, SimpleGrid, Input } from "@chakra-ui/react";
import { AsyncSelect, Card, SyncSelect } from "components";
import { roles } from "consts";
import { api, translator } from "lib";
import { useApiGet } from "hooks";
import { UsersDetailsContext } from ".";
import _ from "lodash";

let loadZonesTimeout = null;

const Merchant = () => {
  const { formData, setFormData, formErrors } = useContext(UsersDetailsContext);
  const [saleCommissions] = useApiGet(useMemo(() => ({ path: "/commissions", params: { query: { type: "SALES" } } }), []));
  const [userGroups] = useApiGet(useMemo(() => ({ path: "/user-groups" }), []));

  const handleLoadZones = (search, cb) => {
    clearTimeout(loadZonesTimeout);
    loadZonesTimeout = setTimeout(async () => {
      const response = await api.get("/zones", { params: { search } });
      cb(response?.data ?? []);
    }, 1000);
  };

  return (
    <Card>
      <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
        <SimpleGrid spacing={4} mb={4}>
          <FormControl isInvalid={formErrors.ref}>
            <FormLabel>REF</FormLabel>
            <Input value={formData.ref ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, ref: target.value }))} />
            <FormErrorMessage>{formErrors.ref}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <FormControl isRequired={true} isInvalid={formErrors.active}>
          <FormLabel>Ativo?</FormLabel>
          <Select
            value={formData.active ? "yes" : "no"}
            onChange={({ target }) => setFormData((state) => ({ ...state, active: target.value === "yes" }))}
          >
            <option value="">--Selecione</option>
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </Select>
          <FormErrorMessage>{formErrors.active}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={false} isInvalid={formErrors.roles}>
          <FormLabel>Papéis</FormLabel>
          <SyncSelect
            isMulti
            placeholder="Selecione os papéis"
            value={_.map(formData.roles, (value) => ({ value, label: translator(value) }))}
            options={_.map(roles, (value) => ({ value, label: translator(value) }))}
            onChange={(roles) => setFormData((state) => ({ ...state, roles: _.map(roles, "value") }))}
          />
          <FormErrorMessage>{formErrors.roles}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
        <FormControl isRequired={_.findIndex(formData.roles, (role) => role === "dashboarder") !== -1} isInvalid={formErrors.userGroup}>
          <FormLabel>Grupo de Usuário</FormLabel>
          <Select
            value={formData.userGroup?._id ?? ""}
            onChange={({ target }) =>
              setFormData((state) => ({ ...state, userGroup: { _id: target.value?.length ? target.value : null } }))
            }
          >
            <option value="">--Selecione</option>
            {userGroups?.data?.map(({ _id, name }) => (
              <option key={_id} value={_id}>
                {name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{formErrors.userGroup}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={false} isInvalid={formErrors.saleCommission}>
          <FormLabel>Comissão por venda</FormLabel>
          <Select
            value={formData.saleCommission?._id ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, saleCommission: { _id: target.value } }))}
          >
            <option value="">--Selecione</option>
            {saleCommissions?.data?.map(({ _id, name }) => (
              <option key={_id} value={_id}>
                {name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{formErrors.saleCommission}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid spacing={4}>
        <FormControl isInvalid={formErrors.zones}>
          <FormLabel>Praças</FormLabel>
          <AsyncSelect
            isInvalid={formErrors.zones}
            isMulti
            value={formData.zones ?? []}
            loadOptions={handleLoadZones}
            placeholder="Selecione a praça"
            onChange={(zones) => setFormData((state) => ({ ...state, zones }))}
            getOptionValue={({ _id }) => _id}
            formatOptionLabel={({ name }) => name}
          />
          <FormErrorMessage>{formErrors.zones}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </Card>
  );
};

export default forwardRef(Merchant);
