import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  HStack,
  Button,
  useToast,
  Icon,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import { Card, InputCurrency } from "components";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { MdSave, MdChevronLeft, MdVisibility, MdArrowBack } from "react-icons/md";

export const CitiesDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [city, loadingCity] = useApiGet(useMemo(() => ({ path: `/cities/${_id}` }), [_id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(city?.memberships ?? {});
  }, [city]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        target: yup.number().required(messages.error.required),
      });
      await schema.validate(formData);
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      await api.put(`/cities/${_id}`, data);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <HStack>
          <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/cities">
                Cidades
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingCity ? <Spinner size="xs" /> : city?.name ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        {city?._id && (
          <Button as={RouterLink} to={`/users?city=${_id}`} size="sm" colorScheme="main" leftIcon={<Icon as={MdVisibility} />}>
            Usuários
          </Button>
        )}
      </HStack>

      <Card>
        <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
          <Box>
            <FormLabel>Cidade</FormLabel>
            <Input value={city?.name ?? ""} isDisabled={true} />
          </Box>
          <Box>
            <FormLabel>População</FormLabel>
            <Input value={city?.population.toLocaleString() ?? ""} isDisabled={true} />
          </Box>
        </SimpleGrid>

        <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
          <Box>
            <FormLabel>Estado</FormLabel>
            <Input value={city?.state.name ?? ""} isDisabled={true} />
          </Box>
          <Box>
            <FormLabel>Região</FormLabel>
            <Input value={city?.state.region.name ?? ""} isDisabled={true} />
          </Box>
        </SimpleGrid>
      </Card>

      <form onSubmit={handleSubmit}>
        <Card>
          <SimpleGrid columns={[1, 2]} spacing={4}>
            <Box>
              <FormLabel>População do estado</FormLabel>
              <Input value={city?.state.population.toLocaleString() ?? ""} isDisabled={true} />
            </Box>
            <Box>
              <FormControl isRequired={true} isInvalid={formErrors.target}>
                <FormLabel>Alvo</FormLabel>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.target ?? ""}
                  onChange={(floatValue) => setFormData((state) => ({ ...state, target: floatValue }))}
                />
                <FormErrorMessage>{formErrors.target}</FormErrorMessage>
              </FormControl>
            </Box>
          </SimpleGrid>
        </Card>

        <HStack justify="flex-end" spacing={4}>
          <Button as={RouterLink} to="/cities" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingCity}>
            Salvar
          </Button>
        </HStack>
      </form>
    </>
  );
};
