import React, { useState, useContext, useEffect } from "react";
import { Box, Button, HStack, Icon, IconButton, SimpleGrid, Text, Tooltip, VStack, Input } from "@chakra-ui/react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdSearch } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import _ from "lodash";
import { usePrevious, useCacheState } from "hooks";
import { api, translator } from "lib";
import { SyncSelect, AsyncSelect } from "components";
import { enums } from "consts";
import { ShipmentsListContext } from "./index";
import * as queryFormatter from "./queryFormatter";

let loadUsersTimeout = null;

const LabelButton = ({ children, active, onClick }) => (
  <Button size="xs" colorScheme={active ? "main" : "gray"} onClick={onClick}>
    {children}
  </Button>
);

const Filter = () => {
  const { query, setQuery, setPage, loading } = useContext(ShipmentsListContext);
  const [formData, setFormData] = useState({});
  const [visible, setVisible] = useCacheState(false, "ShipmentsListFilter:visible");
  const [options, setOptions] = useState({ statuses: [] });
  const prevFormData = usePrevious(formData);

  useEffect(() => {
    const statuses = _.map(enums.shipmentStatuses, (value) => ({ value, label: translator(value) }));
    setOptions((state) => ({ ...state, statuses }));
  }, []);

  useEffect(() => {
    if (!prevFormData) setFormData(queryFormatter.queryToFormData(query));
  }, [prevFormData, query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const query = queryFormatter.formDataToQuery(formData);
    setQuery(query);
    setPage(0);
  };

  const handleCleanQuery = () => {
    setFormData({});
    setQuery({});
    setPage(0);
  };

  const handleLoadUsers = (search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.get("/users", { params: { search, query: { roles: "agent" } } });
      cb(response?.data ?? []);
    }, 1000);
  };

  return (
    <form onSubmit={handleSubmit}>
      <HStack justify="space-between">
        <HStack>
          {Object.keys(query).length > 0 && (
            <Tooltip label="Limpar filtros">
              <IconButton colorScheme="main" variant="ghost" icon={<Icon as={FaCircle} />} onClick={handleCleanQuery} />
            </Tooltip>
          )}
          <Text fontWeight="bold">Filtros</Text>
        </HStack>
        <HStack>
          <Tooltip label="Filtrar">
            <IconButton type="submit" icon={<Icon as={MdSearch} />} isLoading={loading} />
          </Tooltip>
          <Tooltip label="Exibir/Ocultar filtros">
            <IconButton icon={<Icon as={visible ? MdKeyboardArrowUp : MdKeyboardArrowDown} />} onClick={() => setVisible(!visible)} />
          </Tooltip>
        </HStack>
      </HStack>

      {visible && (
        <Box mt={4}>
          <SimpleGrid columns={[1, 3]} spacing={4}>
            <VStack align="stretch">
              <LabelButton
                active={formData.statuses?.length > 0}
                onClick={() => setFormData((state) => ({ ...state, statuses: undefined }))}
              >
                Status
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.statuses, (value) => _.find(options.statuses, (o) => o.value === value))}
                options={options.statuses}
                onChange={(data) => setFormData((state) => ({ ...state, statuses: _.map(data, "value") }))}
              />
            </VStack>

            <VStack align="stretch">
              <LabelButton active={formData.nid} onClick={() => setFormData((state) => ({ ...state, nid: undefined }))}>
                NID
              </LabelButton>
              <Input value={formData.nid ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))} />
            </VStack>

            <VStack align="stretch">
              <LabelButton active={formData.agent} onClick={() => setFormData((state) => ({ ...state, agent: undefined }))}>
                Representante
              </LabelButton>
              <AsyncSelect
                value={formData.agent ?? {}}
                loadOptions={handleLoadUsers}
                placeholder="Selecione"
                onChange={(agent) => setFormData((state) => ({ ...state, agent }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name }) => name}
              />
            </VStack>
          </SimpleGrid>
        </Box>
      )}
    </form>
  );
};

export default Filter;
