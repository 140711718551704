import React, { useContext } from "react";
import { FormControl, FormErrorMessage, FormLabel, Input, SimpleGrid, Textarea } from "@chakra-ui/react";
import { AccountsDetailsContext } from "./index";

export const Additional = () => {
  const { formData, setFormData, formErrors } = useContext(AccountsDetailsContext);

  return (
    <>
      <SimpleGrid spacing={4} mb={4}>
        <FormControl isInvalid={formErrors.document}>
          <FormLabel>Documento</FormLabel>
          <Input
            value={formData.document ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
          />
          <FormErrorMessage>{formErrors.document}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid spacing={4}>
        <FormControl isInvalid={formErrors.comments}>
          <FormLabel>Observações</FormLabel>
          <Textarea
            value={formData.comments ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
            rows={10}
          />
          <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </>
  );
};
