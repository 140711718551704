import React, { useContext } from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";
import { UsersDetailsContext } from ".";
import { Card } from "components";

const Metadata = () => {
  const { formData } = useContext(UsersDetailsContext);
  const backgroundColor = useColorModeValue("gray.100", "gray.900");

  return (
    <Card>
      <Box p={6} borderRadius="xl" backgroundColor={backgroundColor}>
        <pre>{JSON.stringify(formData?.metadata, null, 2)}</pre>
      </Box>
    </Card>
  );
};

export default Metadata;
