import React, { useState, useEffect, useContext } from "react";
import { Icon, IconButton } from "@chakra-ui/react";
import { MdCloudDone, MdError } from "react-icons/md";
import { api } from "lib";
import * as database from "database";
import { PrivateContext } from "pages/Private/Private";

const Syncs = () => {
  const { currentMerchant, syncedTimestamp, refreshSynced } = useContext(PrivateContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let params = {};
        if (typeof syncedTimestamp === "number") {
          const syncedAt = await database.synced.syncs.where({ merchant: currentMerchant._id }).last();
          params = { query: { syncedAt: syncedAt?.createdAt } };
        }
        const response = await api.get("/sync", { params });
        for (const key in response) {
          if (typeof syncedTimestamp === "string") await database.synced[key].clear();
          await database.synced[key].bulkPut(response[key]);
        }
        await database.synced.syncs.add({ merchant: currentMerchant._id, createdAt: new Date() });
        setError(null);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentMerchant._id, syncedTimestamp]);

  return (
    <IconButton
      icon={<Icon as={error ? MdError : MdCloudDone} />}
      variant="ghost"
      colorScheme={error ? "red" : "green"}
      isLoading={loading}
      onClick={() => refreshSynced(true)}
    />
  );
};

export default Syncs;
