import React, { useState, useImperativeHandle, forwardRef, useCallback } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
} from "@chakra-ui/react";
import _ from "lodash";
import { MdPrint } from "react-icons/md";
import { InputCurrency } from "components";
import { api } from "lib";
import Printer from "../printer";

const Selector = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [isSingle, setIsSingle] = useState(false);
  const [documents, setDocuments] = useState([]);

  const open = useCallback(async (type, documents, isSingle) => {
    try {
      setIsOpen(true);
      setLoadingData(true);
      setDocuments(documents);
      setIsSingle(isSingle);
      const { data } = await api.get("/tags", { params: { query: { type } } });
      setData(data);
      setSelected({});
    } finally {
      setLoadingData(false);
    }
  }, []);

  const close = useCallback(() => {
    setDocuments([]);
    setIsOpen(false);
  }, []);

  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  return (
    <Modal isOpen={isOpen} onClose={close} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Impressão de etiquetas</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel fontSize="xs" mb="5px">
              Template
            </FormLabel>
            <HStack>
              <Select value={selected?._id ?? ""} onChange={({ target }) => setSelected(_.find(data, (o) => o._id === target.value))}>
                <option value="">--Selecione</option>
                {_.map(data, (o) => (
                  <option key={o._id} value={o._id}>
                    {o.title}
                  </option>
                ))}
              </Select>
              {loadingData && <Spinner size="sm" />}
            </HStack>
          </FormControl>
          {isSingle && (
            <FormControl mt={4}>
              <FormLabel fontSize="xs" mb="5px">
                Quantidade
              </FormLabel>
              <Input as={InputCurrency} precision="0" value={quantity ?? 1} onChange={(quantity) => setQuantity(quantity)} />
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Printer
            tag={selected}
            documents={isSingle ? [{ document: documents?.[0]?.document, quantity }] : documents}
            trigger={({ isLoading }) => (
              <Button
                colorScheme="main"
                leftIcon={<Icon as={MdPrint} />}
                isFullWidth
                isLoading={loadingData || isLoading}
                isDisabled={!selected?._id}
              >
                Imprimir
              </Button>
            )}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default Selector;
