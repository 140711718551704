import React from "react";
import _ from "lodash";
import {
  Box,
  Divider,
  HStack,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdClose, MdRefresh } from "react-icons/md";

export const MerchandisesList = ({ data, isOpen, onClose, onRefresh, isLoading }) => {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack}>
          <Box flex={1}>
            <Text>Mercadorias</Text>
            <Text fontSize="xs" fontWeight="normal">
              Mercadorias listadas neste documento.
            </Text>
          </Box>
          {onRefresh && <IconButton icon={<Icon as={MdRefresh} />} onClick={onRefresh} isLoading={isLoading} />}
          <IconButton icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <Divider />
        <ModalBody>
          <VStack align="stretch" py="10px">
            {data.map((item) => (
              <HStack key={item.product._id} justify="space-between">
                <HStack>
                  <Image
                    borderRadius="md"
                    boxSize="50px"
                    src={item.product.mainImg?.transforms?.thumbnail?.location}
                    fallbackSrc="/assets/imgs/empty.jpg"
                    borderWidth="1px"
                    borderStyle="solid"
                  />
                  <Box>
                    <Text fontSize="sm">{item.product.name}</Text>
                    <Text fontSize="xs">
                      (#{item.product.sku}) {_.join(_.map(item.product.attrs, "name"), ",")}
                    </Text>
                  </Box>
                </HStack>
                <Text fontSize="sm" fontWeight="bold">
                  {item.quantity}
                </Text>
              </HStack>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
