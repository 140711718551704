import React, { useRef, useMemo, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdDelete, MdAdd } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { IoCopyOutline } from "react-icons/io5";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { Card, Clipboard, DataTable, Dialog } from "components";
import { messages } from "consts";
import { api, translator } from "lib";
import Duplicate from "./duplicate";

export const TagsList = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useCacheState(20, "TagsList:perPage");
  const [sort, setSort] = useStickyState({ title: 1 }, "TagsList:sort");
  const [page, setPage] = useCacheState(0, "TagsList:page");
  const [search, setSearch] = useCacheState("", "TagsList:search");
  const [response, loading, refresh, error] = useApiGet(
    useMemo(() => ({ path: "/tags", params: { search, perPage, page, sort } }), [search, perPage, page, sort])
  );
  const [selected, setSelected] = useState(null);
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/tags/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/tags">
              Etiquetas
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/tags/details" icon={<Icon as={MdAdd} boxSize={4} />} />
        </Tooltip>
      </HStack>
      <Card>
        <DataTable
          tableId="TagsList:table"
          title="Etiquetas"
          data={response?.data ?? []}
          size={response?.size ?? 0}
          loading={loading}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          search={search}
          onSearch={setSearch}
          onRefresh={refresh}
          error={error}
          renderItems={[
            {
              key: "_id",
              label: "ID",
              visible: true,
              render: (value) => <Clipboard value={value} icon={<Icon as={CgHashtag} />} />,
            },
            { key: "title", label: "Título", visible: true, exporter: translator },
            { key: "type", label: "Tipo", visible: true, formatter: translator },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          ActionsComponent={({ item }) => (
            <HStack>
              <Tooltip label="Duplicar">
                <IconButton size="sm" variant="ghost" icon={<Icon as={IoCopyOutline} />} onClick={() => setSelected(item._id)} />
              </Tooltip>
              <Tooltip label="Excluir">
                <IconButton
                  icon={<Icon as={MdDelete} />}
                  size="sm"
                  variant="ghost"
                  colorScheme="red"
                  onClick={() => deleteRef.current.confirm(item, handleDelete)}
                />
              </Tooltip>
            </HStack>
          )}
          onRowDoubleClick={({ _id }) => navigate(`/tags/details/${_id}`)}
        />
      </Card>
      <Dialog.Delete ref={deleteRef} />
      <Duplicate _id={selected} onClose={() => setSelected(null)} onFinish={refresh} />
    </>
  );
};
