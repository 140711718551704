import React, { memo } from "react";
import { Box, HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import { MdDelete, MdVisibility } from "react-icons/md";
import { currency } from "lib";

const Item = memo(({ _id, pack, quantity, amount, isClosed, handleDeleteEntry }) => {
  return (
    <HStack>
      <Box flex={1}>
        <Text fontWeight="bold">NID {pack.nid}</Text>
        <Text fontSize="sm" fontWeight="semibold">
          {currency.format(amount || 0)}
        </Text>
        <Text fontSize="xs">{quantity?.toLocaleString?.() || 0} mercadorias</Text>
      </Box>
      {!isClosed && (
        <IconButton icon={<Icon as={MdDelete} />} size="sm" colorScheme="red" variant="ghost" onClick={() => handleDeleteEntry(_id)} />
      )}
      <IconButton icon={<Icon as={MdVisibility} />} size="sm" variant="ghost" onClick={() => window.open(`/packs/details/${pack._id}`)} />
    </HStack>
  );
});

export default Item;
