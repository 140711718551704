import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Logo, Clipboard } from "components";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { getAuth, getIdToken, signOut } from "firebase/auth";
import { MdBrightness2, MdBrightness7, MdMenu, MdBusiness, MdPassword } from "react-icons/md";
import { IoLogoWhatsapp, IoLogOutOutline } from "react-icons/io5";
import { PrivateContext } from "pages/Private/Private";
import Syncs from "./syncs";
import { ContainerContext } from "./index";

const Toolbar = () => {
  const navigate = useNavigate();
  const { isDesktopView, opened, handleToggle } = useContext(ContainerContext);
  const { currentUser, currentMerchant } = useContext(PrivateContext);
  const [token, setToken] = useState();
  const { colorMode, toggleColorMode } = useColorMode();
  const iconButtonColor = useColorModeValue("gray.500", "white");

  useEffect(() => {
    const auth = getAuth();
    getIdToken(auth.currentUser).then((token) => setToken(token));
  }, []);

  const handleSignOut = async () => {
    const auth = getAuth();
    await signOut(auth);
    localStorage.removeItem("@current-merchant-id");
  };

  return (
    <>
      <HStack height="50px" px="10px" justify="space-between" boxShadow="0 0 5px rgba(0,0,0,0.2)" zIndex={1}>
        <HStack>
          <Tooltip label={`${opened ? "Recolher" : "Expandir"} menu`}>
            <IconButton icon={<Icon as={MdMenu} />} variant="ghost" color={iconButtonColor} onClick={handleToggle} />
          </Tooltip>
          <Button p="5px" variant="ghost" onClick={() => navigate("/memberships")}>
            <Logo width={35} />
            {isDesktopView && (
              <Box marginLeft="5px">
                <Flex>
                  <Text fontSize="sm" fontWeight="bold">
                    Consig
                  </Text>
                  <Text fontSize="sm" fontWeight="light">
                    sys
                  </Text>
                </Flex>
                <Box textAlign="left">
                  <Text fontSize="xs" fontWeight="light">
                    {currentMerchant?.name}
                  </Text>
                </Box>
              </Box>
            )}
          </Button>
        </HStack>
        <HStack>
          {process.env.NODE_ENV === "development" && (
            <Clipboard value={token} icon={<Icon as={MdPassword} />} variant="ghost" color={iconButtonColor} />
          )}
          <Tooltip label={`Tema ${colorMode === "light" ? "escuro" : "claro"}`}>
            <IconButton
              icon={<Icon as={colorMode === "light" ? MdBrightness7 : MdBrightness2} />}
              variant="ghost"
              color={iconButtonColor}
              onClick={toggleColorMode}
            />
          </Tooltip>
          <Syncs />
          <Box>
            <Menu>
              <MenuButton as={IconButton} variant="ghost">
                <Avatar size="sm" />
              </MenuButton>
              <MenuList>
                <Box textAlign="center" paddingY="10px">
                  <Avatar size="lg" marginBottom="10px" />
                  <Text fontWeight="bold">{currentUser.name}</Text>
                  {currentUser.email && <Text fontSize="xs">{currentUser.email}</Text>}
                  {currentUser.phone && <Text fontSize="xs">{currentUser.phone}</Text>}
                </Box>
                <MenuDivider />
                <MenuItem as={RouterLink} to={`/merchants/details/${currentMerchant?._id}`}>
                  <Icon as={MdBusiness} marginRight="10px" />
                  <Text fontSize="xs">Minha organização</Text>
                </MenuItem>
                <MenuItem
                  as="a"
                  href={`https://wa.me/5512988191212?text=Boa tarde! Preciso de suporte. Organização ${currentMerchant.name}. Usuário ${currentUser.name}.`}
                  target="_blank"
                >
                  <Icon as={IoLogoWhatsapp} marginRight="10px" />
                  <Text fontSize="xs">Ajuda</Text>
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={handleSignOut}>
                  <Icon as={IoLogOutOutline} marginRight="10px" />
                  <Text fontSize="xs">Sair da minha conta</Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </HStack>
      </HStack>
    </>
  );
};

export default Toolbar;
