import React, { useRef, useMemo } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdDelete, MdAdd } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { Card, Clipboard, DataTable, Dialog } from "components";
import { messages } from "consts";
import { api } from "lib";

export const WalletsList = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useCacheState(20, "WalletsList:perPage");
  const [sort, setSort] = useStickyState({ name: 1 }, "WalletsList:sort");
  const [page, setPage] = useCacheState(0, "WalletsList:page");
  const [search, setSearch] = useCacheState("", "WalletsList:search");
  const [response, loading, refresh, error] = useApiGet(
    useMemo(() => ({ path: "/wallets", params: { search, perPage, page, sort } }), [search, perPage, page, sort])
  );
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/wallets/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/wallets">
              Carteiras
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/wallets/details" icon={<Icon as={MdAdd} boxSize={4} />} />
        </Tooltip>
      </HStack>
      <Card>
        <DataTable
          tableId="WalletsList:table"
          title="Carteiras"
          data={response?.data ?? []}
          size={response?.size ?? 0}
          loading={loading}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          search={search}
          onSearch={setSearch}
          onRefresh={refresh}
          error={error}
          renderItems={[
            { key: "_id", label: "ID", visible: true, render: (value) => <Clipboard value={value} icon={<Icon as={CgHashtag} />} /> },
            { key: "name", label: "Nome", visible: true },
            { key: "bank.name", label: "Banco", visible: true },
            { key: "agency", label: "Agência/DV", visible: true },
            { key: "account", label: "Conta/DV", visible: true },
            { key: "public", label: "Público?", visible: true, formatter: (value) => (value ? "SIM" : "NÃO") },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          ActionsComponent={({ item }) => (
            <Tooltip label="Excluir">
              <IconButton
                icon={<Icon as={MdDelete} />}
                size="sm"
                variant="ghost"
                colorScheme="red"
                onClick={() => deleteRef.current.confirm(item, handleDelete)}
              />
            </Tooltip>
          )}
          onRowDoubleClick={({ _id }) => navigate(`/wallets/details/${_id}`)}
        />
      </Card>
      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
