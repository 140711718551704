const terms = {
  CLOSED: "FECHADO",
  DEFAULT: "PADRÃO",
  DISTRIBUTED: "DISTRIBUÍDO",
  EXPENSE: "DESPESA",
  EXTERNAL: "EXTERNO",
  IGNORE: "IGNORAR",
  ENTRY: "ENTRADA",
  INTERNAL: "INTERNO",
  LOADED: "CARREGADO",
  UNLOADED: "DESCARREGADO",
  LOST: "PERDIDO",
  OPENED: "ABERTO",
  MERCHANDISE: "MERCADORIA",
  PACK: "PACOTE",
  EXIT: "SAÍDA",
  PAID: "PAGO",
  PENDING: "PENDENTE",
  RESET_STOCK: "ZERAR ESTOQUE",
  RETURNED: "DEVOLVIDO",
  REVENUE: "RECEITA",
  SOLD: "VENDIDO",
  SPECIAL: "ESPECIAL",
  TRANSFERRED: "TRANSFERIDO",
  UNDISTRIBUTED: "NÃO DISTRIBUÍDO",
  SENDED: "ENVIADO",
  RECEIVED: "RECEBIDO",
  PREPARING: "PREPARANDO",
  DISPATCHED: "DESPACHADO",
  GROUPED: "AGRUPADO",
  RESCHEDULED: "REAGENDADO",
  CANCELED: "CANCELADO",
  FAILED: "FALHA",
  DISSOLVED: "DISSOLVIDO",
  DELIVERED: "ENTREGUE",
  CURRENT: "ATUAL",
  MERCHANT: "MERCADORIA",
  PRODUCT: "PRODUTO",
  SHIPMENT: "REMESSA",
  ADMIN: "ADMINISTRADOR",
  MANAGER: "GERENTE",
  EDITOR: "EDITOR",
  RECOUNT: "RECONTAGEM",
  ADDED: "ADICIONADO",
  CHECKED: "CONFERIDO",
  CHECKING: "CONFERINDO",
  MODIFIED: "MODIFICADO",
  ORDER: "ENCOMENDA",
  PARTIAL: "PARCIAL",
  WAITING: "AGUARDANDO",
  WAITING_SETTLE: "AGUARDANDO ACERTO",
  SETTLED: "ACERTADO",
  CUSTOMER: "CLIENTE",
  SELLER: "VENDEDOR",
  DEALER: "REVENDEDOR",
  SUPPLIER: "FORNECEDOR",
  AGENT: "REPRESENTANTE",
  DASHBOARDER: "USUÁRIO DO PAINEL",
  SYNCED: "SINCRONIZADO",
  SYSTEM: "SISTEMA",
  APP: "APLICATIVO",
  LOAD: "CARGA",
  UNLOAD: "DESCARGA",
  TRANSFER: "TRANSFERÊNCIA",
  PACKS: "PACOTES",
  MERCHANDISES: "MERCADORIAS",
  SUCCESS: "SUCESSO",
};

export const translator = (word) => {
  const term = word?.toUpperCase?.();
  if (terms[term]) return terms[term];
  return term;
};
