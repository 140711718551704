export const formDataToQuery = (formData) => {
  const response = {};

  if (formData.nid) response.nid = parseInt(formData.nid);

  if (formData.agent) response.agent = formData.agent;

  if (formData.statuses?.length) response.status = { $in: formData.statuses };

  return response;
};

export const queryToFormData = (query) => {
  const response = {};

  if (query.nid) response.agent = parseInt(query.nid);

  if (query.agent) response.agent = query.agent;

  if (query.status?.$in) response.statuses = query.status.$in;

  return response;
};
