import React from "react";
import { Box, HStack, Icon, StackDivider, Text } from "@chakra-ui/react";
import { currency } from "lib";
import { FaTruckLoading } from "react-icons/fa";

const getQuantity = (value) => {
  if (Number.isInteger(value)) return value.toLocaleString();
  return 0;
};

export const Counters = ({ data }) => {
  return (
    <HStack divider={<StackDivider />}>
      <HStack flex={1} p={2}>
        <Icon as={FaTruckLoading} mx={2} />
        <Box>
          <Text fontSize="xs">CARGA ORIGINAL ({getQuantity(data?.original?.quantity)})</Text>
          <Text fontSize="sm" fontWeight="bold">
            {currency.format(data?.original?.amount || 0)}
          </Text>
        </Box>
      </HStack>
      <HStack flex={1} p={2}>
        <Icon as={FaTruckLoading} mx={2} />
        <Box>
          <Text fontSize="xs">CARGA ATUAL ({getQuantity(data?.current?.quantity)})</Text>
          <Text fontSize="sm" fontWeight="bold">
            {currency.format(data?.current?.amount || 0)}
          </Text>
        </Box>
      </HStack>
      <HStack flex={1} p={2}>
        <Icon as={FaTruckLoading} mx={2} />
        <Box>
          <Text fontSize="xs">CARGA MODIFICADA ({getQuantity(data?.modified?.quantity)})</Text>
          <Text fontSize="sm" fontWeight="bold">
            {currency.format(data?.modified?.amount || 0)}
          </Text>
        </Box>
      </HStack>
    </HStack>
  );
};
