import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import { MdSave, MdOutlineLock, MdArrowBack, MdChevronLeft, MdPrint } from "react-icons/md";
import { FaTags } from "react-icons/fa";
import { getMerchandisesQuantityAmount } from "@consigsys/core";
import moment from "moment";
import * as yup from "yup";
import { messages } from "consts";
import { api, currency } from "lib";
import { Entry, Tags } from "containers";
import { Card, Dialog } from "components";
import * as database from "database";
import Printable from "./printable";

export const InventoryMovementsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const toast = useToast();
  const tagsSelectorRef = useRef();
  const bodyRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingData(true);
        const response = await api.get(`/inventory-movements/${_id}`);
        setFormData(response);
        const entry = await database.main.entries.where({ _id }).first();
        if (entry && entry.updatedAt > new Date(response.updatedAt))
          setFormData((state) => ({
            ...state,
            quantity: entry.quantity,
            amount: entry.amount,
            merchandises: entry.merchandises,
            updatedAt: entry.updatedAt,
            mustUpdate: true,
          }));
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setLoadingData(false);
      }
    };
    _id && fetchData();
  }, [_id, toast]);

  const handleSubmit = async (_data = {}) => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        type: yup.string().required(messages.error.required),
        notListedProducts: yup.string().when("type", {
          is: "RECOUNT",
          then: yup.string().required(messages.error.required),
        }),
      });
      const data = { ...formData, ..._data };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/inventory-movements/${_id}`, data) : await api.post("/inventory-movements", data);
      navigate(`/inventory-movements/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      await database.main.entries.where({ _id: saved._id }).delete();
      setFormData((state) => ({ ...state, mustUpdate: false }));
      if (saved.closed) navigate(-1);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleChangeMerchandises = useCallback((merchandises) => {
    setFormData(({ __v, _id, merchant, createdAt, ...state }) => {
      const { quantity, amount } = getMerchandisesQuantityAmount(merchandises);
      const update = { _id, quantity, amount, merchandises, merchant, createdAt: new Date(createdAt), updatedAt: new Date() };
      database.main.entries.put(update);
      return { ...state, ...update, mustUpdate: true };
    });
  }, []);

  const handleOpenTags = useCallback(() => {
    const documents = _.map(formData.merchandises, ({ product: document, quantity }) => ({ document, quantity }));
    tagsSelectorRef.current.open("PRODUCT", documents, false);
  }, [formData.merchandises]);

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <HStack>
          <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/inventory-movements">
                Movimentações de Inventário
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : formData?.title ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        <HStack>
          <ReactToPrint
            content={() => bodyRef.current}
            trigger={() => <IconButton variant="ghost" icon={<Icon as={MdPrint} />} isLoading={isPrinting} />}
            onBeforeGetContent={() =>
              new Promise((resolve) => {
                setIsPrinting(true);
                setTimeout(resolve, 1000);
              })
            }
            onAfterPrint={() => setIsPrinting(false)}
            onPrintError={() => setIsPrinting(false)}
          />
          <IconButton variant="ghost" icon={<Icon as={FaTags} />} onClick={handleOpenTags} />
        </HStack>
      </HStack>

      {!_id && (
        <Alert status="info" borderRadius="lg" mb={2}>
          <AlertIcon />
          <AlertDescription>Antes de adicionar mercadorias neste documento, você deve salvá-lo.</AlertDescription>
        </Alert>
      )}

      {formData.mustUpdate && (
        <Alert status="warning" borderRadius="lg" mb={4}>
          <AlertIcon />
          <AlertTitle>Sincronização pendente</AlertTitle>
          <AlertDescription>Existem movimentações não sincronizadas neste documento.</AlertDescription>
        </Alert>
      )}

      <Card>
        <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
          <FormControl isRequired={true} isInvalid={formErrors.title}>
            <FormLabel>Título</FormLabel>
            <Input
              value={formData.title ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))}
              isDisabled={formData?.closed}
            />
            <FormErrorMessage>{formErrors.title}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Autor</FormLabel>
            <Input value={formData.author?.name ?? ""} isDisabled={true} />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={[1, formData.type === "RECOUNT" ? 2 : 1]} spacing={4}>
          <FormControl isRequired={true} isInvalid={formErrors.type}>
            <FormLabel>Tipo</FormLabel>
            <Select
              value={formData.type}
              onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}
              isDisabled={formData?.closed}
            >
              <option>--Selecione</option>
              <option value="ENTRY">Entrada</option>
              <option value="EXIT">Saída</option>
              <option value="RECOUNT">Recontagem</option>
            </Select>
            <FormErrorMessage>{formErrors.type}</FormErrorMessage>
          </FormControl>
          {formData.type === "RECOUNT" && (
            <FormControl isRequired={true} isInvalid={formErrors.notListedProducts}>
              <FormLabel>Produtos não listados</FormLabel>
              <Select
                value={formData.notListedProducts}
                onChange={({ target }) => setFormData((state) => ({ ...state, notListedProducts: target.value }))}
                isDisabled={formData?.closed}
              >
                <option>--Selecione</option>
                <option value="IGNORE">Ignorar</option>
                <option value="RESET_STOCK">Zerar estoque</option>
              </Select>
              <FormErrorMessage>{formErrors.notListedProducts}</FormErrorMessage>
            </FormControl>
          )}
        </SimpleGrid>
      </Card>

      {_id && (
        <>
          <Card>
            <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  Mercadorias
                </Text>
                <Text fontSize="sm">{formData.quantity?.toLocaleString?.() ?? 0}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  Valor
                </Text>
                <Text fontSize="sm">{currency.format(formData.amount ?? 0)}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  Linhas
                </Text>
                <Text fontSize="sm">{formData.merchandises?.length ?? 0}</Text>
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={[1, 3]} spacing={4}>
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  Criação
                </Text>
                <Text fontSize="sm">{moment(formData?.createdAt).format("DD/MM/YYYY HH:mm:ss")}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  Atualização
                </Text>
                <Text fontSize="sm">{moment(formData?.updatedAt).format("DD/MM/YYYY HH:mm:ss")}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  Fechamento
                </Text>
                <Text fontSize="sm">{formData?.closedAt ? moment(formData?.closedAt).format("DD/MM/YYYY HH:mm:ss") : "-"}</Text>
              </Box>
            </SimpleGrid>
          </Card>
          <Card>
            <Entry.Merchandises defaultValue={formData.merchandises} onChange={handleChangeMerchandises} isClosed={formData?.closed} />
          </Card>
        </>
      )}

      {!formData.closed && (
        <HStack mb={2}>
          {_id && (
            <Button
              leftIcon={<Icon as={MdOutlineLock} />}
              colorScheme="green"
              isLoading={loadingSaveData || loadingData}
              onClick={() => setShowConfirmDialog(true)}
            >
              Fechar movimentação
            </Button>
          )}
          <Box flex={1} />
          <HStack>
            <Button leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              Voltar
            </Button>
            <Button
              leftIcon={<Icon as={MdSave} />}
              colorScheme="main"
              isLoading={loadingSaveData || loadingData}
              onClick={() => handleSubmit()}
            >
              Salvar
            </Button>
          </HStack>
        </HStack>
      )}

      <Dialog.Confirm
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={() => handleSubmit({ closed: true })}
      />

      <Tags.Selector ref={tagsSelectorRef} />

      {isPrinting && <Printable ref={bodyRef} data={formData} />}
    </>
  );
};
