import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import { MdArrowBack, MdEdit, MdOutlineLock, MdPrint, MdSave } from "react-icons/md";
import { FaTags } from "react-icons/fa";
import { getMerchandisesQuantityAmount } from "@consigsys/core";
import { api, currency, translator } from "lib";
import moment from "moment";
import * as database from "database";
import { messages } from "consts";
import { Card, Dialog, StatusBadge } from "components";
import { Entry, Tags } from "containers";
import Comments from "./comments";
import Printable from "./printable";

export const MovementsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [formData, setFormData] = useState({});
  const [isOpenComments, setIsOpenComments] = useState(false);
  const [isOpenDialogConfirm, setIsOpenDialogConfirm] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const toast = useToast();
  const tagsSelectorRef = useRef();
  const bodyRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingData(true);
        const response = await api.get(`/movements/${_id}`);
        setFormData(response);
        const entry = await database.main.entries.where({ _id }).first();
        if (entry && entry.updatedAt > new Date(response.updatedAt)) {
          setFormData((state) => ({
            ...state,
            quantity: entry.quantity,
            amount: entry.amount,
            items: entry.items,
            updatedAt: entry.updatedAt,
            mustUpdate: true,
          }));
        }
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setLoadingData(false);
      }
    };
    _id && fetchData();
  }, [_id, toast]);

  const handleSaveData = async (params = {}) => {
    try {
      setLoadingSaveData(true);
      const response = await api.put(`/movements/${_id}`, { ...formData, ...params });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      database.main.entries.where({ _id }).delete();
      setFormData((state) => ({ ...state, mustUpdate: false }));
      if (response.closed) navigate(-1);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleChangeData = useCallback((items) => {
    setFormData(({ __v, _id, merchant, createdAt, ...state }) => {
      const { quantity, amount } = (() => {
        if (state.event === "MERCHANDISES") return getMerchandisesQuantityAmount(items);
        const response = { quantity: 0, amount: 0 };
        _.forEach(items, (item) => {
          response.quantity += item.quantity;
          response.amount += item.amount;
        });
        return response;
      })();
      const update = { _id, quantity, amount, items, merchant, createdAt: new Date(createdAt), updatedAt: new Date() };
      database.main.entries.put(update);
      return { ...state, ...update, mustUpdate: true };
    });
  }, []);

  const handleOpenTags = useCallback(() => {
    const documents = _.map(formData.items, ({ product: document, quantity }) => ({ document, quantity }));
    const events = { MERCHANDISES: "PRODUCT", PACKS: "PACK" };
    tagsSelectorRef.current.open(events[formData.event], documents, false);
  }, [formData.event, formData.items]);

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <HStack>
          <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem onClick={() => navigate(-1)}>
              <BreadcrumbLink>Movimentações</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : _id}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        <HStack>
          <IconButton variant="ghost" icon={<Icon as={FaTags} />} onClick={handleOpenTags} />
          <ReactToPrint
            content={() => bodyRef.current}
            trigger={() => <IconButton variant="ghost" icon={<Icon as={MdPrint} />} isLoading={isPrinting} />}
            onBeforeGetContent={() =>
              new Promise((resolve) => {
                setIsPrinting(true);
                setTimeout(resolve, 1000);
              })
            }
            onAfterPrint={() => setIsPrinting(false)}
            onPrintError={() => setIsPrinting(false)}
          />
        </HStack>
      </HStack>

      {formData.mustUpdate && (
        <Alert status="warning" borderRadius="lg" mb={4}>
          <AlertIcon />
          <AlertTitle>Sincronização pendente</AlertTitle>
          <AlertDescription>Existem movimentações não sincronizadas neste documento.</AlertDescription>
        </Alert>
      )}

      <Card>
        <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Evento
            </Text>
            <Text fontSize="sm">{translator(formData.event)}</Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Tipo
            </Text>
            <Text fontSize="sm">{translator(formData.type)}</Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Status
            </Text>
            <Text fontSize="sm">
              <StatusBadge label={formData.status} />
            </Text>
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Mercadorias
            </Text>
            <Text fontSize="sm">{formData.quantity?.toLocaleString?.() ?? 0}</Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Valor
            </Text>
            <Text fontSize="sm">{currency.format(formData.amount ?? 0)}</Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Linhas
            </Text>
            <Text fontSize="sm">{formData.items?.length ?? 0}</Text>
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Autor
            </Text>
            <Text fontSize="sm">{formData?.author?.name}</Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Origem
            </Text>
            <Text fontSize="sm">
              {translator(formData.sourceRef)} {formData.source?.nid ?? "-"}
            </Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Destino
            </Text>
            <Text fontSize="sm">
              {translator(formData.destinationRef)} {formData.destination?.nid ?? "-"}
            </Text>
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Criação
            </Text>
            <Text fontSize="sm">{moment(formData.createdAt).format("DD/MM/YYYY HH:mm:ss")}</Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Atualização
            </Text>
            <Text fontSize="sm">{moment(formData.updatedAt).format("DD/MM/YYYY HH:mm:ss")}</Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Fechamento
            </Text>
            <Text fontSize="sm">{formData.closedAt ? moment(formData.closedAt).format("DD/MM/YYYY HH:mm:ss") : "-"}</Text>
          </Box>
        </SimpleGrid>
        <SimpleGrid spacing={4}>
          <Box>
            <HStack>
              <Text fontSize="xs" fontWeight="bold">
                Observações
              </Text>
              <IconButton size="xs" icon={<Icon as={MdEdit} />} onClick={() => setIsOpenComments(true)} isDisabled={formData.closed} />
            </HStack>
            <Text fontSize="sm">{formData.comments?.length > 0 ? formData.comments : "-"}</Text>
          </Box>
        </SimpleGrid>
      </Card>

      <Card>
        {formData.event === "MERCHANDISES" ? (
          <Entry.Merchandises
            defaultValue={formData.items}
            onChange={handleChangeData}
            isLoading={loadingData}
            isClosed={formData?.closed}
          />
        ) : formData.event === "PACKS" ? (
          <Entry.Packs defaultValue={formData.items} onChange={handleChangeData} isLoading={loadingData} isClosed={formData?.closed} />
        ) : null}
      </Card>

      {formData.closed === false && (
        <HStack justifyContent="space-between" mb={2}>
          <Button
            colorScheme="green"
            leftIcon={<Icon as={MdOutlineLock} />}
            isLoading={loadingSaveData || loadingData}
            onClick={() => setIsOpenDialogConfirm(true)}
          >
            Fechar movimentação
          </Button>
          <Button
            colorScheme="main"
            leftIcon={<Icon as={MdSave} />}
            onClick={() => handleSaveData()}
            isLoading={loadingSaveData || loadingData}
          >
            Salvar movimentação
          </Button>
        </HStack>
      )}

      <Comments
        value={formData.comments}
        isOpen={isOpenComments}
        onClose={() => setIsOpenComments(false)}
        onChange={({ comments }) => setFormData((state) => ({ ...state, comments }))}
      />
      <Dialog.Confirm
        isOpen={isOpenDialogConfirm}
        onClose={() => setIsOpenDialogConfirm(false)}
        onConfirm={() => handleSaveData({ closed: true })}
      />

      <Tags.Selector ref={tagsSelectorRef} />

      {isPrinting && <Printable ref={bodyRef} data={formData} />}
    </>
  );
};
