import Dexie from "dexie";

export const synced = new Dexie("consigsys-synced");

synced.version(5).stores({
  products:
    "++_id, uid, nid, name, slug, description, sku, manufacturerSku, internalSku, supplier, costPrice, wholesalePrice, retailPrice, basket, package, inventory, attrs, productGroups, mainImg, imgs, public, active, cluster, fiscal, merchant, [_id+merchant], [uid+merchant], [nid+merchant], [sku+merchant]",
  packs: "++_id, nid, status, dealer, agent, load, amount, shipment, closed, closedAt, merchant, [_id+merchant], [nid+merchant]",
  syncs: "++id, merchant, createdAt",
});
