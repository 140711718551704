import React, { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import * as yup from "yup";
import { api } from "lib";
import { messages } from "consts";
import { useApiGet } from "hooks";
import { Tags } from "containers";

export const TagsBuilder = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/tags/${_id}` }), [_id]));
  const [formErrors, setFormErrors] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  const handleSubmit = async (data) => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        type: yup.string().required(messages.error.required),
        elements: yup.array().min(1, messages.error.required).required(messages.error.required),
      });
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/tags/${_id}`, data) : await api.post("/tags", data);
      navigate(`/tags/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return <Tags.Builder data={data} loading={loadingData || loadingSaveData} formErrors={formErrors} onSubmit={handleSubmit} />;
};
