import { useState, useEffect, createContext, useMemo, useCallback } from "react";
import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom";
import { eventEmitter } from "lib";
import { Preloader } from "components";
import { Private } from "containers";
import { NetworkError, PermissionDenied, InvalidAuthorization, UnquilifiedUser, MerchantError } from "exceptions";
import { useApiGet } from "hooks";
import * as Pages from "./";

export const PrivateContext = createContext();

const PrivateContainer = () => {
  const [currentUser, loadingCurrentUser, refreshCurrentUser] = useApiGet(useMemo(() => ({ path: "/current/user" }), []));
  const [currentMerchant, setCurrentMerchant] = useState();
  const [networkError, setNetworkError] = useState(false);
  const [merchantError, setMerchantError] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [unquilifiedUser, setUnquilifiedUser] = useState(false);
  const [invalidAuthorization, setInvalidAuthorization] = useState(false);
  const [syncedTimestamp, setSyncedTimestamp] = useState(Date.now());

  useEffect(() => {
    const findCurrentMerchant = () => {
      if (currentUser?._id) {
        const storaged = localStorage.getItem("@current-merchant-id");
        if (currentMerchant && currentMerchant._id === storaged) return;
        localStorage.setItem("@current-merchant-id", currentUser.merchant._id)
        setCurrentMerchant(currentUser.merchant);
      }
    };
    findCurrentMerchant();
    const interval = setInterval(findCurrentMerchant, 5000);
    return () => clearInterval(interval);
  }, [currentUser, currentMerchant]);

  useEffect(() => {
    eventEmitter.subscribe("onNetworkError", () => setNetworkError(true));
    eventEmitter.subscribe("onMerchantError", () => setMerchantError(true));
    eventEmitter.subscribe("onAuthorizationError", (error) => setInvalidAuthorization(error));
    eventEmitter.subscribe("onPermissionDenied", (error) => setPermissionDenied(error));
    eventEmitter.subscribe("onUnquilifiedUser", (error) => setUnquilifiedUser(error));
  }, []);

  const refreshSynced = useCallback((force) => {
    let now = Date.now();
    if (force) now = now.toString();
    setSyncedTimestamp(now);
  }, []);

  return (
    <PrivateContext.Provider
      value={{
        currentUser,
        loadingCurrentUser,
        refreshCurrentUser,
        currentMerchant,
        networkError,
        merchantError,
        permissionDenied,
        unquilifiedUser,
        invalidAuthorization,
        setCurrentMerchant,
        setNetworkError,
        setMerchantError,
        setPermissionDenied,
        setUnquilifiedUser,
        setInvalidAuthorization,
        syncedTimestamp,
        refreshSynced,
      }}
    >
      <BrowserRouter key={currentMerchant?._id}>
        {currentUser?._id && currentMerchant?._id ? (
          <Routes>
            <Route path="/" element={<Private />}>
              <Route path="/home" element={<Pages.Home />} />

              <Route path="/benefits">
                <Route index element={<Pages.BenefitsList />} />
                <Route path="details" element={<Pages.BenefitsDetails />} />
                <Route path="details/:_id" element={<Pages.BenefitsDetails />} />
              </Route>

              <Route path="/cities">
                <Route index element={<Pages.CitiesList />} />
                <Route path="details" element={<Pages.CitiesDetails />} />
                <Route path="details/:_id" element={<Pages.CitiesDetails />} />
              </Route>

              <Route path="/commissions">
                <Route index element={<Pages.CommissionsList />} />
                <Route path="details" element={<Pages.CommissionsDetails />} />
                <Route path="details/:_id" element={<Pages.CommissionsDetails />} />
              </Route>

              <Route path="/users">
                <Route index element={<Pages.UsersList />} />
                <Route path="details" element={<Pages.UsersDetails />} />
                <Route path="details/:_id" element={<Pages.UsersDetails />} />
              </Route>

              <Route path="/user-groups">
                <Route index element={<Pages.UserGroupsList />} />
                <Route path="details" element={<Pages.UserGroupsDetails />} />
                <Route path="details/:_id" element={<Pages.UserGroupsDetails />} />
              </Route>

              <Route path="/products">
                <Route index element={<Pages.ProductsList />} />
                <Route path="details" element={<Pages.Cluster />} />
                <Route path="details/:clusterId" element={<Pages.Cluster />} />
              </Route>

              <Route path="product-attributes">
                <Route index element={<Pages.ProductAttributesList />} />
                <Route path="details" element={<Pages.ProductAttributesDetails />} />
                <Route path="details/:_id" element={<Pages.ProductAttributesDetails />} />
              </Route>
              <Route path="product-groups">
                <Route index element={<Pages.ProductGroupsList />} />
                <Route path="details" element={<Pages.ProductGroupsDetails />} />
                <Route path="details/:_id" element={<Pages.ProductGroupsDetails />} />
              </Route>

              <Route path="/inventory-movements">
                <Route index element={<Pages.InventoryMovementsList />} />
                <Route path="details" element={<Pages.InventoryMovementsDetails />} />
                <Route path="details/:_id" element={<Pages.InventoryMovementsDetails />} />
              </Route>

              <Route path="/packs">
                <Route index element={<Pages.PacksList />} />
                <Route path="details" element={<Pages.PacksDetails />} />
                <Route path="details/:_id" element={<Pages.PacksDetails />} />
              </Route>

              <Route path="/orders">
                <Route index element={<Pages.OrdersList />} />
                <Route path="details/:_id" element={<Pages.OrdersDetails />} />
              </Route>

              <Route path="/shipments">
                <Route index element={<Pages.ShipmentsList />} />
                <Route path="details" element={<Pages.ShipmentsDetails />} />
                <Route path="details/:_id" element={<Pages.ShipmentsDetails />} />
                <Route path="details/:_id/settlements" element={<Pages.ShipmentsDetailsSettlements />} />
              </Route>

              <Route path="/movements">
                <Route index element={<Pages.MovementsList />} />
                <Route path="details/:_id" element={<Pages.MovementsDetails />} />
              </Route>

              <Route path="/payment-forms">
                <Route index element={<Pages.PaymentFormsList />} />
                <Route path="details" element={<Pages.PaymentFormsDetails />} />
                <Route path="details/:_id" element={<Pages.PaymentFormsDetails />} />
              </Route>

              <Route path="/wallets">
                <Route index element={<Pages.WalletsList />} />
                <Route path="details" element={<Pages.WalletsDetails />} />
                <Route path="details/:_id" element={<Pages.WalletsDetails />} />
              </Route>

              <Route path="/zones">
                <Route index element={<Pages.ZonesList />} />
                <Route path="details" element={<Pages.ZonesDetails />} />
                <Route path="details/:_id" element={<Pages.ZonesDetails />} />
              </Route>

              <Route path="/chart-of-accounts">
                <Route index element={<Pages.ChartOfAccountsList />} />
                <Route path="details" element={<Pages.ChartOfAccountsDetails />} />
                <Route path="details/:_id" element={<Pages.ChartOfAccountsDetails />} />
              </Route>

              <Route path="/chart-of-account-levels">
                <Route index element={<Pages.ChartOfAccountLevelsList />} />
                <Route path="details" element={<Pages.ChartOfAccountLevelsDetails />} />
                <Route path="details/:_id" element={<Pages.ChartOfAccountLevelsDetails />} />
              </Route>

              <Route path="/accounts">
                <Route index element={<Pages.AccountsList />} />
                <Route path="details" element={<Pages.AccountsDetails />} />
                <Route path="details/:_id" element={<Pages.AccountsDetails />} />
              </Route>

              <Route path="/recurrences">
                <Route index element={<Pages.RecurrencesList />} />
                <Route path="details" element={<Pages.RecurrencesDetails />} />
                <Route path="details/:_id" element={<Pages.RecurrencesDetails />} />
              </Route>

              <Route path="/notifications">
                <Route index element={<Pages.NotificationsList />} />
                <Route path="details" element={<Pages.NotificationsDetails />} />
                <Route path="details/:_id" element={<Pages.NotificationsDetails />} />
              </Route>

              <Route path="/tags">
                <Route index element={<Pages.TagsList />} />
              </Route>

              <Route path="/merchants">
                <Route index element={<Pages.MerchantsList />} />
                <Route path="details" element={<Pages.MerchantsDetails />} />
                <Route path="details/:_id" element={<Pages.MerchantsDetails />} />
              </Route>
            </Route>

            <Route path="/tags">
              <Route path="details/" element={<Pages.TagsBuilder />} />
              <Route path="details/:_id" element={<Pages.TagsBuilder />} />
            </Route>

            <Route path="/memberships" element={<Pages.MembershipsList />} />

            <Route path="*" element={<Navigate to="/users" replace={true} />} />
          </Routes>
        ) : (
          <Preloader />
        )}
        <NetworkError />
        <PermissionDenied />
        <InvalidAuthorization />
        <UnquilifiedUser />
        <MerchantError />
      </BrowserRouter>
    </PrivateContext.Provider>
  );
};

export default PrivateContainer;
