import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Checkbox, HStack, Icon, IconButton, Stack, Text, Button, VStack } from "@chakra-ui/react";
import { MdAdd, MdRefresh } from "react-icons/md";
import { FaRegCircle } from "react-icons/fa";
import { Card } from "components";
import { ProductContext } from ".";
import { ClusterContext } from "..";

export const ProductGroups = () => {
  const { data, errors, handleDataChange } = useContext(ProductContext);
  const { productGroups, loadingProductGroups, refreshProductGroups } = useContext(ClusterContext);

  const handleGroupsChange = (_id) => {
    const productGroups = [...(data.productGroups ?? [])];
    const index = productGroups.map(({ _id }) => _id).indexOf(_id);
    if (index !== -1) productGroups.splice(index, 1);
    else productGroups.push({ _id });
    handleDataChange({ productGroups });
  };

  return (
    <Card borderColor={errors.productGroups && "red.500"}>
      <HStack mb={4} justify="space-between">
        <Box>
          <Text>Grupos</Text>
          <Text fontSize="xs">Selecione os grupos dos quais este produto faz parte.</Text>
          {errors.productGroups && (
            <Text color="red.500" fontSize="xs">
              {errors.productGroups}
            </Text>
          )}
        </Box>
        <IconButton
          size="xs"
          variant="ghost"
          icon={<Icon as={MdRefresh} />}
          onClick={refreshProductGroups}
          isLoading={loadingProductGroups}
        />
      </HStack>
      <Stack flex={1} spacing={2} overflowY="auto" maxHeight="300px">
        {productGroups?.data.map((item) => (
          <Stack key={item._id} align="stretch" spacing={2}>
            <Checkbox
              isChecked={(data.productGroups ?? []).map(({ _id }) => _id).indexOf(item._id) !== -1}
              onChange={() => handleGroupsChange(item._id)}
              fontSize="sm"
            >
              <Text fontSize="sm">{item.name}</Text>
            </Checkbox>
            {item.children?.map((_item) => (
              <Checkbox
                key={_item._id}
                isChecked={(data.productGroups ?? []).map(({ _id }) => _id).indexOf(_item._id) !== -1}
                pl={6}
                onChange={() => handleGroupsChange(_item._id)}
              >
                <Text fontSize="sm">{_item.name}</Text>
              </Checkbox>
            ))}
          </Stack>
        ))}
        {productGroups?.data?.length === 0 && (
          <VStack spacing={0} py={4} textAlign="center">
            <Icon as={FaRegCircle} boxSize={10} marginBottom="10px" />
            <Text fontSize="sm" fontWeight="bold">
              Nenhum grupo cadastrado
            </Text>
            <Text fontSize="xs">Você ainda não cadastrou grupos de produto.</Text>
            <Button
              size="sm"
              colorScheme="main"
              as={RouterLink}
              to="/product-groups/details"
              target="_blank"
              leftIcon={<Icon as={MdAdd} />}
            >
              Cadastrar
            </Button>
          </VStack>
        )}
      </Stack>
    </Card>
  );
};
