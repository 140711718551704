import React, { useState, forwardRef, useContext } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { api } from "lib";
import { AsyncSelect, Card } from "components";
import { UsersDetailsContext } from ".";

let loadCitiesTimeout = null;

const Address = () => {
  const { formData, setFormData, formErrors } = useContext(UsersDetailsContext);
  const [loadingZipCode, setLoadingZipCode] = useState(false);

  const handleZipCodeChange = async ({ target }) => {
    const zipCode = target.value?.replace(/\D/g, "") || "";
    setFormData((state) => ({ ...state, address: { ...state.address, zipCode } }));
    if (zipCode.length !== 8) return;
    setLoadingZipCode(true);
    const address = await api.get(`/locales/${zipCode}`);
    setFormData((state) => ({ ...state, address: { ...state.address, ...address } }));
    setLoadingZipCode(false);
  };

  const handleLoadCities = (search, cb) => {
    clearTimeout(loadCitiesTimeout);
    loadCitiesTimeout = setTimeout(async () => {
      const response = await api.get("/cities", { params: { perPage: 20, page: 0, search, all: true } });
      cb(response?.data ?? []);
    }, 1000);
  };

  return (
    <>
      <Card>
        <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
          <FormControl isRequired={true} isInvalid={formErrors["address.zipCode"]}>
            <FormLabel>CEP</FormLabel>
            <InputGroup>
              <Input as={InputMask} mask="99999-999" value={formData.address?.zipCode ?? ""} onChange={handleZipCodeChange} />
              {loadingZipCode && (
                <InputRightElement>
                  <IconButton type="button" variant="ghost" isLoading={true} />
                </InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage>{formErrors["address.zipCode"]}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors["address.street"]}>
            <FormLabel>Rua</FormLabel>
            <Input
              value={formData.address?.street ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, street: target.value } }))}
            />
            <FormErrorMessage>{formErrors["address.street"]}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors["address.number"]}>
            <FormLabel>Número</FormLabel>
            <Input
              value={formData.address?.number ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, number: target.value } }))}
            />
            <FormErrorMessage>{formErrors["address.number"]}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
          <FormControl isRequired={true} isInvalid={formErrors["address.neighborhood"]}>
            <FormLabel>Bairro</FormLabel>
            <Input
              value={formData.address?.neighborhood ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, neighborhood: target.value } }))}
            />
            <FormErrorMessage>{formErrors["address.neighborhood"]}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors["address.city"]}>
            <FormLabel>Cidade</FormLabel>
            <AsyncSelect
              value={formData.address?.city ?? {}}
              loadOptions={handleLoadCities}
              placeholder="Selecione a cidade"
              onChange={(city) => setFormData((state) => ({ ...state, address: { ...state.address, city } }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name }) => name}
            />
            <FormErrorMessage>{formErrors["address.city"]}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors["address.state"]}>
            <FormLabel>Estado</FormLabel>
            <Input value={formData.address?.city?.state?.name ?? ""} isDisabled={true} />
            <FormErrorMessage>{formErrors["address.state"]}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 2]} spacing={4}>
          <FormControl>
            <FormLabel>Complemento</FormLabel>
            <Input
              value={formData.address?.complement ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, complement: target.value } }))}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Proprietário?</FormLabel>
            <Select
              value={formData.address?.owner ? "yes" : "no"}
              onChange={({ target }) =>
                setFormData((state) => ({ ...state, address: { ...state.address, owner: target.value === "yes" } }))
              }
            >
              <option value="">--Selecione</option>
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </Select>
          </FormControl>
        </SimpleGrid>
      </Card>
    </>
  );
};

export default forwardRef(Address);
