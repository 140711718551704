import React, { useContext } from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { MerchantsDetailsContext } from ".";
import { Card } from "components";

const Subsidiaries = () => {
  const { formData } = useContext(MerchantsDetailsContext);

  return formData?.subsidiaries?.length > 0 ? (
    <Card>
      <Box>
        <Box mb={4}>
          <Text fontSize="lg">Subsidiárias</Text>
          <Text fontSize="xs">As organizações subsidiárias terão os cadastros de produtos da matriz replicados.</Text>
        </Box>
        <VStack alignItems="stretch">
          {_.map(formData.subsidiaries, (item) => (
            <Box borderWidth="1px" borderRadius="lg" p="20px">
              <Text fontWeight="bold">{item.name}</Text>
              <Text fontSize="sm">{item.address?.line1}</Text>
              <Text fontSize="xs">{item.address?.line2}</Text>
            </Box>
          ))}
        </VStack>
      </Box>
    </Card>
  ) : null;
};

export default Subsidiaries;
