import React, { useContext } from "react";
import moment from "moment";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { DatePicker } from "components";
import { MdSave } from "react-icons/md";
import { currency } from "lib";
import { AccountsDetailsContext } from "./index";

export const PartialPayment = () => {
  const { formData, setFormData, formErrors, setFormErrors, handleSubmit, loadingSaveData } = useContext(AccountsDetailsContext);

  const handleClose = () => {
    setFormErrors({});
    setFormData((state) => ({ ...state, nextDueDate: undefined }));
  };

  return (
    <Modal isOpen={formErrors.nextDueDate} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p="30px">
          <Heading>Pagamento parcial</Heading>
          <Text fontSize="sm" marginBottom="1.5rem">
            Você está realizando um pagamento parcial. Será gerado um novo documento com o valor restante de{" "}
            <strong>{currency.format(formData.amount?.remaining)}</strong>. Por favor, escolha a data de vencimento no campo abaixo.
          </Text>
          <form onSubmit={handleSubmit}>
            <SimpleGrid>
              <FormControl isRequired={true} isInvalid={formErrors.nextDueDate}>
                <FormLabel>Data de vencimento</FormLabel>
                <DatePicker
                  selected={formData.nextDueDate}
                  onChange={(nextDueDate) =>
                    setFormData((state) => ({ ...state, nextDueDate: moment(nextDueDate).startOf("day").toDate() }))
                  }
                  dateFormat="d, MMMM yyyy"
                />
                <FormErrorMessage>{formErrors.nextDueDate}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
            <HStack justify="flex-end" spacing={4} mt={6}>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData}>
                Salvar
              </Button>
            </HStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
