import React, { useState, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { PrivateContext } from "pages/Private/Private";
import { Card } from "components";

export const Children = ({ parentData, path }) => {
  const { _id } = useParams();
  const { currentMerchant } = useContext(PrivateContext);
  const [formData, setFormData] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [children, loadingChildren, refreshChildren] = useApiGet(
    useMemo(() => ({ path, params: { query: { parent: _id }, sort: { name: 1 } } }), [path, _id])
  );
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [loadingDeleteData, setLoadingDeleteData] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const toast = useToast();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
      });
      await schema.validate(formData);
      handleSaveData(formData?._id, { ...formData, parent: _id });
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (_id, data) => {
    try {
      setLoadingSaveData(true);
      _id ? await api.put(`/${path}/${_id}`, data) : await api.post(`/${path}`, data);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      setFormData(null);
      refreshChildren();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleDeleteData = async () => {
    try {
      setLoadingDeleteData(true);
      await api.delete(`/${path}/${deleteData._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refreshChildren();
    } catch (error) {
      toast({ description: messages.error.deleteData, status: "error", isClosable: true });
    } finally {
      setLoadingDeleteData(false);
      setDeleteData(null);
    }
  };

  return _id ? (
    <>
      <Card>
        <Flex mb={6}>
          <Box flex="1">
            <Text fontSize="lg" fontWeight="bold">
              Filhos
            </Text>
            <Text fontSize="xs">
              Os filhos são os sub-valores do registro. Por exemplo, os filhos do atributo "Cor" podem ser "azul", "verde", etc.
            </Text>
          </Box>

          <Tooltip label="Ação bloqueada para subsidiárias" isDisabled={!currentMerchant.parent} shouldWrapChildren>
            <IconButton
              icon={<Icon as={MdAdd} />}
              colorScheme="main"
              isLoading={loadingChildren}
              onClick={() => setFormData({})}
              isDisabled={!!currentMerchant.parent}
            />
          </Tooltip>
        </Flex>

        {children?.size === 0 && (
          <Box textAlign="center" p="30px">
            <Icon as={MdAdd} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhum filho cadastrado
            </Text>
            <Text mb="20px" fontSize="sm">
              Este registro ainda não possui filhos cadastrados. Clique no botão abaixo para realizar o primeiro cadastro.
            </Text>
            <Button leftIcon={<Icon as={MdAdd} />} colorScheme="main" onClick={() => setFormData({})}>
              Adicionar filho
            </Button>
          </Box>
        )}

        <VStack align="stretch" spacing={4} divider={<StackDivider />}>
          {(children?.data ?? []).map((item) => (
            <HStack key={item._id} justify="space-between">
              <Box>
                <Text fontSize="sm">
                  {item.name} ({item.slug})
                </Text>
                <Text fontSize="xs">Criado em {moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")}</Text>
              </Box>
              <HStack spacing={4}>
                <Tooltip label="Ação bloqueada para subsidiárias" isDisabled={!currentMerchant.parent} shouldWrapChildren>
                  <IconButton
                    icon={<Icon as={MdDelete} />}
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => setDeleteData(item)}
                    isDisabled={!!currentMerchant.parent}
                  />
                </Tooltip>
                <Tooltip label="Ação bloqueada para subsidiárias" isDisabled={!currentMerchant.parent} shouldWrapChildren>
                  <IconButton
                    icon={<Icon as={MdEdit} />}
                    size="sm"
                    variant="ghost"
                    onClick={() => setFormData(item)}
                    isDisabled={!!currentMerchant.parent}
                  />
                </Tooltip>
              </HStack>
            </HStack>
          ))}
        </VStack>
      </Card>

      <Modal isOpen={formData} onClose={() => setFormData(null)}>
        <form onSubmit={handleSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{formData?._id ? formData.name : `Novo(a) ${parentData?.name}`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isRequired={true} isInvalid={formErrors.name}>
                <FormLabel>Nome</FormLabel>
                <Input value={formData?.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
                <FormErrorMessage>{formErrors.name}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} isDisabled={loadingSaveData} onClick={() => setFormData(null)}>
                Cancelar
              </Button>
              <Button type="submit" colorScheme="main" isLoading={loadingSaveData}>
                {formData?._id ? "Salvar" : "Adicionar"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>

      <Modal closeOnOverlayClick={!loadingDeleteData} isOpen={deleteData} onClose={() => setDeleteData(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atenção</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{messages.warning.deleteData}</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} isDisabled={loadingDeleteData} onClick={() => setDeleteData(null)}>
              Cancelar
            </Button>
            <Button variant="ghost" onClick={handleDeleteData} isLoading={loadingDeleteData}>
              Sim
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  ) : null;
};
