import React, { useState, useContext, useCallback } from "react";
import { Box, Center, HStack, Icon, IconButton, Image, SimpleGrid, Text, Tooltip } from "@chakra-ui/react";
import { MdAdd, MdClose, MdImage } from "react-icons/md";
import { ImageLibrary } from "containers";
import { PrivateContext } from "pages/Private/Private";
import { ProductContext } from ".";
import { Card } from "components";

export const ProductImages = () => {
  const { currentMerchant } = useContext(PrivateContext);
  const { data, handleDataChange } = useContext(ProductContext);
  const [showImageLibrary, setShowImageLibrary] = useState(false);

  const handleDelete = (index) => {
    const imgs = [...(data.imgs ?? [])];
    imgs.splice(index, 1);
    handleDataChange({ imgs });
  };

  const handleFinish = useCallback(
    (selecteds) => {
      const imgs = [...(data.imgs ?? [])];
      const _ids = imgs.map((o) => o._id);
      for (const selected of selecteds) {
        if (_ids.indexOf(selected._id) === -1) imgs.push(selected);
      }
      handleDataChange({ imgs });
    },
    [data.imgs, handleDataChange]
  );

  return (
    <>
      <Card>
        <HStack mb={4} justify="space-between">
          <Box>
            <Text>Imagens</Text>
            <Text fontSize="xs">Selecione as imagens deste produto.</Text>
          </Box>
          <Tooltip label="Ação bloqueada para subsidiárias" isDisabled={!currentMerchant.parent} shouldWrapChildren>
            <IconButton
              icon={<Icon as={MdAdd} />}
              colorScheme="main"
              onClick={() => setShowImageLibrary(true)}
              isDisabled={!!currentMerchant.parent}
            />
          </Tooltip>
        </HStack>
        <SimpleGrid columns={[4, 8]} spacing={2}>
          {(data.imgs ?? []).map((item, index) => (
            <Box key={item._id} position="relative">
              <Image boxSize="100px" objectFit="cover" src={item.transforms.thumbnail.location} alt={item.name} borderRadius="lg" />
              <IconButton
                icon={<Icon as={MdClose} />}
                colorScheme="blackAlpha"
                position="absolute"
                w="100%"
                h="100%"
                top="0"
                left="0"
                opacity="0"
                _hover={{ opacity: 1 }}
                onClick={() => handleDelete(index)}
              />
            </Box>
          ))}
        </SimpleGrid>
        {!data?.imgs?.length && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={MdImage} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma imagem adicionada
              </Text>
              <Text fontSize="sm">Você ainda não adicionou imagens a este produto.</Text>
            </Box>
          </Center>
        )}
      </Card>

      <ImageLibrary isOpen={showImageLibrary} onClose={() => setShowImageLibrary(false)} onFinish={handleFinish} />
    </>
  );
};
