import React, { useEffect, useState, useMemo, useContext } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputRightElement,
  Select,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { MdAttachMoney, MdChevronLeft, MdOutlineLock } from "react-icons/md";
import { BsQuestionCircle } from "react-icons/bs";
import { Dialog, InputCurrency } from "components";
import { useApiGet } from "hooks";
import { messages } from "consts";
import * as yup from "yup";
import { api, currency, percent } from "lib";
import Accounts from "./accounts";
import { ShipmentsSettlementsContext } from "./index";

const defaultRange = { percentageStart: 0, percentageEnd: 0 };

const Container = (props) => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const { settlement, loadingSettlement } = useContext(ShipmentsSettlementsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [paymentForms, loadingPaymentForms] = useApiGet(useMemo(() => ({ path: "/payment-forms", params: { sort: { name: 1 } } }), []));
  const [wallets, loadingWallets] = useApiGet(useMemo(() => ({ path: "/wallets", params: { sort: { name: 1 } } }), []));
  const [chartOfAccounts, loadingChartOfAccounts] = useApiGet(
    useMemo(() => ({ path: "/chart-of-accounts", params: { query: { type: "EXPENSE" }, sort: { name: 1 } } }), [])
  );
  const [range, setRange] = useState(defaultRange);
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const range = _.find(
      settlement?.agent?.saleCommission?.ranges,
      (o) => o.amountStart < settlement?.amounts?.commissionables?.total && o.amountEnd > settlement?.amounts?.commissionables?.total
    );
    setRange(range ?? defaultRange);
  }, [settlement?.amounts?.commissionables?.total, settlement?.agent?.saleCommission?.ranges]);

  useEffect(() => {
    setFormData((state) => ({ ...state, percentage: range?.percentageStart ?? 0 }));
  }, [range?.percentageStart]);

  useEffect(() => {
    const amount = _.round(settlement?.amounts?.commissionables?.total * formData.percentage, 2);
    setFormData((state) => ({ ...state, amount }));
  }, [settlement?.amounts.commissionables?.total, formData.percentage]);

  useEffect(() => {}, [settlement?.accounts]);

  const handleSubmit = async () => {
    try {
      const schema = yup.object().shape({
        wallet: yup.object().typeError(messages.error.required).required(messages.error.required),
        chartOfAccount: yup.object().typeError(messages.error.required).required(messages.error.required),
        paymentForm: yup.object().typeError(messages.error.required).required(messages.error.required),
        percentage: yup
          .number()
          .min(range.percentageStart, messages.error.greaterOrEqual.concat(percent.format(range.percentageStart)))
          .max(range.percentageEnd, messages.error.lessOrEqual.concat(percent.format(range.percentageEnd)))
          .required(messages.error.required),
        amount: yup.number().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      setFormErrors({});
      handleSaveData({
        wallet: formData.wallet?._id,
        chartOfAccount: formData.chartOfAccount?._id,
        paymentForm: formData.paymentForm?._id,
        accounts: _.flatMap(_.values(settlement?.accounts), (account) => _.map(account, ({ _id }) => _id)),
        amount: formData.amount,
      });
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      await api.post(`/shipments/${_id}/settlements`, data);
      navigate("/shipments", { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <>
      <VStack align="stretch" p="10px" spacing={4}>
        <HStack spacing={4} mb={4}>
          <Box flex={1} borderWidth="1px" borderRadius="lg" p="20px">
            <Text fontWeight="bold" mb={4}>
              COMISSIONÁVEIS
            </Text>
            <SimpleGrid columns={[1, 3]} spacing={4}>
              <HStack>
                <IconButton size="sm" icon={<Icon as={MdAttachMoney} />} colorScheme="green" />
                <Box>
                  <Text fontSize="xs">RECEITAS</Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {currency.format(settlement?.amounts?.commissionables?.revenue ?? 0)}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <IconButton size="sm" icon={<Icon as={MdAttachMoney} />} colorScheme="red" />
                <Box>
                  <Text fontSize="xs">DESPESAS</Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {currency.format(settlement?.amounts?.commissionables?.expense ?? 0)}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <IconButton size="sm" icon={<Icon as={MdAttachMoney} />} colorScheme="main" />
                <Box>
                  <Text fontSize="xs">SUBTOTAL</Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {currency.format(settlement?.amounts?.commissionables?.total ?? 0)}
                  </Text>
                </Box>
              </HStack>
            </SimpleGrid>
          </Box>

          <Box flex={1} borderWidth="1px" borderRadius="lg" p="20px">
            <Text fontWeight="bold" mb={4}>
              OUTROS
            </Text>
            <SimpleGrid columns={[1, 3]} spacing={4}>
              <HStack>
                <IconButton size="sm" icon={<Icon as={MdAttachMoney} />} colorScheme="green" />
                <Box>
                  <Text fontSize="xs">RECEITAS</Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {currency.format(settlement?.amounts?.others?.revenue ?? 0)}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <IconButton size="sm" icon={<Icon as={MdAttachMoney} />} colorScheme="red" />
                <Box>
                  <Text fontSize="xs">DESPESAS</Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {currency.format(settlement?.amounts?.others?.expense ?? 0)}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <IconButton size="sm" icon={<Icon as={MdAttachMoney} />} colorScheme="main" />
                <Box>
                  <Text fontSize="xs">SUBTOTAL</Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {currency.format(settlement?.amounts?.others?.total ?? 0)}
                  </Text>
                </Box>
              </HStack>
            </SimpleGrid>
          </Box>
          <Box borderWidth="1px" borderRadius="lg" p="20px">
            <Text fontWeight="bold" mb={4}>
              TOTAL
            </Text>
            <HStack>
              <IconButton size="sm" icon={<Icon as={MdAttachMoney} />} colorScheme={settlement?.amounts?.total > 0 ? "green" : "red"} />
              <Box>
                <Text fontSize="xs">{settlement?.amounts?.total > 0 ? "RECEBE" : "PAGA"}</Text>
                <Text fontSize="sm" fontWeight="bold">
                  {currency.format(settlement?.amounts?.total ?? 0)}
                </Text>
              </Box>
            </HStack>
          </Box>
        </HStack>

        <SimpleGrid columns={[1, 3]} spacing={4} mb={6}>
          <FormControl isRequired={true} isInvalid={formErrors.wallet}>
            <FormLabel>Forma de Pagamento</FormLabel>
            <HStack>
              <Select
                value={formData.paymentForm?._id ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, paymentForm: { _id: target.value } }))}
              >
                <option value="">--Selecione</option>
                {(paymentForms?.data ?? []).map((o) => (
                  <option key={o._id} value={o._id}>
                    {o.name}
                  </option>
                ))}
              </Select>
              {loadingPaymentForms && <Spinner size="sm" />}
            </HStack>
            <FormErrorMessage>{formErrors.paymentForm}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors.wallet}>
            <FormLabel>Carteira</FormLabel>
            <HStack>
              <Select
                value={formData.wallet?._id ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, wallet: { _id: target.value } }))}
              >
                <option value="">--Selecione</option>
                {(wallets?.data ?? []).map((o) => (
                  <option key={o._id} value={o._id}>
                    {o.name}
                  </option>
                ))}
              </Select>
              {loadingWallets && <Spinner size="sm" />}
            </HStack>
            <FormErrorMessage>{formErrors.wallet}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors.chartOfAccount}>
            <FormLabel>Plano de Contas</FormLabel>
            <HStack>
              <Select
                value={formData.chartOfAccount?._id ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, chartOfAccount: { _id: target.value } }))}
                isDisabled={loadingChartOfAccounts}
              >
                <option value="">--Selecione</option>
                {(chartOfAccounts?.data ?? []).map((o) => (
                  <option key={o._id} value={o._id}>
                    {o.name}
                  </option>
                ))}
              </Select>
              {loadingChartOfAccounts && <Spinner size="sm" />}
            </HStack>
            <FormErrorMessage>{formErrors.chartOfAccount}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 4]} spacing={4} pb={6}>
          <FormControl isReadOnly={true}>
            <FormLabel>Representante</FormLabel>
            <Input value={settlement?.agent?.name} />
          </FormControl>

          <FormControl isReadOnly={true}>
            <FormLabel>Regra de comissão</FormLabel>
            <InputGroup>
              <Input value={settlement?.agent?.saleCommission?.name} />
              <InputRightElement>
                <IconButton icon={<Icon as={BsQuestionCircle} />} variant="ghost" />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl isRequired={true} isInvalid={formErrors.percentage}>
            <FormLabel>Comissão aplicada</FormLabel>
            <InputGroup>
              <Input
                as={InputCurrency}
                value={(formData.percentage ?? 0) * 100}
                onChange={(value) => setFormData((state) => ({ ...state, percentage: value / 100 }))}
              />
              <InputRightAddon>%</InputRightAddon>
            </InputGroup>
            <FormErrorMessage>{formErrors.percentage}</FormErrorMessage>
          </FormControl>

          <FormControl isReadOnly={true}>
            <FormLabel>Valor da comissão</FormLabel>
            <InputGroup>
              <InputLeftAddon>R$</InputLeftAddon>
              <Input as={InputCurrency} value={formData.amount ?? 0} />
            </InputGroup>
          </FormControl>
        </SimpleGrid>

        <Tabs isFitted variant="solid-rounded" colorScheme="main">
          <TabList mb={6}>
            <Tab>Comissionáveis</Tab>
            <Tab>Outros</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <Accounts data={settlement?.accounts?.commissionables} />
            </TabPanel>
            <TabPanel p={0}>
              <Accounts data={settlement?.accounts?.others} />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <HStack justify="flex-end" spacing={4}>
          <Button as={RouterLink} to="/shipments" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button
            type="submit"
            colorScheme="green"
            leftIcon={<Icon as={MdOutlineLock} />}
            onClick={() => setIsOpenConfirmDialog(true)}
            isLoading={loadingSettlement || loadingSaveData}
          >
            Fechar remessa
          </Button>
        </HStack>
      </VStack>
      <Dialog.Confirm isOpen={isOpenConfirmDialog} onClose={() => setIsOpenConfirmDialog(false)} onConfirm={handleSubmit} />
    </>
  );
};

export default Container;
