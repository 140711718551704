import React, { useState, useContext, memo, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { MdSearch, MdOutlineSearchOff } from "react-icons/md";
import { AsyncSelect } from "components";
import * as database from "database";
import { PrivateContext } from "pages/Private/Private";
import Fuse from "fuse.js";

let loadOptionsTimeout = null;

const Search = memo(({ onChange }) => {
  const [products, setProducts] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const { currentMerchant } = useContext(PrivateContext);

  useEffect(() => {
    if (isActive) {
      database.synced.products
        .where({ merchant: currentMerchant._id })
        .toArray()
        .then((data) => setProducts(data));
    } else setProducts([]);
  }, [currentMerchant._id, isActive]);

  const handleLoadOptions = (search, cb) => {
    clearTimeout(loadOptionsTimeout);
    loadOptionsTimeout = setTimeout(() => {
      const fuse = new Fuse(products, {
        includeScore: true,
        keys: ["_id", "uid", "nid", "sku", "manufacturerSku", "internalSku", "name"],
      });
      const results = fuse.search(search, { limit: 20 });
      const response = results.map((o) => o.item);
      cb(response);
    }, 1000);
  };

  return (
    <>
      {isActive ? (
        <>
          <IconButton icon={<Icon as={MdOutlineSearchOff} />} colorScheme="main" onClick={() => setIsActive(false)} />
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <AsyncSelect
              loadOptions={handleLoadOptions}
              placeholder="Selecione o produto"
              onChange={onChange}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name }) => name}
            />
          </FormControl>
        </>
      ) : (
        <Box>
          <Popover>
            <PopoverTrigger>
              <IconButton icon={<Icon as={MdSearch} />} />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader fontWeight="bold">Atenção</PopoverHeader>
              <PopoverBody fontSize="xs">
                A busca textual tem baixo desempenho. Antes de selecionar esta opção certifique-se de que seu computador possui recursos de
                processamento disponíveis.
              </PopoverBody>
              <PopoverFooter>
                <Button size="sm" colorScheme="main" isFullWidth onClick={() => setIsActive(true)}>
                  Ativar a busca textual assim mesmo
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Box>
      )}
    </>
  );
});

export default Search;
