import React, { forwardRef } from "react";
import moment from "moment";
import _ from "lodash";
import { Box, VisuallyHidden, Heading } from "@chakra-ui/react";
import { createGlobalStyle } from "styled-components";
import { currency, translator } from "lib";

export const GlobalStyle = createGlobalStyle`
    @page {
        size: A4 portrait;
        margin: 0.6cm;
        margin-bottom: 1.2cm;
        @bottom-center {
            content: counter(page);
        }
    }
    table{
        width: 100%;
        page-break-after: auto;
        font-size: 12px;
    }
    th{
        font-weight: bold;
        text-align: left;
    }
    tr{
        page-break-inside: avoid; 
        page-break-after: auto;
    }
    th,td{
        border: 1px solid #000;
    }
    .table-merged th{
        border-top: none;
    }
    .table-break{
        margin-bottom: 10px;
    }
`;

const Printable = forwardRef(({ data }, ref) => {
  return (
    <VisuallyHidden>
      <Box ref={ref}>
        <GlobalStyle />
        <Heading mb={3} size="md">
          Relatório de pacote
        </Heading>
        <table>
          <tr>
            <th>NID</th>
            <th>ID</th>
            <th>Data de impressão</th>
          </tr>
          <tr>
            <td>{data.nid}</td>
            <td>{data._id}</td>
            <td>{moment().format("DD/MM/YYYY HH:mm:ss")}</td>
          </tr>
        </table>
        <table className="table-merged">
          <thead>
            <tr>
              <th>Status</th>
              <th>Remessa</th>
              <th>Praça</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{translator(data.status)}</td>
              <td>{data.shipment?.nid ?? "-"}</td>
              <td>{data.zone?.name ?? "-"}</td>
            </tr>
          </tbody>
        </table>
        <table className="table-merged">
          <thead>
            <tr>
              <th>Representante</th>
              <th>Revendedor</th>
              <th>Data de Cobrança</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data.agent?.name ?? "-"}</td>
              <td>{data.dealer?.name ?? "-"}</td>
              <td>{data.chargeDate ? moment(data.chargeDate).format("DD/MM/YYYY") : "-"}</td>
            </tr>
          </tbody>
        </table>
        <table className="table-merged">
          <thead>
            <tr>
              <th>Valor recebível</th>
              <th>Valor recebido</th>
              <th>Valor pendente</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{currency.format(data.amount?.receivable ?? 0)}</td>
              <td>{currency.format(data.amount?.received ?? 0)}</td>
              <td>{currency.format(data.amount?.remaining ?? 0)}</td>
            </tr>
          </tbody>
        </table>
        <table className="table-merged">
          <thead>
            <tr>
              <th>Carga original</th>
              <th>Carga atual</th>
              <th>Carga modificada</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {currency.format(data.load?.original?.amount ?? 0)} ({data.load?.original?.quantity ?? 0})
              </td>
              <td>
                {currency.format(data.load?.current?.amount ?? 0)} ({data.load?.current?.quantity ?? 0})
              </td>
              <td>
                {currency.format(data.load?.modified?.amount ?? 0)} ({data.load?.modified?.quantity ?? 0})
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table-break table-merged">
          <thead>
            <tr>
              <th>Criação</th>
              <th>Atualização</th>
              <th>Fechamento</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss")}</td>
              <td>{moment(data.updatedAt).format("DD/MM/YYYY HH:mm:ss")}</td>
              <td>{data.closedAt ? moment(data.closedAt).format("DD/MM/YYYY HH:mm:ss") : "-"}</td>
            </tr>
          </tbody>
        </table>

        <Heading mb={3} size="sm">
          Endereço
        </Heading>
        <table>
          <tr>
            <th>CEP</th>
            <th>Rua</th>
            <th>Número</th>
          </tr>
          <tr>
            <td>{data.address?.zipCode ?? "-"}</td>
            <td>{data.address?.street ?? "-"}</td>
            <td>{data.address?.number ?? "-"}</td>
          </tr>
        </table>
        <table className="table-merged">
          <thead>
            <tr>
              <th>Bairro</th>
              <th>Cidade</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data.address?.neighborhood ?? "-"}</td>
              <td>{data.address?.city?.name ?? "-"}</td>
              <td>{data.address?.city?.state?.name ?? "-"}</td>
            </tr>
          </tbody>
        </table>
        <table className="table-break table-merged">
          <thead>
            <tr>
              <th>Complemento</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data.address?.complement ?? "-"}</td>
            </tr>
          </tbody>
        </table>

        <Heading mb={3} size="sm">
          Mercadorias
        </Heading>
        <table className="table-break">
          <thead>
            <tr>
              <th>NID</th>
              <th>REF</th>
              <th>Nome</th>
              <th>Atributos</th>
              <th>Quantidade</th>
              <th>Valor Unitátio</th>
              <th>Valor Total</th>
            </tr>
          </thead>
          <tbody>
            {_.map(data.merchandises, (item) => (
              <tr key={item._id}>
                <td>{item.product.nid}</td>
                <td>{item.product.sku}</td>
                <td>{item.product.name}</td>
                <td>{_.map(item.product.attrs, "name").join(", ")}</td>
                <td>{item.quantity}</td>
                <td>{currency.format(item.amount)}</td>
                <td>{currency.format(item.quantity * item.amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </VisuallyHidden>
  );
});

export default Printable;
