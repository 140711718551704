import React, { useContext } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdAttachMoney } from "react-icons/md";
import { InputCurrency } from "components";
import { AccountsDetailsContext } from "./index";
import { currency } from "lib";

export const Amount = () => {
  const { formData, setFormData, formErrors, isPaid, cardBackgroundColor } = useContext(AccountsDetailsContext);

  return (
    <SimpleGrid columns={[1, 4]} spacing={4}>
      <FormControl isRequired={true} isInvalid={formErrors["amount.partial"]}>
        <FormLabel>Subtotal</FormLabel>
        <InputGroup>
          <InputLeftAddon>R$</InputLeftAddon>
          <Input
            as={InputCurrency}
            value={formData.amount?.partial ?? ""}
            onChange={(partial) => setFormData((state) => ({ ...state, amount: { ...state.amount, partial } }))}
          />
        </InputGroup>
        <FormErrorMessage>{formErrors["amount.partial"]}</FormErrorMessage>
      </FormControl>
      <VStack align="center" justify="center" spacing={0} backgroundColor={cardBackgroundColor} borderRadius="lg">
        <Text fontSize="sm">Recebíveis</Text>
        <Text fontSize="lg" fontWeight="bold">
          {currency.format(formData.amount?.receivable ?? 0)}
        </Text>
      </VStack>
      <FormControl isRequired={isPaid} isInvalid={formErrors["amount.received"]}>
        <FormLabel>Recebido</FormLabel>
        <InputGroup>
          <InputLeftAddon>R$</InputLeftAddon>
          <Input
            as={InputCurrency}
            value={formData.amount?.received ?? 0}
            onChange={(received) => setFormData((state) => ({ ...state, amount: { ...state.amount, received } }))}
            disabled={!isPaid}
          />
          <InputRightElement>
            <IconButton
              icon={<Icon as={MdAttachMoney} />}
              colorScheme="green"
              variant="ghost"
              onClick={() => setFormData((state) => ({ ...state, amount: { ...state.amount, received: formData.amount?.receivable } }))}
              isDisabled={!isPaid}
            />
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>{formErrors["amount.received"]}</FormErrorMessage>
      </FormControl>
      <VStack align="center" justify="center" spacing={0} backgroundColor={cardBackgroundColor} borderRadius="lg">
        <Text fontSize="sm">A receber</Text>
        <Text fontSize="lg" fontWeight="bold">
          {currency.format(formData.amount?.remaining ?? 0)}
        </Text>
      </VStack>
    </SimpleGrid>
  );
};
