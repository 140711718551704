import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  StackDivider,
  Switch,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MdSave, MdChevronLeft, MdAdd, MdDelete, MdArrowBack } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet } from "hooks";
import { AsyncSelect, Card } from "components";
import _ from "lodash";

const defaultFormData = { type: "REVENUE" };
let loadChartOfAccountLevelsTimeout = null;

export const ChartOfAccountsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/chart-of-accounts/${_id}` }), [_id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? defaultFormData);
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        levels: yup.array().min(1).required(messages.error.required),
      });
      const data = { ...formData, levels: _.map(formData.levels, "_id") };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/chart-of-accounts/${_id}`, data) : await api.post("/chart-of-accounts", data);
      navigate(`/chart-of-accounts/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <HStack>
          <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/chart-of-accounts">
                Plano de Contas
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        <HStack justify="space-between">
          <Text fontSize="xs">Publico?</Text>
          <Switch isChecked={formData.public ?? false} onChange={() => setFormData((state) => ({ ...state, public: !state.public }))} />
        </HStack>
      </HStack>

      <form onSubmit={handleSubmit}>
        <Card>
          <SimpleGrid columns={[1, 2]} spacing={4}>
            <FormControl isRequired={true} isInvalid={formErrors.type}>
              <FormLabel>Tipo</FormLabel>
              <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
                <option value="REVENUE">Receita</option>
                <option value="EXPENSE">Despesa</option>
              </Select>
              <FormErrorMessage>{formErrors.type}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel>Nome</FormLabel>
              <Input
                value={formData.name ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))}
                disabled={true}
              />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Card>

        <Levels data={formData ?? {}} onChange={(data) => setFormData(data)} />

        <HStack justify="flex-end" spacing={4}>
          <Button as={RouterLink} to="/chart-of-accounts" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </>
  );
};

const Levels = ({ data, onChange }) => {
  const [selectedLevel, setSelectedLevel] = useState({});
  const [selectedLevelErrors, setSelectedLevelErrors] = useState({});
  const [currentLevel, setCurrentLevel] = useState(1);

  useEffect(() => {
    setCurrentLevel((data.levels ?? []).length + 1);
  }, [data.levels, data.levels?.length]);

  const handleSubmit = async () => {
    try {
      const schema = yup.object().shape({
        _id: yup.string().required(messages.error.required),
        name: yup.string().required(messages.error.required),
      });
      await schema.validate(selectedLevel);
      handleAddChartOfAccountLevel(selectedLevel);
      setSelectedLevelErrors({});
    } catch (error) {
      setSelectedLevelErrors({ [error.path]: error.message });
    }
  };

  const handleAddChartOfAccountLevel = (selectedLevel) => {
    const levels = data.levels ?? [];
    const exists = levels.find((o) => o._id === selectedLevel._id);
    if (exists) return setSelectedLevel({});
    levels.push(selectedLevel);
    onChange({ ...data, name: _.map(levels, "name").join(" | "), indexes: _.map(levels, "index").join("."), levels });
    setSelectedLevel({});
  };

  const handleDeleteChartOfAccountLevel = (index) => {
    const levels = data.levels ?? [];
    levels.splice(index, 1);
    onChange({ ...data, name: _.map(levels, "name").join(" | "), indexes: _.map(levels, "index").join("."), levels });
  };

  const handleLoadChartOfAccountLevels = (search, cb) => {
    clearTimeout(loadChartOfAccountLevelsTimeout);
    loadChartOfAccountLevelsTimeout = setTimeout(async () => {
      const response = await api.get("/chart-of-account-levels", { params: { search, query: { level: currentLevel } } });
      cb(response?.data ?? []);
    }, 1000);
  };

  return (
    <Card>
      <SimpleGrid columns={[1, 2]} spacing={4}>
        <Box>
          <Text fontSize="lg">Níveis</Text>
          <Text fontSize="xs">Informe os níveis deste plano de contas.</Text>
        </Box>
        <HStack>
          <FormControl>
            <AsyncSelect
              value={selectedLevel ?? {}}
              loadOptions={handleLoadChartOfAccountLevels}
              placeholder="Selecione o nível desejado"
              onChange={(level) => setSelectedLevel(level)}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name }) => name}
            />
            <FormErrorMessage>{selectedLevelErrors._id || selectedLevelErrors.name}</FormErrorMessage>
          </FormControl>
          <Button fontSize="xs" leftIcon={<Icon as={MdAdd} />} colorScheme="main" disabled={!selectedLevel._id} onClick={handleSubmit}>
            Nível {currentLevel}
          </Button>
        </HStack>
      </SimpleGrid>

      <VStack align="stretch" spacing={4} divider={<StackDivider />} mt="20px">
        {(data.levels ?? []).map((item, index) => (
          <HStack key={index} justify="space-between">
            <Box>
              <Text>{item.name}</Text>
              <Text fontSize="xs">Nível {item.level}</Text>
              <Text fontSize="xs">Índice {item.index}</Text>
            </Box>
            <HStack spacing={4}>
              <IconButton
                icon={<Icon as={MdDelete} />}
                variant="ghost"
                colorScheme="red"
                onClick={() => handleDeleteChartOfAccountLevel(index)}
                disabled={index + 1 !== data.levels.length}
              />
            </HStack>
          </HStack>
        ))}
      </VStack>
    </Card>
  );
};
