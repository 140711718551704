import React, { useRef, useMemo } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { Card, Clipboard, DataTable, Dialog } from "components";
import { messages } from "consts";
import { api, currency } from "lib";

export const OrdersList = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useCacheState(20, "OrdersList:perPage");
  const [sort, setSort] = useStickyState({ nid: -1 }, "OrdersList:sort");
  const [page, setPage] = useCacheState(0, "OrdersList:page");
  const [response, loading, refresh, error] = useApiGet(
    useMemo(() => ({ path: "/orders", params: { perPage, page, sort } }), [perPage, page, sort])
  );
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/orders/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/orders">
              Encomendas
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      <Card>
        <DataTable
          tableId="OrdersList:table"
          title="Encomendas"
          data={response?.data ?? []}
          size={response?.size ?? 0}
          loading={loading}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          onRefresh={refresh}
          error={error}
          renderItems={[
            {
              key: "_id",
              label: "ID",
              visible: true,
              render: (value) => <Clipboard value={value} icon={<Icon as={CgHashtag} />} />,
            },
            { key: "nid", label: "NID", visible: true },
            { key: "dealer.name", label: "Revendedor", visible: true, sortable: false },
            { key: "agent.name", label: "Representante", visible: true, sortable: false },
            { key: "quantity", label: "Quantidade", visible: true },
            { key: "amount", label: "Valor", visible: true, formatter: currency.format },
            { key: "pack.nid", label: "Pacote", visible: true },
            {
              key: "closedAt",
              label: "Fechamento",
              visible: true,
              formatter: (value) => (value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-"),
            },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          ActionsComponent={({ item }) => (
            <Tooltip label="Excluir">
              <IconButton
                icon={<Icon as={MdDelete} />}
                size="sm"
                variant="ghost"
                colorScheme="red"
                onClick={() => deleteRef.current.confirm(item, handleDelete)}
              />
            </Tooltip>
          )}
          onRowDoubleClick={({ _id }) => navigate(`/orders/details/${_id}`)}
        />
      </Card>
      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
