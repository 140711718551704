import React, { createContext, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, HStack, useToast } from "@chakra-ui/react";
import { messages } from "consts";
import { api } from "lib";

import { ProductGroups } from "./groups";
import { ProductAttributes } from "./attributes";
import { ProductImages } from "./images";
import { ProductGeneral } from "./general";
import { ProductInfo } from "./info";

import { ClusterContext } from "..";

export const ProductContext = createContext();

export const Product = ({ data = {}, errors = {} }) => {
  const navigate = useNavigate();
  const { setProducts, handleActiveIndexChange } = useContext(ClusterContext);
  const toast = useToast();

  const handleDataChange = useCallback(
    (obj = {}) => {
      if (!data._id) return;
      return setProducts((state) => {
        return { ...state, [data._id]: { ...state[data._id], ...obj } };
      });
    },
    [data._id, setProducts]
  );

  const handleDataDelete = useCallback(
    async ({ _id, createdAt }) => {
      try {
        if (createdAt) await api.delete(`/products/${_id}`);
        setProducts((state) => {
          const tmp = { ...state };
          delete tmp[_id];
          const keys = Object.keys(tmp);
          if (keys.length) handleActiveIndexChange(keys[0]);
          else navigate("/products", { replace: true });
          return tmp;
        });
        toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      } catch (error) {
        toast({ description: messages.error.deleteData, status: "error", isClosable: true });
      }
    },
    [toast, setProducts, handleActiveIndexChange, navigate]
  );

  return (
    <ProductContext.Provider value={{ data, errors, handleDataChange, handleDataDelete }}>
      <HStack align="flex-start" spacing={3}>
        <Box flex={1}>
          <ProductGeneral />
          <ProductImages />
        </Box>
        <Box width="250px">
          <ProductInfo />
          <ProductAttributes />
          <ProductGroups />
        </Box>
      </HStack>
    </ProductContext.Provider>
  );
};
