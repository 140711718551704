import React, { useRef, createContext, useMemo } from "react";
import moment from "moment";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Icon, IconButton, Tooltip, useToast } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { IoBan } from "react-icons/io5";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { Card, Clipboard, DataTable, Dialog } from "components";
import { messages } from "consts";
import { api, translator } from "lib";
import Filter from "./filter";

export const UsersListContext = createContext();

export const UsersList = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useCacheState(20, "UsersList:perPage");
  const [sort, setSort] = useStickyState({ name: 1 }, "UsersList:sort");
  const [page, setPage] = useCacheState(0, "UsersList:page");
  const [search, setSearch] = useCacheState("", "UsersList:search");
  const [query, setQuery] = useCacheState({ active: true }, "UsersList:query");
  const [response, loading, refresh, error] = useApiGet(
    useMemo(() => ({ path: "/users", params: { query, search, perPage, page, sort } }), [query, search, perPage, page, sort])
  );
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/users/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <UsersListContext.Provider value={{ query, setQuery, setPage, loading }}>
      <HStack p={2} mb={2} justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/users">
              Usuários
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/users/details" icon={<Icon as={MdAdd} boxSize={4} />} />
        </Tooltip>
      </HStack>

      <Card>
        <Filter />
      </Card>

      <Card>
        <DataTable
          tableId="UsersList:table"
          title="Usuários"
          data={response?.data ?? []}
          size={response?.size ?? 0}
          loading={loading}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          search={search}
          onSearch={setSearch}
          onRefresh={refresh}
          error={error}
          renderItems={[
            { key: "_id", label: "ID", visible: true, render: (value) => <Clipboard value={value} icon={<Icon as={CgHashtag} />} /> },
            { key: "name", label: "Nome", visible: true },
            { key: "document", label: "CPF/CNPJ", visible: true },
            { key: "phone", label: "Celular", visible: true },
            { key: "email", label: "E-mail", visible: true },
            { key: "roles", label: "Papéis", visible: true, formatter: (value) => _.map(value, translator).join(", ") },
            { key: "userGroup.name", label: "Grupo de Usuário", visible: true },
            { key: "birth", label: "Nascimento", visible: false, formatter: (value) => moment(value).format("DD/MM/YYYY") },
            {
              key: "gender",
              label: "Gênero",
              visible: false,
              formatter: (value) => (value === "f" ? "Feminino" : value === "m" ? "Masculino" : "Outros"),
            },
            {
              key: "category",
              label: "Categoria",
              visible: false,
              formatter: (value) => (value === "pf" ? "Pessoa física" : "Pessoa jurídica"),
            },
            { key: "qualified", label: "Qualificado", visible: false, formatter: (value) => (value ? "SIM" : "NÃO") },
            { key: "platform.os", label: "S.O.", visible: false },
            { key: "platform.osVersion", label: "Versão S.O.", visible: false },
            { key: "platform.appVersion", label: "Versão App", visible: false },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              visible: false,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          ActionsComponent={({ item }) => (
            <Tooltip label="Desativar">
              <IconButton
                icon={<Icon as={IoBan} />}
                size="sm"
                variant="ghost"
                onClick={() => deleteRef.current.confirm(item, handleDelete)}
              />
            </Tooltip>
          )}
          onRowDoubleClick={({ _id }) => navigate(`/users/details/${_id}`)}
        />
      </Card>
      <Dialog.Delete ref={deleteRef} />
    </UsersListContext.Provider>
  );
};
