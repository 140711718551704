import React, { useMemo } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { Card, Clipboard, DataTable } from "components";

export const MerchantsList = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useCacheState(20, "MerchantsList:perPage");
  const [sort, setSort] = useStickyState({ name: 1 }, "MerchantsList:sort");
  const [page, setPage] = useCacheState(0, "MerchantsList:page");
  const [search, setSearch] = useCacheState("", "MerchantsList:search");
  const [response, loading, refresh, error] = useApiGet(
    useMemo(() => ({ path: "/merchants", params: { search, perPage, page, sort } }), [search, perPage, page, sort])
  );

  return (
    <>
      <HStack p={2} mb={2} justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/merchants">
              Organizações
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/merchants/details" icon={<Icon as={MdAdd} boxSize={4} />} />
        </Tooltip>
      </HStack>

      <Card>
        <DataTable
          tableId="MerchantsList:table"
          title="Organizações"
          data={response?.data ?? []}
          size={response?.size ?? 0}
          loading={loading}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          search={search}
          onSearch={setSearch}
          onRefresh={refresh}
          error={error}
          renderItems={[
            { key: "_id", label: "ID", visible: true, render: (value) => <Clipboard value={value} icon={<Icon as={CgHashtag} />} /> },
            { key: "name", label: "Nome", visible: true },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          onRowDoubleClick={({ _id }) => navigate(`/merchants/details/${_id}`)}
        />
      </Card>
    </>
  );
};
