import React, { useImperativeHandle, forwardRef, useState, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { messages } from "consts";

export const Delete = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [params, setParams] = useState(null);
  const [callback, setCallback] = useState(null);
  const [loadingDeleteData, setLoadingDeleteData] = useState(false);
  const cancelRef = useRef();

  useImperativeHandle(
    ref,
    () => ({
      confirm: (params, callback) => {
        setIsOpen(true);
        setParams(params);
        setCallback(() => callback);
      },
    }),
    []
  );

  const handleConfirmDelete = async () => {
    try {
      setLoadingDeleteData(true);
      await callback?.(params);
      setParams(null);
      setCallback(null);
      setIsOpen(false);
    } catch (error) {
      throw error;
    } finally {
      setLoadingDeleteData(false);
    }
  };

  return (
    <AlertDialog isOpen={isOpen} closeOnOverlayClick={!loadingDeleteData} leastDestructiveRef={cancelRef} onClose={() => setIsOpen(false)}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Atenção
          </AlertDialogHeader>
          <AlertDialogBody>{messages.warning.deleteData}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
              Cancelar
            </Button>
            <Button isLoading={loadingDeleteData} colorScheme="red" onClick={handleConfirmDelete} ml={3}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
});
