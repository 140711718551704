import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
import {
  Button,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { getNumeric, skuAdapter } from "@consigsys/core";
import { MdOutlinePlaylistAdd } from "react-icons/md";
import { messages } from "consts";
import * as database from "database";
import { PrivateContext } from "pages/Private/Private";
import ObjectId from "bson-objectid";
import { BsViewList, BsViewStacked } from "react-icons/bs";
import { usePrevious } from "hooks";
import Search from "./search";
import Item from "./item";

const barcodeReaderSound = new Audio("/assets/sounds/scanner.mp3");
const errorSound = new Audio("/assets/sounds/error.mp3");

const Merchandises = ({ defaultValue, isLoading, isClosed, onChange }) => {
  const { currentMerchant } = useContext(PrivateContext);
  const [searchKey, setSearchKey] = useState("_id");
  const [merchandises, setMerchandises] = useState();
  const prevMerchandises = usePrevious(merchandises);
  const inputRef = useRef();
  const formRef = useRef();
  const toast = useToast();

  useEffect(() => {
    if (_.isArray(defaultValue)) setMerchandises(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (prevMerchandises) onChange?.(merchandises);
  }, [merchandises, onChange]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const value = inputRef.current.value;
    formRef.current.reset();
    handleUpdateEntry(value, 1, undefined, true);
    inputRef.current.focus();
  };

  const updateOrCreateEntry = useCallback(
    async (id, increment = 1, quantity, isAuto) => {
      const product = await (async () => {
        if (searchKey === "_id")
          return database.synced.products
            .where(["uid", "merchant"])
            .equals([id, currentMerchant._id])
            .or(["_id", "merchant"])
            .equals([id, currentMerchant._id])
            .or(["nid", "merchant"])
            .equals([getNumeric(id), currentMerchant._id])
            .first();
        return database.synced.products
          .where(["sku", "merchant"])
          .equals([skuAdapter(id, currentMerchant._id), currentMerchant._id])
          .first();
      })();
      if (!product) throw new Error();
      setMerchandises((merchandises) => {
        const tmp = [...merchandises];
        const index = _.findIndex(tmp, (o) => o.product._id === product._id);
        if (index !== -1) {
          const entry = tmp[index];
          if (_.isNumber(quantity)) entry.quantity = quantity;
          else entry.quantity += increment;
          if (isAuto && index > 0) {
            tmp.splice(index, 1);
            tmp.unshift(entry);
          }
        } else {
          const object = {
            _id: ObjectId().toString(),
            product,
            price: product.retailPrice,
            amount: product.retailPrice,
            quantity: quantity ?? increment,
          };
          tmp.unshift(object);
        }
        return tmp;
      });
    },
    [searchKey, currentMerchant._id]
  );

  const handleUpdateEntry = useCallback(
    async (id, increment, quantity, isAuto) => {
      try {
        if (!id) return;
        await updateOrCreateEntry(id, increment, quantity, isAuto);
        barcodeReaderSound.play();
      } catch (error) {
        toast({ description: messages.error.productNotFound, status: "error", isClosable: true });
        errorSound.play();
      }
    },
    [toast, updateOrCreateEntry]
  );

  const handleDeleteEntry = useCallback(async (product) => {
    setMerchandises((state) => {
      const tmp = [...state];
      _.remove(tmp, (o) => o.product._id === product._id);
      return tmp;
    });
  }, []);

  const handleSearch = useCallback((data) => (inputRef.current.value = data[searchKey]), [searchKey]);

  return (
    <>
      {!isClosed && (
        <form ref={formRef} onSubmit={handleSubmit}>
          <HStack align="flex-end">
            <Search onChange={handleSearch} />
            <FormControl>
              <HStack mb="8px">
                {[
                  { value: "_id", label: "ID/NID" },
                  { value: "sku", label: "REF" },
                ].map(({ value, label }) => (
                  <Button key={value} size="xs" colorScheme={searchKey === value ? "main" : "gray"} onClick={() => setSearchKey(value)}>
                    {label}
                  </Button>
                ))}
              </HStack>
              <Input type="text" ref={inputRef} isDisabled={isLoading} autoFocus={true} />
            </FormControl>
            <IconButton type="submit" colorScheme="main" variant="ghost" icon={<Icon as={MdOutlinePlaylistAdd} />} />
          </HStack>
        </form>
      )}

      <Tabs isFitted variant="solid-rounded" colorScheme="main" mt={4} isLazy={true}>
        <TabList mb={6}>
          <Tab>
            <HStack>
              <Icon as={BsViewList} />
              <Text>Última entrada</Text>
            </HStack>
          </Tab>
          <Tab>
            <HStack>
              <Icon as={BsViewStacked} />
              <Text>Todas as entradas</Text>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            {_.size(merchandises) > 0 && (
              <Item
                key={merchandises?.[0]?.product?._id}
                product={merchandises?.[0]?.product}
                amount={merchandises?.[0]?.amount}
                quantity={merchandises?.[0]?.quantity}
                searchKey={searchKey}
                handleUpdateEntry={handleUpdateEntry}
                handleDeleteEntry={handleDeleteEntry}
                isClosed={isClosed}
              />
            )}
          </TabPanel>
          <TabPanel p={0}>
            <VStack spacing={4} align="stretch" divider={<StackDivider />}>
              {_.map(merchandises, (item) => (
                <Item
                  key={item.product._id}
                  product={item.product}
                  amount={item.amount}
                  quantity={item.quantity}
                  searchKey={searchKey}
                  handleUpdateEntry={handleUpdateEntry}
                  handleDeleteEntry={handleDeleteEntry}
                  isClosed={isClosed}
                />
              ))}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default Merchandises;
