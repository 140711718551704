import moment from "moment";

export const formDataToQuery = (formData) => {
  const response = { $and: [] };

  if (formData.category) response.category = formData.category;
  if (formData.gender) response.gender = formData.gender;

  const phone = formData.phone?.replace(/\D/g, "");
  if (phone?.length) response.$and.push({ $or: [{ phone }, { "metadata.phone": phone }] });
  const document = formData.document?.replace(/\D/g, "");
  if (document?.length) response.$and.push({ $or: [{ document }, { "metadata.document": document }] });
  const email = formData.email;
  if (email?.length) response.$and.push({ $or: [{ email }, { "metadata.email": email }] });

  if (formData.cities?.length) response["address.city"] = { $in: formData.cities };
  if (formData.zones?.length) response.zones = { $in: formData.zones };
  if (formData.roles?.length) response.roles = { $in: formData.roles };
  if (formData.userGroups?.length) response.userGroup = { $in: formData.userGroups };

  if (formData.active !== undefined) response.active = formData.active;
  if (formData.qualified !== undefined) response.qualified = formData.qualified;

  if (formData.saleCommissions?.length) response.saleCommission = { $in: formData.saleCommissions };

  if (formData.birth?.start && moment(formData.birth.start, "DD/MM/YYYY").isValid())
    response.birth = { ...response.birth, $gte: moment(formData.birth.start, "DD/MM/YYYY").toDate() };
  if (formData.birth?.end && moment(formData.birth.end, "DD/MM/YYYY").isValid())
    response.birth = { ...response.birth, $lte: moment(formData.birth.end, "DD/MM/YYYY").toDate() };

  if (formData.createdAt?.start && moment(formData.createdAt.start, "DD/MM/YYYY").isValid())
    response.createdAt = { ...response.createdAt, $gte: moment(formData.createdAt.start, "DD/MM/YYYY").toDate() };
  if (formData.createdAt?.end && moment(formData.createdAt.end, "DD/MM/YYYY").isValid())
    response.createdAt = { ...response.createdAt, $lte: moment(formData.createdAt.end, "DD/MM/YYYY").toDate() };

  if (response.$and.length === 0) delete response.$and;

  return response;
};

const findAndKey = (key, $and = []) => {
  for (const andItem of $and) {
    for (const orItem of andItem.$or) {
      if (orItem[key]) return orItem[key];
    }
  }
  return undefined;
};

export const queryToFormData = (query) => {
  const response = {};

  if (query.category) response.category = query.category;
  if (query.gender) response.gender = query.gender;

  const phone = findAndKey("phone", query.$and);
  if (phone) response.phone = phone;
  const document = findAndKey("document", query.$and);
  if (document) response.document = document;
  const email = findAndKey("email", query.$and);
  if (email) response.email = email;

  if (query["address.city"]?.$in) response.cities = query["address.city"].$in;
  if (query.zones?.$in) response.zones = query.zones.$in;
  if (query.userGroup?.$in) response.userGroups = query.userGroup.$in;
  if (query.saleCommission?.$in) response.saleCommissions = query.saleCommission.$in;

  if (query.active !== undefined) response.active = query.active;
  if (query.qualified !== undefined) response.qualified = query.qualified;

  if (query.birth?.$gte) response.birth = { ...response.birth, start: moment(query.birth.$gte).format("DD/MM/YYYY") };
  if (query.birth?.$lte) response.birth = { ...response.birth, end: moment(query.birth.$lte).format("DD/MM/YYYY") };

  if (query.createdAt?.$gte) response.createdAt = { ...response.createdAt, start: moment(query.createdAt.$gte).format("DD/MM/YYYY") };
  if (query.createdAt?.$lte) response.createdAt = { ...response.createdAt, end: moment(query.createdAt.$lte).format("DD/MM/YYYY") };

  return response;
};
