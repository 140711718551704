import React from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";

export const Card = ({ children, ...rest }) => {
  const bg = useColorModeValue("white", "gray.800");
  return (
    <Box borderRadius="lg" p={6} mb={2} bg={bg} {...rest}>
      {children}
    </Box>
  );
};
