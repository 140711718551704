import React, { useState, useContext, useCallback } from "react";
import { Box, Center, HStack, Icon, IconButton, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { MdAdd, MdClose, MdImage } from "react-icons/md";
import { ImageLibrary } from "containers";
import { BenefitsDetailsContext } from "./index";

const Images = () => {
  const { formData, setFormData } = useContext(BenefitsDetailsContext);
  const [showImageLibrary, setShowImageLibrary] = useState(false);

  const handleDelete = (index) => {
    setFormData((state) => {
      const imgs = [...state.imgs];
      imgs.splice(index, 1);
      return { ...state, imgs };
    });
  };

  const handleFinish = useCallback(
    (selecteds) => {
      setFormData((state) => {
        const imgs = [...(state.imgs ?? [])];
        const _ids = imgs.map((o) => o._id);
        for (const selected of selecteds) {
          if (_ids.indexOf(selected._id) === -1) imgs.push(selected);
        }
        return { ...state, imgs };
      });
    },
    [setFormData]
  );

  return (
    <>
      <HStack mb={4} justify="space-between">
        <Box>
          <Text>Imagens</Text>
          <Text fontSize="xs">Selecione as imagens deste produto.</Text>
        </Box>
        <IconButton icon={<Icon as={MdAdd} />} colorScheme="main" onClick={() => setShowImageLibrary(true)} />
      </HStack>
      <SimpleGrid columns={[4, 8]} spacing={2}>
        {(formData.imgs ?? []).map((item, index) => (
          <Box key={item._id} position="relative">
            <Image boxSize="100px" objectFit="cover" src={item.transforms.thumbnail.location} alt={item.name} borderRadius="lg" />
            <IconButton
              icon={<Icon as={MdClose} />}
              colorScheme="blackAlpha"
              position="absolute"
              w="100%"
              h="100%"
              top="0"
              left="0"
              opacity="0"
              _hover={{ opacity: 1 }}
              onClick={() => handleDelete(index)}
            />
          </Box>
        ))}
      </SimpleGrid>
      {!formData?.imgs?.length && (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={MdImage} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhuma imagem adicionada
            </Text>
            <Text fontSize="sm">Você ainda não adicionou imagens a este produto.</Text>
          </Box>
        </Center>
      )}
      <ImageLibrary isOpen={showImageLibrary} onClose={() => setShowImageLibrary(false)} onFinish={handleFinish} />
    </>
  );
};

export default Images;
