import React, { useState } from "react";
import _ from "lodash";
import { Button, Input, InputGroup, InputLeftAddon, Icon } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";

export const InputChips = ({ data = [], onChange, isDisabled, ...rest }) => {
  const [inputText, setInputText] = useState("");

  const handleKeyDown = (e) => {
    if (e.keyCode !== 13 || inputText.length === 0) return;
    e.preventDefault();
    e.stopPropagation();
    setInputText("");
    onChange?.([...data, inputText]);
  };

  const handleDelete = (index) => {
    const tmp = [...data];
    tmp.splice(index, 1);
    onChange?.(tmp);
  };

  return (
    <InputGroup>
      {_.size(data) > 0 && (
        <InputLeftAddon display="inline-block" whiteSpace="pre-wrap" maxWidth="50%" h="auto" p="5px">
          {_.map(data, (item, index) => (
            <Button
              key={index}
              size="xs"
              m="2px"
              colorScheme="main"
              rightIcon={<Icon as={MdClose} />}
              onClick={() => handleDelete(index)}
              isDisabled={isDisabled}
            >
              {item}
            </Button>
          ))}
        </InputLeftAddon>
      )}
      <Input
        h="auto"
        minH="40px"
        value={inputText ?? ""}
        onChange={({ target }) => setInputText(target.value)}
        autoFocus={false}
        onKeyDown={handleKeyDown}
        isDisabled={isDisabled}
        {...rest}
      />
    </InputGroup>
  );
};
