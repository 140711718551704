import React from "react";
import { Badge, Icon, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { CgHashtag } from "react-icons/cg";
import { Clipboard, StatusBadge } from "components";
import { currency, translator } from "lib";

const Accounts = ({ data }) => {
  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>ID</Th>
          <Th>Tipo</Th>
          <Th>Status</Th>
          <Th>NID Pacote</Th>
          <Th>Status Pacote</Th>
          <Th>Recebíveis</Th>
          <Th>Recebido</Th>
          <Th>Data de pagamento</Th>
        </Tr>
      </Thead>
      <Tbody>
        {_.map(data, (item) => (
          <Tr key={item._id}>
            <Td>
              <Clipboard value={item._id} icon={<Icon as={CgHashtag} />} />
            </Td>
            <Td>
              <Badge colorScheme={item.type === "REVENUE" ? "green" : "red"}>{translator(item.type)}</Badge>
            </Td>
            <Td>
              <Badge colorScheme={item.status === "PAID" ? "green" : "orange"}>{translator(item.status)}</Badge>
            </Td>
            <Td>{item?.charge?.pack?.nid ?? "-"}</Td>
            <Td>{item?.charge?.pack?.status ? <StatusBadge label={item?.charge?.pack?.status} /> : "-"}</Td>
            <Td>{currency.format(item.amount?.receivable ?? 0)}</Td>
            <Td>{currency.format(item.amount?.received ?? 0)}</Td>
            <Td>{item.paymentDate ? moment(item.paymentDate).format("DD/MM/YYYY HH:mm:ss") : "-"}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default Accounts;
