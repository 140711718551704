import React, { useMemo, createContext } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Icon, IconButton, Spinner, Tooltip } from "@chakra-ui/react";
import { MdRefresh, MdArrowBack } from "react-icons/md";
import { Preloader } from "components";
import { useApiGet } from "hooks";
import Container from "./container";

export const ShipmentsSettlementsContext = createContext();

export const ShipmentsDetailsSettlements = (props) => {
  const { _id } = useParams();
  const [settlement, loadingSettlement, refreshSettlement] = useApiGet(useMemo(() => ({ path: `/shipments/${_id}/settlements` }), [_id]));

  return (
    <ShipmentsSettlementsContext.Provider value={{ settlement, loadingSettlement, refreshSettlement }}>
      <HStack p="5px" justify="space-between" align="center">
        <HStack>
          <IconButton size="sm" variant="ghost" as={RouterLink} to="/shipments" icon={<Icon as={MdArrowBack} />} />
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/shipments">
                Remessas
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem as={RouterLink} to="/shipments">
              <BreadcrumbLink>{loadingSettlement ? <Spinner size="xs" /> : settlement?.shipment?.nid}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>Fechamento</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        <Tooltip label="Recarregar">
          <IconButton variant="ghost" icon={<Icon as={MdRefresh} />} onClick={refreshSettlement} isLoading={loadingSettlement} />
        </Tooltip>
      </HStack>
      {loadingSettlement ? <Preloader /> : <Container />}
    </ShipmentsSettlementsContext.Provider>
  );
};
