import React from "react";
import { Link } from "react-router-dom";
import { Button, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { MdChevronRight, MdEmail, MdSmartphone } from "react-icons/md";
import { Logo } from "components";

export const SignIn = () => {
  return (
    <>
      <VStack spacing={0} mb={4}>
        <Logo width={100} />
        <Heading py={2}>ConsigSys</Heading>
        <Text fontSize="sm" textAlign="center">
          Seja bem-vindo ao ConsigSys. Escolha um dos métodos de login abaixo para iniciar sua sessão.
        </Text>
      </VStack>
      <VStack align="stretch">
        <Button
          as={Link}
          to="phone-number"
          colorScheme="main"
          leftIcon={<Icon as={MdSmartphone} />}
          rightIcon={<Icon as={MdChevronRight} />}
        >
          Número de telefone
        </Button>
        <Button as={Link} to="email-link" colorScheme="main" leftIcon={<Icon as={MdEmail} />} rightIcon={<Icon as={MdChevronRight} />}>
          Link de e-mail
        </Button>
      </VStack>
    </>
  );
};
