import React, { useState, useEffect, useRef, createContext, useMemo } from "react";
import moment from "moment";
import { Link as RouterLink, useParams, useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { MdArrowBack, MdChevronLeft, MdSave } from "react-icons/md";
import { RiUserStarLine } from "react-icons/ri";
import * as yup from "yup";
import { messages } from "consts";
import { useApiGet } from "hooks";
import { api } from "lib";
import Address from "./address";
import Merchant from "./merchant";
import Personal from "./personal";
import Device from "./device";
import Syncs from "./syncs";
import Metadata from "./metadata";

export const UsersDetailsContext = createContext();

export const UsersDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/users/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();
  const tabRefs = {
    personal: useRef(),
    address: useRef(),
    merchant: useRef(),
  };

  useEffect(() => {
    const formData = data ?? { document: location.state?.document ?? "", active: true };
    if (formData.birth) formData.birth = moment(formData.birth).format("DD/MM/YYYY");
    setFormData(formData);
  }, [data, location.state?.document]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        document: yup.string().required(messages.error.required),
        birth: yup.date().typeError(messages.error.date).required(messages.error.required),
        category: yup.string().required(messages.error.required),
        address: yup.object().shape({
          zipCode: yup.string().required(messages.error.required),
          street: yup.string().required(messages.error.required),
          number: yup.string().required(messages.error.required),
          neighborhood: yup.string().required(messages.error.required),
          city: yup.string().required(messages.error.required),
        }),
        roles: yup.array().min(1, messages.error.required).required(messages.error.required),
      });
      const data = {
        ...formData,
        birth: moment(formData.birth, "DD/MM/YYYY").toDate(),
        address: { ...formData.address, city: formData.address?.city?._id },
        saleCommission: formData.saleCommission?._id,
        recommendationCommission: formData.recommendationCommission?._id,
        userGroup: formData.userGroup?._id,
        zones: _.map(formData.zones, "_id"),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
      const [firstKey] = Object.keys(formErrors);
      if (firstKey) {
        const [path] = firstKey.split(".") ?? [];
        (tabRefs[path] ?? tabRefs.personal).current?.focus();
      }
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/users/${_id}`, data) : await api.post("/users", data);
      navigate(`/users/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <UsersDetailsContext.Provider value={{ formData, formErrors, setFormData }}>
      <HStack p={2} mb={2} alignItems="center" justifyContent="space-between">
        <HStack>
          <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
          <Breadcrumb fontWeight="medium" fontSize="xs">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/users">
                Usuários
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        {_.isBoolean(data?.qualified) && (
          <Tooltip label={data?.qualified ? "QUALIFICADO" : "NÃO QUALIFICADO"}>
            <IconButton colorScheme={data?.qualified ? "green" : "red"} size="sm" icon={<Icon as={RiUserStarLine} />} />
          </Tooltip>
        )}
      </HStack>

      {Object.keys(formErrors).length > 0 && (
        <Alert status="error" borderRadius="xl" mb="20px">
          <AlertIcon />
          <AlertDescription fontSize="xs">Existem campos inválidos no formulário. Por favor, verifique as sessões abaixo.</AlertDescription>
        </Alert>
      )}

      <form onSubmit={handleSubmit} noValidate>
        <Tabs isFitted variant="solid-rounded" colorScheme="main" isLazy={true}>
          <TabList mb={4}>
            <Tab ref={tabRefs.personal}>Pessoal</Tab>
            <Tab ref={tabRefs.address}>Endereço</Tab>
            <Tab ref={tabRefs.merchant}>Organização</Tab>
            <Tab>Dispositivo</Tab>
            <Tab>Sincronizações</Tab>
            {formData.metadata && <Tab>Metadados</Tab>}
          </TabList>

          <TabPanels>
            <TabPanel p={0}>
              <Personal />
            </TabPanel>
            <TabPanel p={0}>
              <Address />
            </TabPanel>
            <TabPanel p={0}>
              <Merchant />
            </TabPanel>
            <TabPanel p={0}>
              <Device />
            </TabPanel>
            <TabPanel p={0}>
              <Syncs />
            </TabPanel>
            {formData.metadata && (
              <TabPanel p={0}>
                <Metadata />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>

        <HStack justify="flex-end" mb={4}>
          <Button as={RouterLink} to="/users" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </UsersDetailsContext.Provider>
  );
};
