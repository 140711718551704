import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, sendSignInLinkToEmail } from "@firebase/auth";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IoPaperPlaneOutline } from "react-icons/io5";
import { messages } from "consts";
import * as yup from "yup";
import { MdEmail } from "react-icons/md";

export const SignInEmailLinkSend = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        email: yup.string().email(messages.error.required),
      });
      await schema.validate(formData);
      handleSignIn(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSignIn = async ({ email }) => {
    try {
      setLoadingSignIn(true);
      const auth = getAuth();
      const actionCodeSettings = {
        url: `${process.env.REACT_APP_DASH_BASE_URL}/sign-in/email-link/validate`,
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem("@sign-in-email-link", email);
      navigate("sended", { state: { email } });
    } catch (error) {
      toast({ description: error.message, status: "error", duration: 5000, isClosable: true });
    } finally {
      setLoadingSignIn(false);
    }
  };

  return (
    <>
      <VStack spacing={0} mb={4}>
        <Icon as={MdEmail} boxSize={16} mb={1} />
        <Heading size="lg">Link de e-mail</Heading>
        <Text fontSize="sm" textAlign="center">
          Informe seu email no campo abaixo para que possamos enviar o link de login.
        </Text>
      </VStack>
      <form onSubmit={handleSubmit}>
        <VStack align="stretch">
          <FormControl isRequired={true} isInvalid={formErrors.email}>
            <InputGroup>
              <InputLeftElement>
                <Icon as={MdEmail} />
              </InputLeftElement>
              <Input
                placeholder="Informe seu e-mail"
                value={formData.email ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))}
                autoFocus={true}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.email}</FormErrorMessage>
          </FormControl>
          <Button type="submit" rightIcon={<Icon as={IoPaperPlaneOutline} />} isLoading={loadingSignIn} colorScheme="main">
            Enviar
          </Button>
        </VStack>
      </form>
    </>
  );
};
