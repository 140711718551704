import React, { useState, useEffect, useRef, useContext, useMemo, createContext } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import * as yup from "yup";
import _ from "lodash";
import { MdSave } from "react-icons/md";
import InputMask from "react-input-mask";
import { messages } from "consts";
import { api } from "lib";
import { Address } from "containers";
import { Card, InputChips } from "components";
import { useApiGet } from "hooks";
import { PrivateContext } from "pages/Private/Private";
import Params from "./params";
import Templates from "./templates";
import Subsidiaries from "./subsidiaries";

export const MerchantsDetailsContext = createContext();

export const MerchantsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const { refreshCurrentUser } = useContext(PrivateContext);
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/merchants/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [documentMask, setDocumentMask] = useState("");
  const toast = useToast();
  const addressRef = useRef();
  const paramsRef = useRef();
  const templatesRef = useRef();

  useEffect(() => {
    setFormData(data ?? { category: "pf", active: true });
  }, [data]);

  useEffect(() => {
    setDocumentMask(formData.category === "pf" ? "999.999.999-99" : "99.999.999/9999-99");
  }, [formData.category]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        category: yup.string().required(messages.error.required),
        document: yup.string().required(messages.error.required),
        params: yup.object().shape({
          feePercentage: yup.number(),
          penaltyPercentage: yup.number(),
          gracePeriod: yup.number(),
        }),
        templates: yup.object().shape({}),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/merchants/${_id}`, data) : await api.post("/merchants", data);
      navigate(`/merchants/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshCurrentUser();
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <MerchantsDetailsContext.Provider value={{ formData, setFormData, formErrors }}>
      <HStack p={2} mb={2} justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink>Organizações</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <HStack>
          <Text fontSize="xs">Ativa?</Text>
          <Switch isChecked={formData.active ?? false} onChange={() => setFormData((state) => ({ ...state, active: !state.active }))} />
        </HStack>
      </HStack>

      <form onSubmit={handleSubmit}>
        <Card>
          <SimpleGrid spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel>Razão Social</FormLabel>
              <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.category}>
              <FormLabel>Categoria</FormLabel>
              <Select
                value={formData.category ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, category: target.value }))}
              >
                <option value="">--Selecione</option>
                <option value="pf">Pessoa física</option>
                <option value="pj">Pessoa jurídica</option>
              </Select>
              <FormErrorMessage>{formErrors.category}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.document}>
              <FormLabel>CPF/CNPJ</FormLabel>
              <Input
                as={InputMask}
                mask={documentMask}
                value={formData.document ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
              />
              <FormErrorMessage>{formErrors.document}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
            <FormControl isInvalid={formErrors.ie}>
              <FormLabel>Inscrição Estadual (IE)</FormLabel>
              <Input value={formData.ie ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, ie: target.value }))} />
              <FormErrorMessage>{formErrors.ie}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formErrors.im}>
              <FormLabel>Inscrição Municipal (IM)</FormLabel>
              <Input value={formData.im ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, im: target.value }))} />
              <FormErrorMessage>{formErrors.im}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={[1, 2]} spacing={4}>
            <FormControl isInvalid={formErrors.email}>
              <FormLabel>E-mail</FormLabel>
              <Input value={formData.email ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))} />
              <FormErrorMessage>{formErrors.email}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formErrors.phones}>
              <FormLabel>Telefones</FormLabel>
              <InputChips
                as={InputMask}
                mask="(99) 99999-9999"
                data={formData.phones}
                onChange={(phones) => setFormData((state) => ({ ...state, phones }))}
              />
              <FormErrorMessage>{formErrors.phones}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Card>

        <Card pb={0}>
          <Box mb={4}>
            <Text fontSize="lg">Endereço</Text>
            <Text fontSize="xs">
              Mantenha sempre os dados referentes ao endereço atualizados. Desta forma os relatórios de localização serão mais precisos.
            </Text>
          </Box>
          <Address
            ref={addressRef}
            data={formData?.address ?? {}}
            errors={formErrors ?? {}}
            onChange={(address) => setFormData((state) => ({ ...state, address: { ...state.address, ...address } }))}
          />
        </Card>

        <Card>
          <Params ref={paramsRef} data={formData.params ?? {}} />
        </Card>

        <Card>
          <Templates ref={templatesRef} data={formData.templates ?? {}} />
        </Card>

        <Subsidiaries />

        <HStack justify="flex-end" spacing={4} mb={2}>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </MerchantsDetailsContext.Provider>
  );
};
