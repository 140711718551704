import React, { useContext, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { MdSave } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { Dialog } from "components";
import { api, currency } from "lib";
import { useApiGet } from "hooks";
import { ShipmentsDetailsContext } from "../index";

export const Derivative = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const { formData: currentShipment, refreshData } = useContext(ShipmentsDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [paymentForms, loadingPaymentForms] = useApiGet(useMemo(() => ({ path: "/payment-forms", params: { sort: { name: 1 } } }), []));
  const [wallets, loadingWallets] = useApiGet(useMemo(() => ({ path: "/wallets", params: { sort: { name: 1 } } }), []));
  const [chartOfAccounts, loadingChartOfAccounts] = useApiGet(
    useMemo(() => ({ path: "/chart-of-accounts", params: { query: { type: "EXPENSE" }, sort: { name: 1 } } }), [])
  );
  const toast = useToast();

  useEffect(() => {
    setFormData({});
  }, [isOpen]);

  const handleSubmit = async () => {
    try {
      const schema = yup.object().shape({
        wallet: yup.object().typeError(messages.error.required).required(messages.error.required),
        chartOfAccount: yup.object().typeError(messages.error.required).required(messages.error.required),
        paymentForm: yup.object().typeError(messages.error.required).required(messages.error.required),
      });
      await schema.validate(formData);
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = await api.post(`/shipments/${_id}/derivative`, data);
      toast({ description: messages.success.saveDataPackWithNid.replace("{NID}", saved.nid), status: "success", isClosable: true });
      onClose?.();
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Criar pacote perdido</Text>
            <Text fontSize="xs" fontWeight="normal">
              Você está criando um novo pacote PERDIDO dentro da remessa com as mercadorias selecionadas. Isto criará um débito em nome do
              representante com o valor total das mercadorias.
            </Text>
            <ModalCloseButton />
          </ModalHeader>
          <Divider />
          <ModalBody py={6}>
            <SimpleGrid columns={3} mb={4}>
              <FormControl>
                <Text fontSize="xs">Representante</Text>
                <Text>{currentShipment.agent?.name}</Text>
              </FormControl>
              <FormControl>
                <Text fontSize="xs">Quantidade de mercadorias</Text>
                <Text>{currentShipment?.load?.current.quantity}</Text>
              </FormControl>
              <FormControl>
                <Text fontSize="xs">Valor das mercadorias</Text>
                <Text>{currency.format(currentShipment?.load?.current.amount ?? 0)}</Text>
              </FormControl>
            </SimpleGrid>

            <SimpleGrid columns={[1, 3]} spacing={4} mb={6}>
              <FormControl isRequired={true} isInvalid={formErrors.wallet}>
                <FormLabel>Forma de Pagamento</FormLabel>
                <HStack>
                  <Select
                    value={formData.paymentForm?._id ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, paymentForm: { _id: target.value } }))}
                  >
                    <option value="">--Selecione</option>
                    {(paymentForms?.data ?? []).map((o) => (
                      <option key={o._id} value={o._id}>
                        {o.name}
                      </option>
                    ))}
                  </Select>
                  {loadingPaymentForms && <Spinner size="sm" />}
                </HStack>
                <FormErrorMessage>{formErrors.paymentForm}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired={true} isInvalid={formErrors.wallet}>
                <FormLabel>Carteira</FormLabel>
                <HStack>
                  <Select
                    value={formData.wallet?._id ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, wallet: { _id: target.value } }))}
                  >
                    <option value="">--Selecione</option>
                    {(wallets?.data ?? []).map((o) => (
                      <option key={o._id} value={o._id}>
                        {o.name}
                      </option>
                    ))}
                  </Select>
                  {loadingWallets && <Spinner size="sm" />}
                </HStack>
                <FormErrorMessage>{formErrors.wallet}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired={true} isInvalid={formErrors.chartOfAccount}>
                <FormLabel>Plano de Contas</FormLabel>
                <HStack>
                  <Select
                    value={formData.chartOfAccount?._id ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, chartOfAccount: { _id: target.value } }))}
                    isDisabled={loadingChartOfAccounts}
                  >
                    <option value="">--Selecione</option>
                    {(chartOfAccounts?.data ?? []).map((o) => (
                      <option key={o._id} value={o._id}>
                        {o.name}
                      </option>
                    ))}
                  </Select>
                  {loadingChartOfAccounts && <Spinner size="sm" />}
                </HStack>
                <FormErrorMessage>{formErrors.chartOfAccount}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <HStack>
              <Button variant="ghost" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="red"
                leftIcon={<Icon as={MdSave} />}
                isLoading={loadingSaveData}
                onClick={() => setIsOpenConfirmDialog(true)}
              >
                Criar pacote Perdido
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Dialog.Confirm
        textConfirmation={false}
        isOpen={isOpenConfirmDialog}
        onClose={() => setIsOpenConfirmDialog(false)}
        onConfirm={handleSubmit}
      />
    </>
  );
};
