import React, { createContext, useCallback, useEffect, useState } from "react";
import { Flex, Divider, Spinner, Center } from "@chakra-ui/react";
import objectScan from "object-scan";
import _ from "lodash";
import { elementPrototypes } from "../../consts";
import { Header, Canvas, Toolbar, Layers } from "./containers";

export const BuilderContext = createContext();

const Builder = ({ data, formErrors, loading, onSubmit }) => {
  const [formData, setFormData] = useState({});
  const [options, setOptions] = useState({ scale: 3 });
  const [currentElement, setCurrentElement] = useState({});
  const [currentElementPrototype, setCurrentElementPrototype] = useState({});

  useEffect(() => {
    setFormData(
      data ?? {
        type: "PRODUCT",
        perPage: 1,
        pageStyle: { paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 },
        elements: [
          {
            key: "canvas",
            elementType: "Box",
            props: { width: "5cm", height: "5cm", overflow: "hidden" },
            children: [],
          },
        ],
      }
    );
  }, [data]);

  useEffect(() => {
    const currentElementPrototype = elementPrototypes.find((o) => o.type === currentElement.elementType);
    setCurrentElementPrototype(currentElementPrototype ?? {});
  }, [currentElement?.elementType]);

  const searchElementPath = useCallback((object, { key }) => {
    const findPath = (id, data) => {
      return objectScan(["**.key"], {
        abort: true,
        rtn: "key",
        filterFn: ({ value }) => value === id,
      })(data);
    };
    const path = findPath(key, object);
    path.pop();
    return path;
  }, []);

  const filterNullElements = (children) => {
    if (_.isArray(children)) {
      const filtered = _.filter(children, (o) => o);
      return filtered.map((o) => ({ ...o, children: filterNullElements(o.children) }));
    }
    return children;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { ...formData, elements: filterNullElements(formData.elements) };
    onSubmit?.(data);
  };

  return (
    <BuilderContext.Provider
      value={{
        loading,
        formData,
        formErrors,
        options,
        currentElement,
        currentElementPrototype,
        setFormData,
        setCurrentElement,
        setOptions,
        searchElementPath,
      }}
    >
      <form onSubmit={handleSubmit}>
        <Flex direction="column" w="100vw" h="100vh" overflow="hidden">
          <Header />
          <Divider />
          {loading ? (
            <Center p="20px">
              <Spinner />
            </Center>
          ) : (
            <Flex flex={1} overflow="hidden">
              <Layers />
              <Canvas />
              <Toolbar />
            </Flex>
          )}
        </Flex>
      </form>
    </BuilderContext.Provider>
  );
};

export default Builder;
