import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { SyncSelect, InputCurrency } from "components";
import _ from "lodash";
import ObjectID from "bson-objectid";

import { ClusterContext } from "..";
import { ProductContext } from "../Product";
import defaultFields from "./defaultFields";

const getOption = (o) => ({ value: o._id, label: `${_.map(o.attrs, "name").join(" • ")} (${o.sku})` });

export const CopyProduct = ({ isOpen, onClose }) => {
  const productContext = useContext(ProductContext);
  const { products, setProducts } = useContext(ClusterContext);
  const [productsList, setProductsList] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
      fields: defaultFields.filter((o) => o.selected),
      srcProduct: productContext?.data ? getOption(productContext?.data) : {},
      dstProducts: [],
      newProducts: 0,
    });
  }, [isOpen, productContext?.data]);

  useEffect(() => {
    setProductsList(Object.values(products));
  }, [products]);

  const handleSubmit = () => {
    const data = _.pick(products[formData.srcProduct.value], _.map(formData.fields, "value"));
    for (const { value } of formData.dstProducts) setProducts((state) => ({ ...state, [value]: { ...state[value], ...data } }));
    for (let i = 0; i < formData.newProducts; i++) {
      const _id = ObjectID().toString();
      setProducts((state) => ({ ...state, [_id]: { ...data, _id } }));
    }
    onClose?.();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={VStack} align="stretch" spacing={0}>
          <Text fontSize="md">Copiar produto</Text>
          <Text fontSize="xs" fontWeight="normal">
            Selecione os parâmetros que deseja copiar do produto de origem. Logo após selecione os produtos de destino, ou a quantidade de
            produtos novos.
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={6}>
          <VStack align="stretch" spacing={3}>
            <FormControl isRequired={true}>
              <FormLabel>Produto de origem</FormLabel>
              <SyncSelect
                placeholder="Selecione o produto de origem"
                value={formData.srcProduct}
                options={productsList.map(getOption)}
                onChange={(srcProduct) => setFormData((state) => ({ ...state, srcProduct }))}
                disabled={productContext?.data?._id}
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Parâmetros</FormLabel>
              <SyncSelect
                isMulti
                placeholder="Selecione os parâmetros"
                defaultValue={defaultFields.filter((o) => o.selected)}
                options={defaultFields}
                onChange={(fields) => setFormData((state) => ({ ...state, fields }))}
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Produtos de destino</FormLabel>
              <SyncSelect
                isMulti
                placeholder="Selecione os produtos"
                options={productsList.filter((o) => o._id !== productContext?.data?._id).map(getOption)}
                onChange={(dstProducts) => setFormData((state) => ({ ...state, dstProducts }))}
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Quantos produtos novos deseja criar?</FormLabel>
              <Input
                as={InputCurrency}
                precision="0"
                value={formData.newProducts ?? ""}
                onChange={(newProducts) => setFormData((state) => ({ ...state, newProducts }))}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button type="button" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button type="button" colorScheme="main" onClick={handleSubmit}>
            Copiar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
