import React, { useState, useContext, useEffect } from "react";
import { Button, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, SimpleGrid, Text, Tooltip, VStack } from "@chakra-ui/react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdSearch } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import _ from "lodash";
import InputMask from "react-input-mask";
import { usePrevious, useCacheState } from "hooks";
import { api } from "lib";
import { SyncSelect, AsyncSelect, InputCurrency } from "components";
import { AccountsListContext } from "./index";
import * as queryFormatter from "./queryFormatter";

let loadUsersTimeout = null;

const LabelButton = ({ children, active, onClick }) => (
  <Button size="xs" colorScheme={active ? "main" : "gray"} onClick={onClick}>
    {children}
  </Button>
);

const Filter = () => {
  const { query, setQuery, setPage, loading } = useContext(AccountsListContext);
  const [formData, setFormData] = useState({});
  const [visible, setVisible] = useCacheState(false, "UsersListFilter:visible");
  const [options, setOptions] = useState({
    types: [
      { value: "REVENUE", label: "Receita" },
      { value: "EXPENSE", label: "Despesa" },
    ],
    statuses: [
      { value: "PAID", label: "Pago" },
      { value: "PENDING", label: "Pendente" },
    ],
  });
  const prevFormData = usePrevious(formData);

  useEffect(() => {
    (async () => {
      const [wallets, paymentForms, chartOfAccounts] = await Promise.all([
        api.get("/wallets", { params: { sort: { name: 1 } } }),
        api.get("/payment-forms", { params: { sort: { name: 1 } } }),
        api.get("/chart-of-accounts", { params: { sort: { name: 1 } } }),
      ]);
      setOptions((state) => ({
        ...state,
        wallets: _.map(wallets?.data, (o) => ({ value: o._id, label: o.name })),
        paymentForms: _.map(paymentForms?.data, (o) => ({ value: o._id, label: o.name })),
        chartOfAccounts: _.map(chartOfAccounts?.data, (o) => ({ value: o._id, label: o.name })),
      }));
    })();
  }, []);

  useEffect(() => {
    if (!prevFormData) setFormData(queryFormatter.queryToFormData(query));
  }, [prevFormData, query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const query = queryFormatter.formDataToQuery(formData);
    setQuery(query);
    setPage(0);
  };

  const handleCleanQuery = () => {
    setFormData({});
    setQuery({});
    setPage(0);
  };

  const handleLoadUsers = (search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.get("/users", { params: { search } });
      cb(response?.data ?? []);
    }, 1000);
  };

  return (
    <form onSubmit={handleSubmit}>
      <HStack justify="space-between">
        <HStack>
          {Object.keys(query).length > 0 && (
            <Tooltip label="Limpar filtros">
              <IconButton colorScheme="main" variant="ghost" icon={<Icon as={FaCircle} />} onClick={handleCleanQuery} />
            </Tooltip>
          )}
          <Text fontWeight="bold">Filtros</Text>
        </HStack>
        <HStack>
          <Tooltip label="Filtrar">
            <IconButton type="submit" icon={<Icon as={MdSearch} />} isLoading={loading} />
          </Tooltip>
          <Tooltip label="Exibir/Ocultar filtros">
            <IconButton icon={<Icon as={visible ? MdKeyboardArrowUp : MdKeyboardArrowDown} />} onClick={() => setVisible(!visible)} />
          </Tooltip>
        </HStack>
      </HStack>

      {visible && (
        <>
          <SimpleGrid columns={[1, 3]} spacing={4} my={4}>
            <VStack align="stretch">
              <LabelButton active={formData.types?.length > 0} onClick={() => setFormData((state) => ({ ...state, types: undefined }))}>
                Tipos
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.types, (value) => _.find(options.types, (o) => o.value === value))}
                options={options.types}
                onChange={(data) => setFormData((state) => ({ ...state, types: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.statuses?.length > 0}
                onClick={() => setFormData((state) => ({ ...state, statuses: undefined }))}
              >
                Status
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.statuses, (value) => _.find(options.statuses, (o) => o.value === value))}
                options={options.statuses}
                onChange={(data) => setFormData((state) => ({ ...state, statuses: _.map(data, "value") }))}
              />
            </VStack>

            <VStack align="stretch">
              <LabelButton active={formData.user} onClick={() => setFormData((state) => ({ ...state, user: undefined }))}>
                Usuário
              </LabelButton>
              <AsyncSelect
                value={formData.user ?? {}}
                loadOptions={handleLoadUsers}
                placeholder="Selecione"
                onChange={(user) => setFormData((state) => ({ ...state, user }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name }) => name}
              />
            </VStack>
          </SimpleGrid>
          <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton active={formData.wallets?.length > 0} onClick={() => setFormData((state) => ({ ...state, wallets: undefined }))}>
                Carteiras
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.wallets, (value) => _.find(options.wallets, (o) => o.value === value))}
                options={options.wallets}
                onChange={(data) => setFormData((state) => ({ ...state, wallets: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.paymentForms?.length > 0}
                onClick={() => setFormData((state) => ({ ...state, paymentForms: undefined }))}
              >
                Formas de Pagamento
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.paymentForms, (value) => _.find(options.paymentForms, (o) => o.value === value))}
                options={options.paymentForms}
                onChange={(data) => setFormData((state) => ({ ...state, paymentForms: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.chartOfAccounts?.length > 0}
                onClick={() => setFormData((state) => ({ ...state, chartOfAccounts: undefined }))}
              >
                Planos de Contas
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.chartOfAccounts, (value) => _.find(options.chartOfAccounts, (o) => o.value === value))}
                options={options.chartOfAccounts}
                onChange={(data) => setFormData((state) => ({ ...state, chartOfAccounts: _.map(data, "value") }))}
              />
            </VStack>
          </SimpleGrid>
          <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton
                active={formData.issueDate?.start}
                onClick={() => setFormData((state) => ({ ...state, issueDate: { ...state.issueDate, start: undefined } }))}
              >
                Data de emissão (início)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.issueDate?.start ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, issueDate: { ...state.issueDate, start: target.value } }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.issueDate?.end}
                onClick={() => setFormData((state) => ({ ...state, issueDate: { ...state.issueDate, end: undefined } }))}
              >
                Data de emissão (fim)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.issueDate?.end ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, issueDate: { ...state.issueDate, end: target.value } }))}
              />
            </VStack>

            <VStack align="stretch">
              <LabelButton
                active={formData.dueDate?.start}
                onClick={() => setFormData((state) => ({ ...state, dueDate: { ...state.dueDate, start: undefined } }))}
              >
                Data de vencimento (início)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.dueDate?.start ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, dueDate: { ...state.dueDate, start: target.value } }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.dueDate?.end}
                onClick={() => setFormData((state) => ({ ...state, dueDate: { ...state.dueDate, end: undefined } }))}
              >
                Data de vencimento (fim)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.dueDate?.end ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, dueDate: { ...state.dueDate, end: target.value } }))}
              />
            </VStack>
          </SimpleGrid>
          <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton
                active={formData.paymentDate?.start}
                onClick={() => setFormData((state) => ({ ...state, paymentDate: { ...state.paymentDate, start: undefined } }))}
              >
                Data de pagamento (início)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.paymentDate?.start ?? ""}
                onChange={({ target }) =>
                  setFormData((state) => ({ ...state, paymentDate: { ...state.paymentDate, start: target.value } }))
                }
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.paymentDate?.end}
                onClick={() => setFormData((state) => ({ ...state, paymentDate: { ...state.paymentDate, end: undefined } }))}
              >
                Data de pagamento (fim)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.paymentDate?.end ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, paymentDate: { ...state.paymentDate, end: target.value } }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.createdAt?.start}
                onClick={() => setFormData((state) => ({ ...state, birth: { ...state.birth, start: undefined } }))}
              >
                Criação (início)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.createdAt?.start ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, createdAt: { ...state.createdAt, start: target.value } }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.createdAt?.end}
                onClick={() => setFormData((state) => ({ ...state, createdAt: { ...state.createdAt, end: undefined } }))}
              >
                Criação (fim)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.createdAt?.end ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, createdAt: { ...state.createdAt, end: target.value } }))}
              />
            </VStack>
          </SimpleGrid>

          <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton
                active={formData["amount.partial"]?.start}
                onClick={() => setFormData((state) => ({ ...state, "amount.partial": { ...state["amount.partial"], start: undefined } }))}
              >
                Valor (início)
              </LabelButton>
              <InputGroup>
                <InputLeftElement fontSize="xs">R$</InputLeftElement>
                <Input
                  as={InputCurrency}
                  value={formData["amount.partial"]?.start ?? ""}
                  onChange={(value) =>
                    setFormData((state) => ({ ...state, "amount.partial": { ...state["amount.partial"], start: value } }))
                  }
                />
              </InputGroup>
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData["amount.partial"]?.end}
                onClick={() => setFormData((state) => ({ ...state, "amount.partial": { ...state["amount.partial"], end: undefined } }))}
              >
                Valor (fim)
              </LabelButton>
              <InputGroup>
                <InputLeftElement fontSize="xs">R$</InputLeftElement>
                <Input
                  as={InputCurrency}
                  value={formData["amount.partial"]?.end ?? ""}
                  onChange={(value) => setFormData((state) => ({ ...state, "amount.partial": { ...state["amount.partial"], end: value } }))}
                />
              </InputGroup>
            </VStack>

            <VStack align="stretch">
              <LabelButton
                active={formData["amount.remaining"]?.start}
                onClick={() =>
                  setFormData((state) => ({ ...state, "amount.remaining": { ...state["amount.remaining"], start: undefined } }))
                }
              >
                Valor restante (início)
              </LabelButton>
              <InputGroup>
                <InputLeftElement fontSize="xs">R$</InputLeftElement>
                <Input
                  as={InputCurrency}
                  value={formData["amount.remaining"]?.start ?? ""}
                  onChange={(value) =>
                    setFormData((state) => ({ ...state, "amount.remaining": { ...state["amount.remaining"], start: value } }))
                  }
                />
              </InputGroup>
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData["amount.remaining"]?.end}
                onClick={() => setFormData((state) => ({ ...state, "amount.remaining": { ...state["amount.remaining"], end: undefined } }))}
              >
                Valor restante (fim)
              </LabelButton>
              <InputGroup>
                <InputLeftElement fontSize="xs">R$</InputLeftElement>
                <Input
                  as={InputCurrency}
                  value={formData["amount.remaining"]?.end ?? ""}
                  onChange={(value) =>
                    setFormData((state) => ({ ...state, "amount.remaining": { ...state["amount.remaining"], end: value } }))
                  }
                />
              </InputGroup>
            </VStack>
          </SimpleGrid>

          <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton
                active={formData["amount.receivable"]?.start}
                onClick={() =>
                  setFormData((state) => ({ ...state, "amount.receivable": { ...state["amount.receivable"], start: undefined } }))
                }
              >
                Valor a receber (início)
              </LabelButton>
              <InputGroup>
                <InputLeftElement fontSize="xs">R$</InputLeftElement>
                <Input
                  as={InputCurrency}
                  value={formData["amount.receivable"]?.start ?? ""}
                  onChange={(value) =>
                    setFormData((state) => ({ ...state, "amount.receivable": { ...state["amount.receivable"], start: value } }))
                  }
                />
              </InputGroup>
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData["amount.receivable"]?.end}
                onClick={() =>
                  setFormData((state) => ({ ...state, "amount.receivable": { ...state["amount.receivable"], end: undefined } }))
                }
              >
                Valor a receber (fim)
              </LabelButton>
              <InputGroup>
                <InputLeftElement fontSize="xs">R$</InputLeftElement>
                <Input
                  as={InputCurrency}
                  value={formData["amount.receivable"]?.end ?? ""}
                  onChange={(value) =>
                    setFormData((state) => ({ ...state, "amount.receivable": { ...state["amount.receivable"], end: value } }))
                  }
                />
              </InputGroup>
            </VStack>

            <VStack align="stretch">
              <LabelButton
                active={formData["amount.received"]?.start}
                onClick={() => setFormData((state) => ({ ...state, "amount.received": { ...state["amount.received"], start: undefined } }))}
              >
                Valor recebido (início)
              </LabelButton>
              <InputGroup>
                <InputLeftElement fontSize="xs">R$</InputLeftElement>
                <Input
                  as={InputCurrency}
                  value={formData["amount.received"]?.start ?? ""}
                  onChange={(value) =>
                    setFormData((state) => ({ ...state, "amount.received": { ...state["amount.received"], start: value } }))
                  }
                />
              </InputGroup>
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData["amount.received"]?.end}
                onClick={() => setFormData((state) => ({ ...state, "amount.received": { ...state["amount.received"], end: undefined } }))}
              >
                Valor recebido (fim)
              </LabelButton>
              <InputGroup>
                <InputLeftElement fontSize="xs">R$</InputLeftElement>
                <Input
                  as={InputCurrency}
                  value={formData["amount.received"]?.end ?? ""}
                  onChange={(value) =>
                    setFormData((state) => ({ ...state, "amount.received": { ...state["amount.received"], end: value } }))
                  }
                />
              </InputGroup>
            </VStack>
          </SimpleGrid>
        </>
      )}
    </form>
  );
};

export default Filter;
