import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import * as yup from "yup";
import { MdSave, MdArrowBack, MdChevronLeft } from "react-icons/md";
import { AsyncSelect, Card } from "components";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet } from "hooks";

let loadCitiesTimeout = null;

export const ZonesDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/zones/${_id}` }), [_id]));
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        city: yup.string().required(messages.error.required),
      });
      const data = { ...formData, owner: formData.owner?._id, city: formData.city?._id };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/zones/${_id}`, data) : await api.post("/zones", data);
      navigate(`/zones/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleLoadCities = (search, cb) => {
    clearTimeout(loadCitiesTimeout);
    loadCitiesTimeout = setTimeout(async () => {
      const response = await api.get("/cities", { params: { search } });
      cb(response?.data ?? []);
    }, 1000);
  };

  return (
    <>
      <HStack p={2} mb={2}>
        <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/zones">
              Praças
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      <form onSubmit={handleSubmit}>
        <Card>
          <SimpleGrid spacing={4} mb={4}>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel>Nome</FormLabel>
              <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid spacing={4}>
            <FormControl isRequired={true} isInvalid={formErrors.city}>
              <FormLabel>Cidade</FormLabel>
              <AsyncSelect
                value={formData.city ?? {}}
                loadOptions={handleLoadCities}
                placeholder="Selecione a cidade"
                onChange={(city) => setFormData((state) => ({ ...state, city }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name, state }) => `${name ?? ""} - ${state?.initials ?? ""}`}
              />
              <FormErrorMessage>{formErrors.city}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Card>

        <HStack justify="flex-end" mb={2}>
          <Button leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </>
  );
};
