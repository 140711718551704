import React, { useState, useEffect, forwardRef, useContext, useCallback } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SimpleGrid,
  Text,
  Textarea,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { Card } from "components";
import { UsersDetailsContext } from ".";
import { MdAdd, MdDelete } from "react-icons/md";
import _ from "lodash";

const Personal = () => {
  const { formData, setFormData, formErrors } = useContext(UsersDetailsContext);
  const [documentMask, setDocumentMask] = useState("");

  useEffect(() => {
    setDocumentMask(formData.category === "pf" ? "999.999.999-99" : "99.999.999/9999-99");
  }, [formData.category]);

  const handleAddKeyValue = useCallback(
    (key) => {
      setFormData((state) => {
        const tmp = [...(state?.[key] ?? [])];
        tmp.push({ key: "", value: "" });
        return { ...state, [key]: tmp };
      });
    },
    [setFormData]
  );

  const handleDeleteKeyValue = useCallback(
    (key, index) => {
      setFormData((state) => {
        const tmp = [...(state?.[key] ?? [])];
        tmp.splice(index, 1);
        return { ...state, [key]: tmp };
      });
    },
    [setFormData]
  );

  const handleChangeKeyValue = useCallback(
    (key, index, attr, value) => {
      setFormData((state) => {
        const tmp = [...(state?.[key] ?? [])];
        tmp[index][attr] = value;
        return { ...state, [key]: tmp };
      });
    },
    [setFormData]
  );

  return (
    <>
      <Card>
        <SimpleGrid spacing={4} mb={4}>
          <FormControl isRequired={true} isInvalid={formErrors.name}>
            <FormLabel>Nome</FormLabel>
            <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
            <FormErrorMessage>{formErrors.name}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
          <FormControl isRequired={true} isInvalid={formErrors.category}>
            <FormLabel>Categoria</FormLabel>
            <Select
              value={formData.category ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, category: target.value }))}
            >
              <option value="">--Selecione</option>
              <option value="pf">Pessoa física</option>
              <option value="pj">Pessoa jurídica</option>
            </Select>
            <FormErrorMessage>{formErrors.category}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors.document}>
            <FormLabel>CPF/CNPJ</FormLabel>
            <Input
              as={InputMask}
              mask={documentMask}
              value={formData.document ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
            />
            <FormErrorMessage>{formErrors.document}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors.birth}>
            <FormLabel>Nascimento</FormLabel>
            <Input
              as={InputMask}
              mask="99/99/9999"
              value={formData.birth ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, birth: target.value }))}
            />
            <FormErrorMessage>{formErrors.birth}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
          <FormControl isInvalid={formErrors.gender}>
            <FormLabel>Sexo</FormLabel>
            <Select value={formData.gender ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, gender: target.value }))}>
              <option value="">--Selecione</option>
              <option value="f">Feminino</option>
              <option value="m">Masculino</option>
              <option value="o">Outros</option>
            </Select>
            <FormErrorMessage>{formErrors.gender}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={formErrors.phone}>
            <FormLabel>Celular</FormLabel>
            <Input
              as={InputMask}
              mask="(99) 99999-9999"
              value={formData.phone ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, phone: target.value }))}
              isDisabled={formData._id && formData.auth?.uid}
            />
            {formData._id && formData.auth?.uid && (
              <FormHelperText fontSize="xs">O celular só pode ser alterado pelo próprio usuário.</FormHelperText>
            )}
            <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={formErrors.email}>
            <FormLabel>E-mail</FormLabel>
            <Input value={formData.email ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))} />
            <FormErrorMessage>{formErrors.email}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
          <FormControl isInvalid={formErrors.occupation}>
            <FormLabel>Profissão</FormLabel>
            <Input
              value={formData.occupation ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, occupation: target.value }))}
            />
            <FormErrorMessage>{formErrors.occupation}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={formErrors.maritalStatus}>
            <FormLabel>Estado civil</FormLabel>
            <Select
              value={formData.maritalStatus ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, maritalStatus: target.value }))}
            >
              <option value="">--Selecione</option>
              <option value="solteiro">Solteiro</option>
              <option value="casado">Casado</option>
              <option value="separado">Separado</option>
              <option value="divorciado">Divorciado</option>
              <option value="viuvo">Viúvo</option>
            </Select>
            <FormErrorMessage>{formErrors.maritalStatus}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid spacing={4} mb={4}>
          <FormControl isInvalid={formErrors.comments}>
            <FormLabel>Observações</FormLabel>
            <Textarea
              value={formData.comments ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
            />
            <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
      </Card>

      <Card>
        <HStack justify="space-between">
          <Text fontSize="lg" fontWeight="bold">
            Contatos de refência
          </Text>
          <IconButton size="sm" colorScheme="main" icon={<Icon as={MdAdd} />} onClick={() => handleAddKeyValue("contacts")} />
        </HStack>
        {_.map(formData.contacts, (item, index) => (
          <SimpleGrid key={index.toString()} columns={[1, 2]} spacing={4} mt={4}>
            <FormControl>
              <FormLabel>Nome ou descrição</FormLabel>
              <Input value={item.key ?? ""} onChange={({ target }) => handleChangeKeyValue("contacts", index, "key", target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Telefone</FormLabel>
              <InputGroup>
                <Input
                  as={InputMask}
                  mask="(99) 99999-9999"
                  value={item.value ?? ""}
                  onChange={({ target }) => handleChangeKeyValue("contacts", index, "value", target.value)}
                />
                <InputRightElement>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    icon={<Icon as={MdDelete} />}
                    onClick={() => handleDeleteKeyValue("contacts", index)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </SimpleGrid>
        ))}
      </Card>

      <Card>
        <HStack justify="space-between">
          <Text fontSize="lg" fontWeight="bold">
            Outros documentos
          </Text>
          <IconButton size="sm" colorScheme="main" icon={<Icon as={MdAdd} />} onClick={() => handleAddKeyValue("documents")} />
        </HStack>
        {_.map(formData.documents, (item, index) => (
          <SimpleGrid key={index.toString()} columns={[1, 2]} spacing={4} mt={4}>
            <FormControl>
              <FormLabel>Tipo</FormLabel>
              <Select value={item.key ?? ""} onChange={({ target }) => handleChangeKeyValue("documents", index, "key", target.value)}>
                <option value="">--Selecione</option>
                <option value="cpf">CPF</option>
                <option value="cnpj">CNPJ</option>
                <option value="rg">RG</option>
                <option value="cnh">CNH</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Número do documento</FormLabel>
              <InputGroup>
                <Input
                  value={item.value ?? ""}
                  onChange={({ target }) => handleChangeKeyValue("documents", index, "value", target.value)}
                />
                <InputRightElement>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    icon={<Icon as={MdDelete} />}
                    onClick={() => handleDeleteKeyValue("documents", index)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </SimpleGrid>
        ))}
      </Card>

      <Card>
        <HStack justify="space-between">
          <Text fontSize="lg" fontWeight="bold">
            Redes sociais
          </Text>
          <IconButton size="sm" colorScheme="main" icon={<Icon as={MdAdd} />} onClick={() => handleAddKeyValue("socials")} />
        </HStack>
        {_.map(formData.socials, (item, index) => (
          <SimpleGrid key={index.toString()} columns={[1, 2]} spacing={4} mt={4}>
            <FormControl>
              <FormLabel>Tipo</FormLabel>
              <Select value={item.key ?? ""} onChange={({ target }) => handleChangeKeyValue("socials", index, "key", target.value)}>
                <option value="">--Selecione</option>
                <option value="facebook">Facebook</option>
                <option value="instagram">Instagram</option>
                <option value="twitter">Twitter</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>@ do usuário</FormLabel>
              <InputGroup>
                <Input value={item.value ?? ""} onChange={({ target }) => handleChangeKeyValue("socials", index, "value", target.value)} />
                <InputRightElement>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    icon={<Icon as={MdDelete} />}
                    onClick={() => handleDeleteKeyValue("socials", index)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </SimpleGrid>
        ))}
      </Card>
    </>
  );
};

export default forwardRef(Personal);
