import React, { useState } from "react";
import { IconButton, InputGroup, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Input } from "@chakra-ui/react";
import { InputCurrency } from "components";

export const InputSize = ({ precision = "2", value, defaultUnit = "cm", onChange }) => {
  const [unit, setUnit] = useState(defaultUnit);
  const floatValue = parseFloat(value ?? 0);

  const handleChangeUnit = (unit) => {
    onChange(`${floatValue}${unit}`);
    setUnit(unit);
  };

  return (
    <InputGroup>
      <Input as={InputCurrency} precision={precision} value={floatValue ?? ""} onChange={(value) => onChange(`${value}${unit}`)} />
      <InputRightElement>
        <Menu>
          <MenuButton size="sm" as={IconButton}>
            {unit}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => handleChangeUnit("px")}>px</MenuItem>
            <MenuItem onClick={() => handleChangeUnit("cm")}>cm</MenuItem>
          </MenuList>
        </Menu>
      </InputRightElement>
    </InputGroup>
  );
};
