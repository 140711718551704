import React, { useState, useEffect, createContext, useMemo, useCallback, useRef } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
  Spinner,
  StackDivider,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import { MdChevronLeft, MdSave, MdArrowBack, MdRefresh, MdAttachMoney, MdPrint } from "react-icons/md";
import { FaTruckLoading, FaBox } from "react-icons/fa";
import { getMerchandisesQuantityAmount } from "@consigsys/core";
import { messages } from "consts";
import { api, currency } from "lib";
import { useApiGet } from "hooks";
import { Entry } from "containers";
import { Card } from "components";

export const OrdersDetailsContext = createContext();

export const OrdersDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/orders/${_id}` }), [_id]));
  const toast = useToast();
  const bodyRef = useRef();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  useEffect(() => {
    const { quantity, amount } = getMerchandisesQuantityAmount(formData.merchandises);
    setFormData((state) => ({ ...state, quantity, amount }));
  }, [formData.merchandises]);

  const handleSaveData = async () => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/orders/${_id}`, formData) : await api.post("/orders", formData);
      navigate(`/orders/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleCreatePack = async () => {
    try {
      setLoadingSaveData(true);
      const response = await api.post(`/orders/${_id}/packs`);
      navigate(`/packs/details/${response._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleChangeMerchandises = useCallback((merchandises) => setFormData((state) => ({ ...state, merchandises })), []);

  return (
    <Box ref={bodyRef}>
      <OrdersDetailsContext.Provider value={{ data, formData, loadingSaveData, loadingData, refreshData, setFormData }}>
        <HStack p={2} mb={2} justify="space-between" align="center">
          <HStack>
            <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
            <Breadcrumb fontWeight="medium" fontSize="xs">
              <BreadcrumbItem>
                <BreadcrumbLink as={RouterLink} to="/home">
                  Home
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink as={RouterLink} to="/orders">
                  Encomendas
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : formData?.nid ?? "Novo"}</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </HStack>
          <HStack>
            <ReactToPrint content={() => bodyRef.current} trigger={() => <IconButton variant="ghost" icon={<Icon as={MdPrint} />} />} />
            <Tooltip label="Recarregar">
              <IconButton variant="ghost" icon={<Icon as={MdRefresh} />} onClick={refreshData} isLoading={loadingData} />
            </Tooltip>
          </HStack>
        </HStack>

        <Card>
          <SimpleGrid columns={[1, 4]} spacing={4}>
            <Box>
              <Text fontSize="xs">NID</Text>
              <Text fontWeight="bold">{formData.nid ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">REPRESENTANTE</Text>
              <Text fontWeight="bold">{formData.agent?.name ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">REVENDEDOR</Text>
              <Text fontWeight="bold">{formData.dealer?.name ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">PACOTE</Text>
              <Text fontWeight="bold">{formData.pack?.nid ?? "-"}</Text>
            </Box>
          </SimpleGrid>
        </Card>

        <Card>
          <HStack divider={<StackDivider />}>
            <HStack flex={1} p="20px">
              <Icon as={MdAttachMoney} boxSize={6} mr={2} />
              <Box>
                <Text fontSize="xs">VALOR</Text>
                <Text fontWeight="bold">{currency.format(formData.amount ?? 0)}</Text>
              </Box>
            </HStack>
            <HStack flex={1} p="20px">
              <Icon as={FaTruckLoading} boxSize={6} mr={2} />
              <Box>
                <Text fontSize="sm">QUANTIDADE</Text>
                <Text fontWeight="bold">{formData.quantity ?? 0}</Text>
              </Box>
            </HStack>
          </HStack>
        </Card>

        <Card>
          <Entry.Merchandises defaultValue={formData.merchandises} onChange={handleChangeMerchandises} isClosed={data?.closed !== false} />
        </Card>

        {!data?.closed && (
          <HStack justify="space-between" spacing={4} mb={2}>
            <Button leftIcon={<Icon as={FaBox} />} colorScheme="main" isLoading={loadingSaveData || loadingData} onClick={handleCreatePack}>
              Transformar em pacote
            </Button>
            <HStack>
              <Button leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
                Voltar
              </Button>
              <Button
                leftIcon={<Icon as={MdSave} />}
                colorScheme="main"
                isLoading={loadingSaveData || loadingData}
                onClick={handleSaveData}
              >
                Salvar
              </Button>
            </HStack>
          </HStack>
        )}
      </OrdersDetailsContext.Provider>
    </Box>
  );
};
