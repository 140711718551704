import React, { useState } from "react";
import {
  Button,
  Divider,
  Icon,
  IconButton,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import { BsQuestionCircle } from "react-icons/bs";

export const Requirements = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Tooltip label="Requisitos para fechamento">
        <IconButton variant="ghost" icon={<Icon as={BsQuestionCircle} />} onClick={() => setIsOpen(true)} />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} scrollBehavior="inside" size="lg" isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Requisitos para fechamento</Text>
            <Text fontSize="sm" fontWeight="normal">
              Para fechar uma remessa, todos os requisitos abaixo devem ser cumpridos.
            </Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <UnorderedList>
              <ListItem>
                <Text fontSize="sm">
                  Todas as mercadorias da remessa devem ser destinadas a outro local. Seja através da conferência ou criando um novo pacote
                  derivado.
                </Text>
              </ListItem>
              <ListItem>
                <Text fontSize="sm">Não devem haver mercadorias pendentes na listagem de conferência.</Text>
              </ListItem>
              <ListItem>
                <Text fontSize="sm">A listagem de pacotes não deve estar vazia.</Text>
              </ListItem>
              <ListItem>
                <Text fontSize="sm">
                  Não devem existir pacotes abertos dentro da remessa, exceto aqueles que estejam com status DISSOLVIDO ou PERDIDO.
                </Text>
              </ListItem>
            </UnorderedList>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button variant="ghost" onClick={() => setIsOpen(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
