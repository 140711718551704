import React from "react";
import { useLocation } from "react-router-dom";
import { Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { MdMarkEmailUnread } from "react-icons/md";

export const SignInEmailLinkSended = () => {
  const location = useLocation();

  return (
    <VStack spacing={0}>
      <Icon as={MdMarkEmailUnread} color="green.500" boxSize={16} />
      <Heading>Link enviado</Heading>
      <Text fontSize="sm" textAlign="center">
        O link de login foi enviado para o endereço <b>{location.state.email}</b>. Por favor, acesse seu e-mail e siga as instruções.
      </Text>
    </VStack>
  );
};
