import _ from "lodash";
import moment from "moment";
import { currency } from "lib";

export const processors = {
  PRODUCT: {
    ID: (o) => o.uid,
    NID: (o) => o.nid,
    REF: (o) => o.sku,
    NOME: (o) => o.name,
    DESCRICAO: (o) => o.description,
    ATRIBUTOS: (o) => _.map(o.attrs, "name").join(","),
    PRECO_CUSTO: (o) => currency.format(o.costPrice),
    PRECO_ATACADO: (o) => currency.format(o.wholesalePrice),
    PRECO_VAREJO: (o) => currency.format(o.retailPrice),
    DATA: (o) => moment().format("DD/MM/YYYY"),
    DATA_HORA: (o) => moment().format("DD/MM/YYYY [às] HH:mm"),
  },
  PACK: {
    ID: (o) => o._id,
    NID: (o) => o.nid,
    CARREGADO: (o) => o.load?.original?.amount,
    REPRESENTANTE: (o) => o.agent?.name,
    REVENDEDOR: (o) => o.dealer?.name,
    PRACA: (o) => o.shipment?.zone?.name,
    DISTRIBUICAO: (o) => `${o.shipment?.distribution?.week}/${o.shipment?.distribution?.year}`,
    COBRANCA: (o) => `${o.shipment?.charge?.week}/${o.shipment?.charge?.year}`,
    DATA: (o) => moment().format("DD/MM/YYYY"),
    DATA_HORA: (o) => moment().format("DD/MM/YYYY [às] HH:mm"),
  },
};
