import React, { useState, useContext, useEffect } from "react";
import { Box, Button, HStack, Icon, IconButton, Input, Select, SimpleGrid, Text, Tooltip, VStack } from "@chakra-ui/react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdSearch } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import _ from "lodash";
import InputMask from "react-input-mask";
import { usePrevious, useCacheState } from "hooks";
import { api, translator } from "lib";
import { roles } from "consts";
import { SyncSelect } from "components";
import { UsersListContext } from "./index";
import * as queryFormatter from "./queryFormatter";
import CPFCNPJMask from "@react-br-forms/cpf-cnpj-mask";

const LabelButton = ({ children, active, onClick }) => (
  <Button size="xs" colorScheme={active ? "main" : "gray"} onClick={onClick}>
    {children}
  </Button>
);

const Filter = () => {
  const { query, setQuery, setPage, loading } = useContext(UsersListContext);
  const [formData, setFormData] = useState({});
  const [visible, setVisible] = useCacheState(false, "UsersListFilter:visible");
  const [options, setOptions] = useState({
    active: [
      { value: "", label: "Selecione" },
      { value: true, label: "Sim" },
      { value: false, label: "Não" },
    ],
    qualified: [
      { value: "", label: "Selecione" },
      { value: true, label: "Sim" },
      { value: false, label: "Não" },
    ],
    roles: _.map(roles, (role) => ({ value: role, label: translator(role) })),
  });
  const prevFormData = usePrevious(formData);

  useEffect(() => {
    (async () => {
      const [zones, commissions, cities, userGroups] = await Promise.all([
        api.get("/zones", { params: { sort: { name: 1 } } }),
        api.get("/commissions", { params: { sort: { name: 1 } } }),
        api.get("/cities", { params: { sort: { name: 1 } } }),
        api.get("/user-groups", { params: { sort: { name: 1 } } }),
      ]);
      const saleCommissions = _.filter(commissions?.data, (o) => o.type === "SALES");
      const recommendationCommissions = _.filter(commissions?.data, (o) => o.type === "RECOMMENDATIONS");
      setOptions((state) => ({
        ...state,
        zones: _.map(zones?.data, (o) => ({ value: o._id, label: o.name })),
        saleCommissions: _.map(saleCommissions, (o) => ({ value: o._id, label: o.name })),
        recommendationCommissions: _.map(recommendationCommissions, (o) => ({ value: o._id, label: o.name })),
        cities: _.map(cities?.data, (o) => ({ value: o._id, label: o.name })),
        userGroups: _.map(userGroups?.data, (o) => ({ value: o._id, label: o.name })),
      }));
    })();
  }, []);

  useEffect(() => {
    if (!prevFormData) setFormData(queryFormatter.queryToFormData(query));
  }, [prevFormData, query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const query = queryFormatter.formDataToQuery(formData);
    setQuery(query);
    setPage(0);
  };

  const handleCleanQuery = () => {
    setFormData({});
    setQuery({});
    setPage(0);
  };

  return (
    <form onSubmit={handleSubmit}>
      <HStack justify="space-between">
        <HStack>
          {Object.keys(query).length > 0 && (
            <Tooltip label="Limpar filtros">
              <IconButton colorScheme="main" variant="ghost" icon={<Icon as={FaCircle} />} onClick={handleCleanQuery} />
            </Tooltip>
          )}
          <Text fontWeight="bold">Filtros</Text>
        </HStack>
        <HStack>
          <Tooltip label="Filtrar">
            <IconButton type="submit" icon={<Icon as={MdSearch} />} isLoading={loading} />
          </Tooltip>
          <Tooltip label="Exibir/Ocultar filtros">
            <IconButton icon={<Icon as={visible ? MdKeyboardArrowUp : MdKeyboardArrowDown} />} onClick={() => setVisible(!visible)} />
          </Tooltip>
        </HStack>
      </HStack>

      {visible && (
        <Box mt={4}>
          <SimpleGrid columns={[1, 5]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton active={_.isBoolean(formData.active)} onClick={() => setFormData((state) => ({ ...state, active: undefined }))}>
                Ativos?
              </LabelButton>
              <SyncSelect
                placeholder="Selecione"
                value={options.active.find((o) => o.value === formData.active) ?? options.active?.[0]}
                options={options.active}
                onChange={({ value }) => setFormData((state) => ({ ...state, active: value }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={_.isBoolean(formData.qualified)}
                onClick={() => setFormData((state) => ({ ...state, qualified: undefined }))}
              >
                Qualificados?
              </LabelButton>
              <SyncSelect
                placeholder="Selecione"
                value={options.qualified.find((o) => o.value === formData.qualified) ?? options.qualified?.[0]}
                options={options.qualified}
                onChange={({ value }) => setFormData((state) => ({ ...state, qualified: value }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton active={formData.gender} onClick={() => setFormData((state) => ({ ...state, gender: undefined }))}>
                Sexo
              </LabelButton>
              <Select value={formData.gender ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, gender: target.value }))}>
                <option value="">--Selecione</option>
                <option value="f">Feminino</option>
                <option value="m">Masculino</option>
                <option value="o">Outros</option>
              </Select>
            </VStack>
            <VStack align="stretch">
              <LabelButton active={formData.phone} onClick={() => setFormData((state) => ({ ...state, phone: undefined }))}>
                Celular
              </LabelButton>
              <Input
                as={InputMask}
                mask="(99) 99999-9999"
                value={formData.phone ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, phone: target.value }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton active={formData.email} onClick={() => setFormData((state) => ({ ...state, email: undefined }))}>
                E-mail
              </LabelButton>
              <Input value={formData.email ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))} />
            </VStack>
          </SimpleGrid>
          <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton active={formData.category} onClick={() => setFormData((state) => ({ ...state, category: undefined }))}>
                Categoria
              </LabelButton>
              <Select
                value={formData.category ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, category: target.value }))}
              >
                <option value="">--Selecione</option>
                <option value="pf">Pessoa física</option>
                <option value="pj">Pessoa jurídica</option>
              </Select>
            </VStack>
            <VStack align="stretch">
              <LabelButton active={formData.document} onClick={() => setFormData((state) => ({ ...state, document: undefined }))}>
                CPF/CNPJ
              </LabelButton>
              <Input
                as={CPFCNPJMask}
                value={formData.document ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton active={formData.roles?.length > 0} onClick={() => setFormData((state) => ({ ...state, roles: undefined }))}>
                Papéis
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.roles, (value) => _.find(options.roles, (o) => o.value === value))}
                options={options.roles}
                onChange={(data) => setFormData((state) => ({ ...state, roles: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.userGroups?.length > 0}
                onClick={() => setFormData((state) => ({ ...state, userGroups: undefined }))}
              >
                Grupos
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.userGroups, (value) => _.find(options.userGroups, (o) => o.value === value))}
                options={options.userGroups}
                onChange={(data) => setFormData((state) => ({ ...state, userGroups: _.map(data, "value") }))}
              />
            </VStack>
          </SimpleGrid>
          <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton active={formData.zones?.length > 0} onClick={() => setFormData((state) => ({ ...state, zones: undefined }))}>
                Praças
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.zones, (value) => _.find(options.zones, (o) => o.value === value))}
                options={options.zones}
                onChange={(data) => setFormData((state) => ({ ...state, zones: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.saleCommissions?.length > 0}
                onClick={() => setFormData((state) => ({ ...state, saleCommissions: undefined }))}
              >
                Comissões de venda
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.saleCommissions, (value) => _.find(options.saleCommissions, (o) => o.value === value))}
                options={options.saleCommissions}
                onChange={(data) => setFormData((state) => ({ ...state, saleCommissions: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.recommendationCommissions?.length > 0}
                onClick={() => setFormData((state) => ({ ...state, recommendationCommissions: undefined }))}
              >
                Comissões de indicação
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.recommendationCommissions, (value) =>
                  _.find(options.recommendationCommissions, (o) => o.value === value)
                )}
                options={options.recommendationCommissions}
                onChange={(data) => setFormData((state) => ({ ...state, recommendationCommissions: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton active={formData.cities?.length > 0} onClick={() => setFormData((state) => ({ ...state, cities: undefined }))}>
                Cidades
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.cities, (value) => _.find(options.cities, (o) => o.value === value))}
                options={options.cities}
                onChange={(data) => setFormData((state) => ({ ...state, cities: _.map(data, "value") }))}
              />
            </VStack>
          </SimpleGrid>
          <SimpleGrid columns={[1, 4]} spacing={4}>
            <VStack align="stretch">
              <LabelButton
                active={formData.birth?.start}
                onClick={() => setFormData((state) => ({ ...state, birth: { ...state.birth, start: undefined } }))}
              >
                Nascimento (início)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.birth?.start ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, birth: { ...state.birth, start: target.value } }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.birth?.end}
                onClick={() => setFormData((state) => ({ ...state, birth: { ...state.birth, end: undefined } }))}
              >
                Nascimento (fim)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.birth?.end ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, birth: { ...state.birth, end: target.value } }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.createdAt?.start}
                onClick={() => setFormData((state) => ({ ...state, birth: { ...state.birth, start: undefined } }))}
              >
                Criação (início)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.createdAt?.start ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, createdAt: { ...state.createdAt, start: target.value } }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.createdAt?.end}
                onClick={() => setFormData((state) => ({ ...state, createdAt: { ...state.createdAt, end: undefined } }))}
              >
                Criação (fim)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.createdAt?.end ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, createdAt: { ...state.createdAt, end: target.value } }))}
              />
            </VStack>
          </SimpleGrid>
        </Box>
      )}
    </form>
  );
};

export default Filter;
