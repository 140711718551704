import React, { useState, useEffect, createContext, useCallback, useMemo, useContext } from "react";
import { Link as RouterLink, useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { MdSave, MdChevronLeft, MdAdd, MdError, MdLocalOffer, MdArrowBack } from "react-icons/md";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet, useQuery } from "hooks";
import * as yup from "yup";
import _ from "lodash";
import ObjectID from "bson-objectid";
import { PrivateContext } from "pages/Private/Private";
import { Product } from "./Product";
import { CopyProduct } from "./CopyProduct";

class EqualAttrsError extends Error {
  constructor(path) {
    super(messages.error.equalAttrs);
    this.path = path;
  }
}

export const ClusterContext = createContext();

export const Cluster = () => {
  const { clusterId } = useParams();
  const { currentMerchant } = useContext(PrivateContext);
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [refreshData, setRefreshData] = useState(Date.now());
  const [productAttributes, loadingProductAttributes, refreshProductAttributes] = useApiGet(
    useMemo(() => ({ path: "/product-attributes", params: { query: { parent: { $exists: false } } } }), [])
  );
  const [productGroups, loadingProductGroups, refreshProductGroups] = useApiGet(
    useMemo(() => ({ path: "/product-groups", params: { query: { parent: { $exists: false } } } }), [])
  );
  const [products, setProducts] = useState({});
  const [errors, setErrors] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [productId, setProductId] = useState();
  const toast = useToast();

  const handleActiveIndexChange = useCallback(
    (_id) => {
      const { pathname } = window.location;
      navigate(`${pathname}?p=${_id}`, { replace: true });
    },
    [navigate]
  );

  useEffect(() => {
    setProductId(query.get("p"));
  }, [query]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingData(true);
        const docs = await api.get(`/product-clusters/${clusterId}`);
        setData(docs);
      } catch (error) {
        const _id = ObjectID().toString();
        setData([{ _id }]);
        handleActiveIndexChange(_id);
      } finally {
        setLoadingData(false);
      }
    })();
  }, [clusterId, refreshData, handleActiveIndexChange]);

  useEffect(() => {
    (() => {
      if (!data) return;
      const products = _.keyBy(data, "_id");
      setProducts(products);
    })();
  }, [data]);

  const validateEqualAttrs = (data) => {
    for (const prod of data) {
      const sorted = _.sortBy(prod.attrs);
      for (const check of data) {
        if (prod._id === check._id) continue;
        const isEqual = _.isEqual(sorted, _.sortBy(check.attrs));
        if (isEqual) throw new EqualAttrsError(`${prod._id}.attrs`);
      }
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.lazy((obj) =>
        yup.object(
          _.mapValues(obj, () =>
            yup.object({
              name: yup.string().required(messages.error.required),
              costPrice: yup.number().required(messages.error.required),
              wholesalePrice: yup.number().required(messages.error.required),
              retailPrice: yup.number().required(messages.error.required),
              productGroups: yup.array().required(messages.error.required),
            })
          )
        )
      );
      await schema.validate(products);
      const data = Object.values(products).map(({ productGroups, attrs, ...rest }) => ({
        ...rest,
        productGroups: _.map(productGroups, "_id"),
        attrs: _.map(attrs, "_id"),
      }));
      validateEqualAttrs(data);
      handleSaveData(data);
      setErrors({});
    } catch (error) {
      let errors = {};
      _.set(errors, error.path, error.message);
      setErrors(errors);
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      const [saved] = clusterId ? await api.put(`/product-clusters/${clusterId}`, data) : await api.post("/product-clusters", data);
      navigate(`/products/details/${saved.cluster}${location.search}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      setRefreshData(Date.now());
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <ClusterContext.Provider
      value={{
        products,
        setProducts,
        productAttributes,
        loadingProductAttributes,
        refreshProductAttributes,
        productGroups,
        loadingProductGroups,
        refreshProductGroups,
        handleActiveIndexChange,
      }}
    >
      <HStack p={2} mb={2}>
        <IconButton size="sm" variant="ghost" icon={<Icon as={MdArrowBack} />} onClick={() => navigate(-1)} />
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/products">
              Produtos
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : "Detalhes" ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      {loadingData ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Tooltip label={currentMerchant.parent ? "Ação bloqueada para subsidiárias" : "Adicionar produto"} shouldWrapChildren>
              <IconButton
                icon={<Icon as={MdAdd} />}
                colorScheme="main"
                variant="ghost"
                onClick={() => setShowCreateModal(true)}
                isDisabled={!!currentMerchant.parent}
              />
            </Tooltip>
            {Object.values(products).map(({ _id, attrs, sku }) => (
              <Button
                key={_id}
                fontSize="xs"
                whiteSpace="nowrap"
                variant={_id === productId ? "solid" : "ghost"}
                colorScheme={errors[_id] ? "red" : _id === productId ? "main" : "gray"}
                onClick={() => handleActiveIndexChange(_id)}
                mx={1}
              >
                <Icon as={errors[_id] ? MdError : MdLocalOffer} boxSize={errors[_id] && 4} mr={2} />
                {_.map(attrs, "name").join(" • ")} ({sku})
              </Button>
            ))}
          </Box>

          {productId && <Product data={products[productId]} errors={errors[productId]} />}

          <HStack justify="flex-end" spacing={4} mb={2}>
            <Button as={RouterLink} to="/products" leftIcon={<Icon as={MdChevronLeft} />}>
              Voltar
            </Button>
            <Tooltip label="Ação bloqueada para subsidiárias" isDisabled={!currentMerchant.parent} shouldWrapChildren>
              <Button
                type="submit"
                leftIcon={<Icon as={MdSave} />}
                colorScheme="main"
                isLoading={loadingSaveData || loadingData}
                isDisabled={!!currentMerchant.parent}
              >
                Salvar
              </Button>
            </Tooltip>
          </HStack>
        </form>
      )}

      <CopyProduct isOpen={showCreateModal} onClose={() => setShowCreateModal(false)} />
    </ClusterContext.Provider>
  );
};
