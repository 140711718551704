export const messages = {
  success: {
    deleteData: "Registro removido com sucesso!",
    saveData: "Registro salvo com sucesso!",
    saveDataPackWithNid: "O pacote NID {NID} foi criado com sucesso!",
  },
  error: {
    fetchData: "Problemas ao buscar registros.",
    deleteData: "Problemas ao remover registro.",
    saveData: "Problemas ao salvar registro.",
    required: "Este campo é obrigatório.",
    date: "A data informada é inválida.",
    signInPhoneNumber: "O número de telefone informado é inválido.",
    signInVerificationCode: "O código de verificação informado é inválido.",
    passwordStrength: "A nova senha deve conter pelo menos 8 caracteres distribuídos entre letras maiúsculas, letras minúsculas e números.",
    invalidDate: "A data informada é inválida.",
    productNotFound: "Produto não encontrado.",
    orderNotFound: "Pacote não encontrado.",
    greaterOrEqual: "O valor informado deve ser maior ou igual a ",
    lessOrEqual: "O valor informado deve ser menor ou igual a ",
    equalAttrs: "Esta combinação de atributos já foi usada em outro produto.",
  },
  warning: {
    deleteData: "Deseja realmente excluir este registro?",
  },
};
