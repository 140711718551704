import React, { useState, useCallback, createContext } from "react";
import { Outlet } from "react-router-dom";
import { Box, Flex, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import Toolbar from "./toolbar";
import Sidebar from "./sidebar";

export const ContainerContext = createContext();

export const Private = () => {
  const [isDesktopView] = useMediaQuery("(min-width: 940px)");
  const [isMobileView] = useMediaQuery("(max-width: 940px)");
  const [opened, setOpened] = useState(isDesktopView);
  const backgroundColor = useColorModeValue("gray.100", "gray.900");

  const handleToggle = useCallback(() => setOpened(!opened), [opened]);

  return (
    <ContainerContext.Provider value={{ isDesktopView, isMobileView, opened, handleToggle }}>
      <Flex width="100vw" height="100vh" flexDirection="column">
        <Toolbar />
        <Flex flex={1} overflow="hidden">
          <Sidebar opened={opened} onToggle={handleToggle} />
          <Box flex={1} p={2} overflowY="auto" bg={backgroundColor}>
            <Outlet />
          </Box>
        </Flex>
      </Flex>
    </ContainerContext.Provider>
  );
};
