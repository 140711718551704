import React from "react";
import { VStack, FormControl, FormLabel, Input, InputGroup, InputRightElement, Text, Select } from "@chakra-ui/react";
import { InputCurrency } from "components";

export const BarCode = ({ data, onChange }) => {
  return (
    <VStack>
      <FormControl>
        <FormLabel fontSize="xs" mb="2px">
          Largura da barra
        </FormLabel>
        <InputGroup>
          <Input
            as={InputCurrency}
            precision="1"
            value={data.props.width ?? ""}
            onChange={(floatValue) => onChange({ ...data, props: { ...data.props, width: floatValue } })}
          />
          <InputRightElement>
            <Text>px</Text>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel fontSize="xs" mb="2px">
          Altura
        </FormLabel>
        <InputGroup>
          <Input
            as={InputCurrency}
            precision="0"
            value={data.props.height ?? ""}
            onChange={(floatValue) => onChange({ ...data, props: { ...data.props, height: floatValue } })}
          />
          <InputRightElement>
            <Text>px</Text>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel fontSize="xs" mb="2px">
          Exibir valor?
        </FormLabel>
        <Select
          value={data.props.displayValue ? "yes" : "no"}
          onChange={({ target }) => onChange({ ...data, props: { ...data.props, displayValue: target.value === "yes" } })}
        >
          <option value="yes">Sim</option>
          <option value="no">Não</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel fontSize="xs" mb="2px">
          Tamanho da fonte
        </FormLabel>
        <InputGroup>
          <Input
            as={InputCurrency}
            precision="0"
            value={data.props.fontSize ?? ""}
            onChange={(floatValue) => onChange({ ...data, props: { ...data.props, fontSize: floatValue } })}
          />
          <InputRightElement>
            <Text>px</Text>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </VStack>
  );
};
