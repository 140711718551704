import { useState, useCallback } from "react";

export const useMiddlewareState = (middleware, defaultValue) => {
  const [value, setValue] = useState(defaultValue);

  const handleSetValue = useCallback(
    (data) =>
      setValue((state) => {
        if (data instanceof Function) return middleware(data(state));
        else return middleware(data);
      }),
    [middleware]
  );

  return [value, handleSetValue];
};
