import React, { useState, useEffect, createContext, useMemo, useRef, useCallback } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
  Spinner,
  StackDivider,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import {
  MdChevronLeft,
  MdSave,
  MdArrowBack,
  MdRefresh,
  MdAttachMoney,
  MdLocationPin,
  MdVisibility,
  MdArrowForward,
  MdPrint,
} from "react-icons/md";
import moment from "moment";
import { BiTransferAlt } from "react-icons/bi";
import { RiHandCoinLine } from "react-icons/ri";
import { CgTag } from "react-icons/cg";
import { FiShoppingCart } from "react-icons/fi";
import { getMerchandisesQuantityAmount } from "@consigsys/core";
import { messages } from "consts";
import { api, currency } from "lib";
import { useApiGet } from "hooks";
import { Tags, MerchandisesList, NewMovement } from "containers";
import { StatusBadge, OwnershipButton, Counters, Card } from "components";
import { FaTags } from "react-icons/fa";
import Printable from "./printable";

export const PacksDetailsContext = createContext();

export const PacksDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [data, loadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/packs/${_id}` }), [_id]));
  const [merchandises, setMerchandises] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [isOpenNewMovement, setIsOpenNewMovement] = useState(false);
  const [salesCounters, setSalesCounters] = useState({ quantity: 0, amount: 0 });
  const [isPrinting, setIsPrinting] = useState(false);
  const toast = useToast();
  const tagsSelectorRef = useRef();
  const bodyRef = useRef();

  useEffect(() => {
    const { __v, ...formData } = data ?? { type: "DEFAULT", status: "PREPARING" };
    setFormData(formData);
  }, [data]);

  useEffect(() => {
    setIsOwner(data?.shipment?.ownership !== "APP");
  }, [data?.shipment?.ownership]);

  useEffect(() => {
    const salesCounters = getMerchandisesQuantityAmount(formData.sales);
    setSalesCounters(salesCounters);
  }, [formData.sales]);

  const handleSaveData = async () => {
    try {
      setLoadingSaveData(true);
      const saved = _id ? await api.put(`/packs/${_id}`, formData) : await api.post("/packs", formData);
      navigate(`/packs/details/${saved._id}`, { replace: true });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleOpenTags = useCallback(() => {
    tagsSelectorRef.current.open("PACK", [{ document: formData, quantity: 1 }], true);
  }, [formData]);

  return (
    <>
      <PacksDetailsContext.Provider value={{ formData }}>
        <HStack p={2} mb={2} justify="space-between">
          <HStack>
            <IconButton size="sm" variant="ghost" as={RouterLink} to="/packs" icon={<Icon as={MdArrowBack} />} />
            <Breadcrumb fontWeight="medium" fontSize="xs">
              <BreadcrumbItem>
                <BreadcrumbLink as={RouterLink} to="/home">
                  Home
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink as={RouterLink} to="/packs">
                  Pacotes
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : formData?.nid ?? "Novo"}</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </HStack>
          <HStack>
            {_id && <IconButton variant="ghost" icon={<Icon as={FaTags} />} onClick={handleOpenTags} />}
            <ReactToPrint
              content={() => bodyRef.current}
              trigger={() => <IconButton variant="ghost" icon={<Icon as={MdPrint} />} isLoading={isPrinting} />}
              onBeforeGetContent={() =>
                new Promise((resolve) => {
                  setIsPrinting(true);
                  setTimeout(resolve, 1000);
                })
              }
              onAfterPrint={() => setIsPrinting(false)}
              onPrintError={() => setIsPrinting(false)}
            />
            <Tooltip label="Recarregar">
              <IconButton variant="ghost" icon={<Icon as={MdRefresh} />} onClick={refreshData} isLoading={loadingData} />
            </Tooltip>
            <OwnershipButton value={formData.shipment?.ownership} isLoading={loadingData} isDisabled={true} />
          </HStack>
        </HStack>

        {!_id && (
          <Alert status="info" borderRadius="lg" mb={2}>
            <AlertIcon />
            <AlertDescription>Antes de movimentar este documento, você deve salvá-lo.</AlertDescription>
          </Alert>
        )}

        {formData.dealer?.location?.length > 0 && (
          <Card overflow="hidden" p={1}>
            <iframe
              title="Localização"
              width="100%"
              height="300px"
              frameBorder="0"
              style={{ border: 0, borderRadius: "10px" }}
              src={`https://www.google.com/maps?q=${formData.dealer?.location?.[0]},${formData.dealer?.location?.[1]}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
              allowFullScreen
            />
          </Card>
        )}

        <Card>
          <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
            <Box>
              <Text fontSize="xs">NID</Text>
              <Text fontWeight="bold">{formData.nid ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Status</Text>
              <StatusBadge label={formData?.status} />
            </Box>
            <Box>
              <Text fontSize="xs">REMESSA</Text>
              <Text fontWeight="bold">{formData.shipment?.nid ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">PRAÇA</Text>
              <Text fontWeight="bold">{formData.shipment?.zone?.name ?? "-"}</Text>
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={[1, 3]} spacing={4}>
            <Box>
              <Text fontSize="xs">REPRESENTANTE</Text>
              <Text fontWeight="bold">{formData.agent?.name ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">REVENDEDOR</Text>
              <Text fontWeight="bold">{formData.dealer?.name ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">DATA DE COBRANÇA</Text>
              <Text fontWeight="bold">{formData.chargeDate ? moment(formData.chargeDate).format("DD/MM/YYYY") : "-"}</Text>
            </Box>
          </SimpleGrid>
        </Card>

        <Card>
          <HStack divider={<StackDivider />}>
            <HStack flex={1} p={2}>
              <Icon mx={2} as={MdAttachMoney} />
              <Box>
                <Text fontSize="xs">VALOR RECEBÍVEL</Text>
                <Text fontSize="sm" fontWeight="bold">
                  {currency.format(formData.amount?.receivable ?? 0)}
                </Text>
              </Box>
            </HStack>
            <HStack flex={1} p={2}>
              <Icon mx={2} as={MdAttachMoney} />
              <Box>
                <Text fontSize="xs">VALOR RECEBIDO</Text>
                <Text fontSize="sm" fontWeight="bold">
                  {currency.format(formData.amount?.received ?? 0)}
                </Text>
              </Box>
            </HStack>
            <HStack flex={1} p={2}>
              <Icon mx={2} as={MdAttachMoney} />
              <Box>
                <Text fontSize="xs">VALOR PENDENTE</Text>
                <Text fontSize="sm" fontWeight="bold">
                  {currency.format(formData.amount?.remaining ?? 0)}
                </Text>
              </Box>
            </HStack>
          </HStack>
        </Card>

        <Card>
          <Counters data={formData.load} />
        </Card>

        <Card>
          <HStack>
            <Icon mx={2} as={CgTag} />
            <Box flex={1}>
              <Text fontWeight="bold">Mercadorias</Text>
              <Text fontSize="xs">{formData?.load?.current?.quantity ?? 0} mercadorias</Text>
            </Box>
            <IconButton
              icon={<Icon as={MdVisibility} />}
              variant="ghost"
              onClick={() => setMerchandises(formData.merchandises ?? [])}
              isDisabled={_.size(formData.merchandises) === 0 || !formData._id}
            />
          </HStack>
        </Card>

        <Card>
          <HStack>
            <Icon mx={2} as={BiTransferAlt} />
            <Box flex={1}>
              <Text fontWeight="bold">Movimentações</Text>
              <Text fontSize="xs">{formData?.movementsCount ?? 0} movimentações</Text>
            </Box>
            <Button size="sm" leftIcon={<Icon as={BiTransferAlt} />} onClick={() => setIsOpenNewMovement(true)}>
              Nova movimentação
            </Button>
            <IconButton
              icon={<Icon as={MdArrowForward} />}
              variant="ghost"
              onClick={() => navigate("/movements", { state: { refPath: "Pack", _id: formData._id, nid: formData.nid } })}
              isDisabled={!formData._id}
            />
          </HStack>
        </Card>

        <Card>
          <HStack>
            <Icon mx={2} as={RiHandCoinLine} />
            <Box flex={1}>
              <Text fontWeight="bold">Contas</Text>
              <Text fontSize="xs">{data?.accountsCount ?? 0} contas</Text>
            </Box>
            <IconButton
              icon={<Icon as={MdArrowForward} />}
              variant="ghost"
              onClick={() => navigate(`/accounts?pack=${_id}`)}
              isDisabled={!formData._id}
            />
          </HStack>
        </Card>

        <Card>
          <HStack>
            <Icon mx={2} as={FiShoppingCart} />
            <Box flex={1}>
              <Text fontWeight="bold">Vendas</Text>
              <Text fontSize="xs">{salesCounters?.quantity?.toLocaleString?.() || 0} mercadorias</Text>
            </Box>
            <IconButton
              icon={<Icon as={MdVisibility} />}
              variant="ghost"
              onClick={() => setMerchandises(formData.sales ?? [])}
              isDisabled={_.size(formData.sales) === 0 || !formData._id}
            />
          </HStack>
        </Card>

        <Card>
          <HStack mb={4}>
            <Icon mx={2} as={MdLocationPin} />
            <Text fontWeight="bold">Endereço</Text>
          </HStack>

          <SimpleGrid columns={[1, 3]} p={2} spacing={4} mb={6}>
            <Box>
              <Text fontSize="xs">CEP</Text>
              <Text fontWeight="bold">{formData.dealer?.address?.zipCode ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Rua</Text>
              <Text fontWeight="bold">{formData.dealer?.address?.street ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Número</Text>
              <Text fontWeight="bold">{formData.dealer?.address?.number ?? "-"}</Text>
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={[1, 3]} p={2} spacing={4} mb={6}>
            <Box>
              <Text fontSize="xs">Bairro</Text>
              <Text fontWeight="bold">{formData.dealer?.address?.neighborhood ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Cidade</Text>
              <Text fontWeight="bold">{formData.dealer?.address?.city?.name ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Estado</Text>
              <Text fontWeight="bold">{formData.dealer?.address?.city?.state?.name ?? "-"}</Text>
            </Box>
          </SimpleGrid>

          <SimpleGrid p={2} spacing={4} mb={6}>
            <Box>
              <Text fontSize="xs">Complemento</Text>
              <Text fontWeight="bold">{formData.dealer?.address?.complement ?? "-"}</Text>
            </Box>
          </SimpleGrid>
        </Card>

        <HStack justify="flex-end" mb={2}>
          <Button as={RouterLink} to="/packs" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button
            leftIcon={<Icon as={MdSave} />}
            colorScheme="main"
            isLoading={loadingSaveData || loadingData}
            onClick={handleSaveData}
            isDisabled={data?.closed || !isOwner}
          >
            Salvar
          </Button>
        </HStack>

        <MerchandisesList data={merchandises} isOpen={merchandises.length} onClose={() => setMerchandises([])} />

        <NewMovement refPath="Pack" _id={_id} isOpen={isOpenNewMovement} onClose={() => setIsOpenNewMovement(false)} />

        <Tags.Selector ref={tagsSelectorRef} />

        {isPrinting && <Printable ref={bodyRef} data={formData} />}
      </PacksDetailsContext.Provider>
    </>
  );
};
