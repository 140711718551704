import React, { useContext } from "react";
import { Box, HStack, SimpleGrid, Text } from "@chakra-ui/react";
import { Card, Clipboard } from "components";
import { UsersDetailsContext } from ".";

const Device = () => {
  const { formData } = useContext(UsersDetailsContext);

  return (
    <>
      <Card>
        <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Marca
            </Text>
            <Text fontSize="sm">{formData.platform?.brand ?? "-"}</Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Modelo
            </Text>
            <Text fontSize="sm">{formData.platform?.model ?? "-"}</Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              S.O.
            </Text>
            <Text fontSize="sm">{formData.platform?.os ?? "-"}</Text>
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={[1, 3]} spacing={4}>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Versão do S.O.
            </Text>
            <Text fontSize="sm">{formData.platform?.osVersion ?? "-"}</Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              Versão do App
            </Text>
            <Text fontSize="sm">{formData.platform?.appVersion ?? "-"}</Text>
          </Box>
          <Box>
            <Text fontSize="xs" fontWeight="bold">
              FCM Token
            </Text>
            <HStack>
              <Text noOfLines={1} fontSize="sm">
                {formData.fcmToken ?? "-"}
              </Text>
              <Clipboard value={formData.fcmToken} />
            </HStack>
          </Box>
        </SimpleGrid>
      </Card>
    </>
  );
};

export default Device;
