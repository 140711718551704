import ObjectId from "bson-objectid";
import { BsSquare, BsGrid1X2 } from "react-icons/bs";
import { MdOutlineTextFields, MdOutlineQrCode2 } from "react-icons/md";
import { HiVariable } from "react-icons/hi";
import { AiOutlineBarcode } from "react-icons/ai";

export const elementPrototypes = [
  {
    type: "Box",
    icon: BsSquare,
    title: "Caixa",
    isWrapper: true,
    constructor: () => ({
      key: ObjectId().toString(),
      elementType: "Box",
      props: { width: "4cm", height: "4cm" },
      children: [],
    }),
  },
  {
    type: "Flex",
    icon: BsGrid1X2,
    title: "Caixa flexível",
    isWrapper: true,
    constructor: () => ({
      key: ObjectId().toString(),
      elementType: "Flex",
      props: { width: "4cm", height: "4cm" },
      children: [],
    }),
  },
  {
    type: "Text",
    icon: MdOutlineTextFields,
    title: "Texto",
    constructor: () => ({
      key: ObjectId().toString(),
      elementType: "Text",
      props: { fontSize: "12px", fontWeight: "400" },
      children: "Texto de exemplo",
    }),
  },
  {
    type: "Variable",
    icon: HiVariable,
    title: "Variável",
    constructor: () => ({
      key: ObjectId().toString(),
      elementType: "Variable",
      props: { fontSize: "12px", fontWeight: "400" },
      children: "[ID]",
    }),
  },
  {
    type: "BarCode",
    icon: AiOutlineBarcode,
    title: "Código de barras",
    constructor: () => ({
      key: ObjectId().toString(),
      elementType: "BarCode",
      props: { width: 1.5, height: 50, displayValue: true, fontSize: 12 },
      children: "[NID]",
    }),
  },
  {
    type: "QRCode",
    icon: MdOutlineQrCode2,
    title: "Código QR",
    constructor: () => ({
      key: ObjectId().toString(),
      elementType: "QRCode",
      props: { size: 128 },
      children: "[ID]",
    }),
  },
];