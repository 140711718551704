import React, { useContext } from "react";
import { Box, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightAddon, SimpleGrid, Text } from "@chakra-ui/react";
import { InputCurrency } from "components";
import { MerchantsDetailsContext } from ".";

const Params = (props) => {
  const { formData, setFormData, formErrors } = useContext(MerchantsDetailsContext);

  return (
    <>
      <Box mb={4}>
        <Text fontSize="lg">Parâmetros globais</Text>
        <Text fontSize="xs">Os parâmetros globais são utilizados como valores de referência em todo o sistema.</Text>
      </Box>
      <SimpleGrid columns={[1, 3]} spacing={4}>
        <FormControl isInvalid={formErrors["params.feePercentage"]}>
          <FormLabel>Juros ao dia</FormLabel>
          <InputGroup>
            <Input
              as={InputCurrency}
              value={formData.params?.feePercentage * 100 ?? 0}
              onChange={(floatValue) =>
                setFormData((state) => ({ ...state, params: { ...state.params, feePercentage: floatValue / 100 } }))
              }
            />
            <InputRightAddon>%</InputRightAddon>
          </InputGroup>
          <FormErrorMessage>{formErrors["params.feePercentage"]}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formErrors["params.penaltyPercentage"]}>
          <FormLabel>Multa</FormLabel>
          <InputGroup>
            <Input
              as={InputCurrency}
              value={formData.params?.penaltyPercentage * 100 ?? 0}
              onChange={(floatValue) =>
                setFormData((state) => ({ ...state, params: { ...state.params, penaltyPercentage: floatValue / 100 } }))
              }
            />
            <InputRightAddon>%</InputRightAddon>
          </InputGroup>
          <FormErrorMessage>{formErrors["params.penaltyPercentage"]}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formErrors["params.gracePeriod"]}>
          <FormLabel>Período de carência</FormLabel>
          <InputGroup>
            <Input
              as={InputCurrency}
              precision="0"
              value={formData.params?.gracePeriod ?? 0}
              onChange={(floatValue) => setFormData((state) => ({ ...state, params: { ...state.params, gracePeriod: floatValue } }))}
            />
            <InputRightAddon>dias</InputRightAddon>
          </InputGroup>
          <FormErrorMessage>{formErrors["params.gracePeriod"]}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </>
  );
};

export default Params;
