import React, { useRef, createContext, useMemo } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Badge } from "@chakra-ui/react";
import { MdDelete, MdAdd } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { Card, Clipboard, DataTable, Dialog } from "components";
import { messages } from "consts";
import { api, translator, currency, percent } from "lib";
import Filter from "./filter";

export const AccountsListContext = createContext();

export const AccountsList = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useCacheState(20, "AccountsList:perPage");
  const [sort, setSort] = useStickyState({ dueDate: 1 }, "AccountsList:sort");
  const [page, setPage] = useCacheState(0, "AccountsList:page");
  const [query, setQuery] = useCacheState({}, "AccountsList:query");
  const [response, loading, refresh, error] = useApiGet(
    useMemo(() => ({ path: "/accounts", params: { query, perPage, page, sort } }), [query, perPage, page, sort])
  );
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/accounts/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <AccountsListContext.Provider value={{ query, setQuery, setPage, loading }}>
      <HStack p={2} mb={2} justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/accounts">
              Contas
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/accounts/details" icon={<Icon as={MdAdd} boxSize={4} />} />
        </Tooltip>
      </HStack>

      <Card>
        <Filter />
      </Card>

      <Card>
        <DataTable
          tableId="AccountsList:table"
          title="Contas"
          data={response?.data ?? []}
          size={response?.size ?? 0}
          loading={loading}
          sort={sort}
          onSort={setSort}
          perPage={perPage}
          onPerPage={setPerPage}
          page={page}
          onPaginate={setPage}
          onRefresh={refresh}
          error={error}
          renderItems={[
            { key: "_id", label: "ID", visible: true, render: (value) => <Clipboard value={value} icon={<Icon as={CgHashtag} />} /> },
            {
              key: "type",
              label: "Tipo",
              visible: true,
              render: (value) => <Badge colorScheme={value === "REVENUE" ? "green" : "red"}>{translator(value)}</Badge>,
              exporter: translator,
            },
            { key: "user.name", label: "Usuário", visible: true },
            {
              key: "status",
              label: "Status",
              visible: true,
              render: (value) => <Badge colorScheme={value === "PAID" ? "green" : "orange"}>{translator(value)}</Badge>,
              exporter: translator,
            },
            { key: "paymentForm.name", label: "Forma de Pagamento", visible: true },
            { key: "wallet.name", label: "Carteira", visible: true },
            { key: "chartOfAccount.indexes", label: "Plano de Contas", visible: true },
            { key: "issueDate", label: "Data de emissão", visible: true, formatter: (value) => moment(value).format("DD/MM/YYYY") },
            { key: "dueDate", label: "Data de vencimento", visible: true, formatter: (value) => moment(value).format("DD/MM/YYYY") },
            {
              key: "paymentDate",
              label: "Data de pagamento",
              visible: true,
              formatter: (value) => (value ? moment(value).format("DD/MM/YYYY") : "-"),
            },
            { key: "expiredDays", label: "Expirado", visible: true, formatter: (value) => `${value} dias` },
            { key: "additions.fees", label: "Juros", visible: true, formatter: (value) => percent.format(value) },
            { key: "additions.fine", label: "Multa", visible: true, formatter: (value) => percent.format(value) },
            { key: "additions.discount", label: "Desconto", visible: true, formatter: (value) => currency.format(value) },
            { key: "amount.partial", label: "Subtotal", visible: true, formatter: (value) => currency.format(value) },
            { key: "amount.receivable", label: "Recebíveis", visible: true, formatter: (value) => currency.format(value) },
            { key: "amount.received", label: "Recebido", visible: true, formatter: (value) => currency.format(value) },
            { key: "amount.remaining", label: "A receber", visible: true, formatter: (value) => currency.format(value) },
            { key: "document", label: "Documento", visible: true },
            { key: "installments.current", label: "Parcela", visible: true },
            {
              key: "createdAt",
              label: "Criação",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "updatedAt",
              label: "Atualização",
              visible: true,
              formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
            },
          ]}
          ActionsComponent={({ item }) => (
            <Tooltip label="Excluir">
              <IconButton
                icon={<Icon as={MdDelete} />}
                size="sm"
                variant="ghost"
                colorScheme="red"
                onClick={() => deleteRef.current.confirm(item, handleDelete)}
              />
            </Tooltip>
          )}
          onRowDoubleClick={({ _id }) => navigate(`/accounts/details/${_id}`)}
        />
      </Card>
      <Dialog.Delete ref={deleteRef} />
    </AccountsListContext.Provider>
  );
};
